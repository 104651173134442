import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Divider, Popover, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Icon } from 'components';
import { withStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Badge from '@mui/material/Badge';
import { NavLink } from 'react-router-dom';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import { UserStateContext } from 'context/user-state-context';
import { Tooltip } from '@mui/material';
import { AnalyticsIcon, AssetsFilledIcon, AssetsIcon, BroadcastsIcon, CarrotDownIcon, CarrotUpIcon, CouponFilledIcon, CouponIcon, CustomerGroupsFilledIcon, CustomerGroupsIcon, CustomersFilledIcon, CustomersIcon, DashboardFilledIcon, DashboardIcon, FileManagerFilledIcon, FileManagerIcon, HandymanIcon, ImportIcon, InboxFilledIcon, InboxIcon, InsertChartDataFilledIcon, InsertChartDataIcon, JourneyFilledIcon, JourneyIcon, LandingPagesIcon, ListAltFilledIcon, ListAltIcon, MarketingFilledIcon, MarketingIcon, NewspaperIcon, PriorityIcon, PriortyFilledIcon, RepeatTransactionCalculatorIcon, ReviewFilledIcon, ReviewIcon, SMSIcon, SegmentIcon, SidebarMenuCloseIcon, SidebarMenuOpenIcon, SmsFilledIcon, SurveyResponsesIcon, SurveysIcon, TemplatesFilledIcon, TemplatesIcon, TransactionFilledIcon, TransactionIcon, WebFormIcon } from 'components/mui';
import { history } from '../../root.component';
import { ReleaseNotesModal } from './release-notes-modal.component';
const StyledButton = withStyles({
  root: {
    width: '56px',
    height: '40px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#C1E6FE',
      color: '#000'
    }
  }
})(IconButton);
const ActionItemButton = withStyles({
  root: {
    width: '56px',
    height: '40px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#C2EBD6',
      color: '#000'
    }
  }
})(IconButton);
export const SidebarMui = props => {
  const [sidebarExpanded, setSidebarExpanded] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverData, setPopoverData] = React.useState(null);
  const [subMenuExpanded, setSubMenuExpanded] = React.useState([]);
  const {
    asCompany,
    hasPermission,
    hasIntegration,
    user,
    logOut,
    flags,
    actionItems,
    isCatalystUser,
    releaseNotes
  } = React.useContext(UserStateContext);
  const [menu, setMenu] = React.useState([]);
  const [iconHover, setIconHover] = React.useState([]);
  const [hasChildrenCompanies, setHasChildrenCompanies] = React.useState();
  const open = Boolean(anchorEl);
  const [releaseNotesModalOpen, setReleaseNotesModalOpen] = React.useState(false);
  React.useEffect(() => {
    if (localStorage.getItem('sidebarExpanded')) {
      setSidebarExpanded(localStorage.getItem('sidebarExpanded') === 'true');
    }
    const resizeListener = () => {
      if (window.innerWidth < 1100) {
        setSidebarExpanded(false);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  React.useEffect(() => {
    if (subMenuExpanded.length == 0) {
      const path = window?.location?.pathname.split('/');
      const i = menu.filter(m => m.subMenu?.find(s => s.key == '/' + path[1]));
      if (i.length > 0) {
        setSubMenuExpanded([...subMenuExpanded, i[0]]);
      }
    }
  }, [menu]);
  React.useEffect(() => {
    setHasChildrenCompanies(asCompany?.children?.length > 0);
  }, [asCompany]);
  React.useEffect(() => {
    if (isCatalystUser) {
      setMenu([{
        key: '/companies',
        //TODO: build dashboard page and link to it here
        name: 'Companies',
        icon: DashboardIcon,
        iconFilled: DashboardFilledIcon
      }, {
        name: 'Inbox',
        icon: InboxIcon,
        iconFilled: InboxFilledIcon,
        //badge: 3,   //TODO: Impliment unread counter
        subMenu: [{
          key: '/messaging',
          //TODO: Build inbox page and link to it
          name: 'Text Messages',
          icon: SMSIcon,
          iconFilled: SmsFilledIcon,
          enabled: hasPermission('communication.view_smslog')
        }, {
          key: '/reviews',
          name: 'Reviews',
          icon: ReviewIcon,
          iconFilled: ReviewFilledIcon,
          enabled: hasIntegration('reviews')
        }, {
          key: '/survey-responses',
          name: 'Survey Responses',
          icon: SurveyResponsesIcon,
          enabled: hasIntegration('surveys')
        }]
      }, {
        name: 'Data',
        icon: InsertChartDataIcon,
        iconFilled: InsertChartDataFilledIcon,
        subMenu: [{
          key: '/customers',
          name: 'Customers',
          icon: CustomersIcon,
          iconFilled: CustomersFilledIcon,
          enabled: hasPermission('customer.view_customer')
        }, {
          key: '/transactions',
          name: 'Transactions',
          icon: TransactionIcon,
          iconFilled: TransactionFilledIcon,
          enabled: hasPermission('customer.view_customer')
        }]
      }, {
        name: 'Support'
      }, {
        name: 'Sign Out'
      }]);
    } else {
      setMenu([{
        key: '/dashboard',
        name: 'Dashboard',
        icon: DashboardIcon,
        iconFilled: DashboardFilledIcon
      }, {
        name: 'Inbox',
        icon: InboxIcon,
        iconFilled: InboxFilledIcon,
        //badge: 3,   //TODO: Impliment unread counter
        subMenu: [{
          key: '/messaging',
          //TODO: Build inbox page and link to it
          name: 'Text Messages',
          icon: SMSIcon,
          iconFilled: SmsFilledIcon,
          enabled: hasPermission('communication.view_smslog')
        }, {
          key: '/reviews',
          name: 'Reviews',
          icon: ReviewIcon,
          iconFilled: ReviewFilledIcon,
          enabled: hasIntegration('reviews')
        }, {
          key: '/survey-responses',
          name: 'Survey Responses',
          icon: SurveyResponsesIcon,
          enabled: hasIntegration('surveys')
        }]
      }, {
        name: 'Marketing',
        icon: MarketingIcon,
        iconFilled: MarketingFilledIcon,
        subMenu: [{
          key: '/customer-journeys',
          name: 'Journeys',
          icon: JourneyIcon,
          iconFilled: JourneyFilledIcon,
          enabled: hasPermission('journey.view_journey')
        }, {
          key: '/sms-broadcasts',
          name: 'Text Broadcasts',
          icon: BroadcastsIcon,
          enabled: hasPermission('communication.view_smsbroadcast')
        }, {
          key: '/email-broadcasts',
          name: 'Email Broadcasts',
          icon: BroadcastsIcon,
          enabled: hasPermission('communication.view_smsbroadcast')
        }, {
          key: '/manage-email-broadcasts',
          name: 'Managed Email Broadcasts',
          icon: JourneyFilledIcon,
          enabled: hasPermission('communication.view_smsbroadcast') && hasChildrenCompanies,
          viewBox: '0 0 1 1'
        }]
      }, {
        name: 'Assets',
        icon: AssetsIcon,
        iconFilled: AssetsFilledIcon,
        subMenu: [{
          key: '/segments',
          name: 'Segments',
          icon: SegmentIcon,
          enabled: hasPermission('customer.view_segment'),
          subPages: ['/create-segment']
        }, {
          key: '/templates',
          name: 'Templates',
          icon: TemplatesIcon,
          iconFilled: TemplatesFilledIcon,
          enabled: hasPermission('template.view_template')
        }, {
          key: '/files',
          name: 'File Manager',
          icon: FileManagerIcon,
          iconFilled: FileManagerFilledIcon,
          enabled: hasPermission('files.view_files')
        }, {
          key: '/coupons',
          name: 'Coupons',
          icon: CouponIcon,
          iconFilled: CouponFilledIcon,
          enabled: hasIntegration('loyalty') || hasIntegration('isi') || hasIntegration('coupons')
        }, {
          key: '/forms',
          name: 'Forms',
          icon: WebFormIcon,
          enabled: hasPermission('customer.view_segment')
        }, {
          key: '/surveys',
          name: 'Surveys',
          icon: ListAltIcon,
          iconFilled: ListAltFilledIcon,
          enabled: hasIntegration('surveys')
        }, {
          key: '/landing-pages',
          name: 'Landing Pages',
          icon: LandingPagesIcon,
          enabled: hasIntegration('convrrt') && hasPermission('integration.manage_landing_pages')
        }, {
          key: '/repeat-transaction-calculator-list',
          name: 'Repeat Transaction Calculator',
          icon: RepeatTransactionCalculatorIcon,
          enabled: hasIntegration('repeat_transaction_calculator') && (hasPermission('repeat_transaction_calculator.view_transactioncalculatorrule') || hasPermission('repeat_transaction_calculator.view_transactioncalculatorresults'))
        }, {
          key: '/motor-oem-recommendations/configure',
          name: 'Motor OEM Recommendations',
          subPages: ['/motor-oem-recommendations'],
          icon: RepeatTransactionCalculatorIcon,
          // TODO change icon...
          enabled: hasIntegration('motor_oem_recommendations') // TODO get this working with permissions next story
        }, {
          key: '/prebuilt/install',
          name: 'Prebuilt Asssets',
          subPages: ['/prebuilt'],
          icon: HandymanIcon,
          enabled: user.is_superuser || flags.cinch_testers
        }]
      }, {
        name: 'Data',
        icon: InsertChartDataIcon,
        iconFilled: InsertChartDataFilledIcon,
        subMenu: [{
          key: '/analytics-list',
          name: 'Analytics',
          icon: AnalyticsIcon,
          enabled: hasPermission('analytics.view_view')
        }, {
          key: '/customers',
          name: 'Customers',
          icon: CustomersIcon,
          iconFilled: CustomersFilledIcon,
          enabled: hasPermission('customer.view_customer')
        }, {
          key: '/transactions',
          name: 'Transactions',
          icon: TransactionIcon,
          iconFilled: TransactionFilledIcon,
          enabled: hasPermission('customer.view_customer')
        }, {
          key: '/customer-groups',
          name: 'Customer Groups',
          icon: CustomerGroupsIcon,
          iconFilled: CustomerGroupsFilledIcon,
          enabled: hasIntegration('customergroups')
        }, {
          key: '/imports',
          name: 'Import Data',
          icon: ImportIcon,
          enabled: hasPermission('csvimport.change_import')
        }]
      }, {
        name: 'Support'
      }, {
        name: 'Sign Out'
      }]);
    }
  }, [hasPermission, hasIntegration, hasChildrenCompanies, actionItems]);
  const handleExpandClick = event => {
    localStorage.setItem('sidebarExpanded', !sidebarExpanded);
    setSidebarExpanded(!sidebarExpanded);
  };
  const handleSupportClick = () => {
    window.open(user.support_url);
  };
  const handleActionItemsClick = () => {
    history.push('/action-items');
  };
  const hasActionItemsToComplete = () => {
    if (isCatalystUser) {
      return false;
    }
    for (const [key, value] of Object.entries(actionItems)) {
      for (let i = 0; i < value.length; i++) {
        if (value[i]?.status != 'done' && value[i]?.status != 'ignored') {
          return true;
        }
      }
    }
  };
  const getActionItemsToCompleteCount = () => {
    let count = 0;
    for (const [key, value] of Object.entries(actionItems)) {
      for (let i = 0; i < value.length; i++) {
        if (value[i]?.status != 'done' && value[i]?.status != 'ignored') {
          count++;
        }
      }
    }
    return count;
  };
  const handleLogoutClick = () => {
    logOut();
  };
  const checkChildrenPath = item => {
    if (!item || !window?.location?.pathname) return false;
    const path = window?.location?.pathname.split('/');
    if (item.key == window?.location?.pathname || item.subMenu?.some(i => i.key === '/' + path[1])) return true;
    if (item.subMenu) {
      return item.subMenu.some(subItem => {
        return subItem.subPages && subItem.subPages.some(s => s == '/' + path[1]);
      });
    }
    return false;
  };
  const isSubItem = subItem => {
    const path = window?.location?.pathname.split('/');
    if ('/' + path[1] == subItem.key) {
      return true;
    }

    //check if there are subitem matches, eg create-segment should match to segment
    if (subItem.subPages) {
      return !!subItem.subPages.some(p => p == '/' + path[1]);
    }
    return false;
  };
  const handlePopoverOpen = (event, item) => {
    setPopoverData(item);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverData(null);
  };
  const handleReleaseNotesClick = () => {
    setReleaseNotesModalOpen(true);
  };
  return <>
      <CssBaseline />
      <Box onMouseLeave={e => {
      handlePopoverClose();
    }} sx={{
      zIndex: 50,
      height: '100vh',
      width: '100vw',
      maxWidth: sidebarExpanded ? '360px' : '96px',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)'
    }}>
        {sidebarExpanded ? <Paper className="d-flex column space-between secondary-color" sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        borderRadius: '0px',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
            <Stack>
              <Box sx={{
            display: 'flex',
            pt: '20px',
            pl: '36px',
            pr: '7px'
          }} className="d-flex w-100 justify-content-between">
                <div style={{
              fill: '#66B6DF',
              height: '24px',
              width: '70px'
            }} className="logo" dangerouslySetInnerHTML={{
              __html: cinchLogo
            }} />
                <Tooltip title="Collapse Sidebar">
                  <StyledButton onClick={handleExpandClick} sx={{
                width: '24px',
                height: '24px',
                textAlign: 'center',
                padding: 0,
                bottom: '8px',
                color: '#1D252D'
              }}>
                    <SidebarMenuCloseIcon />
                  </StyledButton>
                </Tooltip>
              </Box>
              <Stack sx={{
            pt: '20px'
          }} spacing={1}>
                {menu.filter(m => !m.subMenu || m.subMenu?.some(s => s.enabled)).map((item, index) => <Box key={item.name} sx={{
              pb: '10px',
              px: '20px'
            }}>
                      {item.name != 'Support' && item.name != 'Sign Out' && <NavLink to={item.key ? item.key : '#'} style={{
                textDecoration: 'none',
                color: '#1D252D',
                width: '100%'
              }}>
                          <Button key={index} onClick={e => {
                  if (subMenuExpanded.some(i => i.name == item.name)) {
                    setSubMenuExpanded(subMenuExpanded.filter(i => i.name != item.name));
                  } else if (item?.subMenu) {
                    setSubMenuExpanded([...subMenuExpanded, item]);
                  }
                }} fullWidth sx={{
                  textAlign: 'center',
                  color: '#1D252D',
                  background: checkChildrenPath(item) ? '#3898D9' : user.color_preference_secondary || '#fff',
                  textTransform: 'none',
                  px: '16px',
                  justifyContent: 'left',
                  alignContent: 'center',
                  margin: 'auto',
                  alignItems: 'center',
                  height: '40px',
                  borderRadius: '20px',
                  '&:hover': {
                    backgroundColor: checkChildrenPath(item) ? '#3898D9' : '#C2E7FF',
                    color: '#53A6D6'
                  }
                }}>
                            <Stack direction="row" sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center'
                  }} className="d-flex w-100 justify-content-between">
                              <Stack sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center'
                    }} direction="row" spacing="20px">
                                <Badge badgeContent={item.badge} invisible={!item.badge} color="primary">
                                  <>{checkChildrenPath(item) && item.iconFilled ? <item.iconFilled /> : <item.icon />}</>
                                </Badge>
                                <Typography sx={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: checkChildrenPath(item) ? '#FFFFFF' : '#1D252D'
                      }}>
                                  {item.name}
                                </Typography>
                              </Stack>
                              {item.subMenu && subMenuExpanded.find(i => i.name == item.name) && <CarrotUpIcon fill={checkChildrenPath(item) ? '#FFFFFF' : '#1D252D'} />}
                              {item.subMenu && !subMenuExpanded.find(i => i.name == item.name) && <CarrotDownIcon fill={checkChildrenPath(item) ? '#FFFFFF' : '#1D252D'} />}
                            </Stack>
                          </Button>
                        </NavLink>}
                      {subMenuExpanded.some(i => i.name == item.name) && <Stack spacing={'10px'} sx={{
                pt: '10px'
              }}>
                          {item.subMenu?.filter(sub => sub.enabled).map(subItem => <NavLink key={subItem.key} to={subItem.key}>
                                <Button sx={{
                    borderRadius: '41px',
                    width: '100%',
                    justifyContent: 'flex-start',
                    '&:hover': {
                      backgroundColor: '#C1E6FE',
                      color: '#1D252D'
                    }
                  }}>
                                  <Box sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                                    <Stack sx={{
                        pl: '18px',
                        py: '3px',
                        color: 'inherit'
                      }} direction="row">
                                      <>
                                        {isSubItem(subItem) ? <Box sx={{
                            pr: '5px',
                            borderLeft: '2px solid',
                            borderColor: '#53A6D6'
                          }} /> : <Box sx={{
                            pr: '7px'
                          }} />}

                                        {window?.location?.pathname === subItem.key && subItem.iconFilled ? <subItem.iconFilled fill={isSubItem(subItem) ? '#53A6D6' : '#1D252D'} /> : <subItem.icon fill={isSubItem(subItem) ? '#53A6D6' : '#1D252D'} />}
                                      </>
                                      <Typography sx={{
                          fontSize: 14,
                          fontWeight: '700',
                          pl: '20px',
                          mt: '5px',
                          color: isSubItem(subItem) ? '#53A6D6' : '#1D252D',
                          textTransform: 'none'
                        }}>
                                        {subItem.name}
                                      </Typography>
                                    </Stack>
                                    {subItem.badge && <Typography flexGrow="1" variant="tableHeader" sx={{
                        fontSize: 14,
                        pr: '14px',
                        mt: '5px',
                        color: 'inherit',
                        textAlign: 'right'
                      }}>
                                        {subItem.badge}
                                      </Typography>}
                                  </Box>
                                </Button>
                              </NavLink>)}
                        </Stack>}
                    </Box>)}
              </Stack>
            </Stack>
            <Stack spacing={1} sx={{
          display: 'flex',
          alignItems: 'left',
          textAlign: 'center',
          pb: '13px',
          justifyContent: 'left'
        }}>
              <Box sx={{
            position: 'relative',
            bottom: '6px'
          }}>
                <Divider sx={{
              color: '#EDEFF1'
            }} flexItem />
              </Box>
              {hasActionItemsToComplete() && <Box sx={{
            position: 'relative',
            top: '2px',
            right: '0px',
            px: '20px'
          }}>
                  <Button onClick={handleActionItemsClick} fullWidth sx={{
              textAlign: 'center',
              height: '40px',
              padding: 0,
              justifyContent: 'left',
              borderRadius: '20px',
              background: checkChildrenPath({
                key: '/action-items'
              }) ? '#3EB87B' : user.color_preference_secondary || '#fff',
              '&:hover': {
                backgroundColor: checkChildrenPath({
                  key: '/action-items'
                }) ? '#3EB87B' : '#C2EBD6'
              }
            }}>
                    <Stack direction="row" sx={{
                pl: '16px'
              }}>
                      <Badge badgeContent={getActionItemsToCompleteCount()} invisible={localStorage.getItem('hideActionItemsBadge') === 'true' || !hasActionItemsToComplete()} sx={{
                  '& .MuiBadge-badge': {
                    color: '#ffffff',
                    backgroundColor: '#E35989'
                  }
                }}>
                        {checkChildrenPath({
                    key: '/action-items'
                  }) ? <PriortyFilledIcon fill="#FFFFFF" /> : <PriorityIcon fill="#3EB87B" />}
                      </Badge>
                      <Typography variant="tableHeader" sx={{
                  color: checkChildrenPath({
                    key: '/action-items'
                  }) ? '#fff' : '#1D252D',
                  position: 'relative',
                  top: '4px',
                  pl: '20px',
                  textTransform: 'none'
                }}>
                        Action Items
                      </Typography>
                    </Stack>
                  </Button>
                </Box>}
              {flags?.release_notes && <Box sx={{
            position: 'relative',
            top: '2px',
            right: '0px',
            px: '20px'
          }}>
                  <Button onClick={e => {
              handleReleaseNotesClick();
            }} fullWidth sx={{
              textAlign: 'center',
              height: '40px',
              padding: 0,
              justifyContent: 'left',
              color: '#1D252D',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: '#C1E6FE'
              }
            }}>
                    <Stack direction="row" sx={{
                pl: '16px'
              }}>
                      <Badge badgeContent={releaseNotes.filter(n => !n.is_read)?.length} invisible={!releaseNotes.filter(n => !n.is_read)?.length} sx={{
                  '& .MuiBadge-badge': {
                    color: '#ffffff',
                    backgroundColor: '#E35989'
                  }
                }}>
                        <NewspaperIcon />
                      </Badge>
                      <Typography variant="tableHeader" sx={{
                  position: 'relative',
                  top: '4px',
                  pl: '20px',
                  textTransform: 'none'
                }}>
                        What's New
                      </Typography>
                    </Stack>
                  </Button>
                </Box>}

              <Box sx={{
            position: 'relative',
            top: '2px',
            right: '0px',
            px: '20px'
          }}>
                <Button onClick={handleSupportClick} fullWidth sx={{
              textAlign: 'center',
              height: '40px',
              padding: 0,
              justifyContent: 'left',
              color: '#1D252D',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: '#C1E6FE'
              }
            }}>
                  <Stack direction="row" sx={{
                pl: '16px'
              }}>
                    <Icon name="custom-headset_mic" size={24} />
                    <Typography variant="tableHeader" sx={{
                  position: 'relative',
                  top: '4px',
                  pl: '20px',
                  textTransform: 'none'
                }}>
                      Support
                    </Typography>
                  </Stack>
                </Button>
              </Box>
            </Stack>
          </Paper> : <Paper className="d-flex column space-between secondary-color" sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        borderRadius: '0px'
      }}>
            <Stack spacing={1} sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          alignContent: 'center',
          pt: '13px',
          justifyContent: 'space-between'
        }}>
              <Box onMouseEnter={handlePopoverClose} sx={{
            pb: '27px'
          }}>
                <Tooltip title="Expand Sidebar">
                  <StyledButton onClick={handleExpandClick} sx={{
                width: '24px',
                height: '24px',
                textAlign: 'center',
                padding: 0,
                color: '#1D252D'
              }}>
                    <SidebarMenuOpenIcon />
                  </StyledButton>
                </Tooltip>
              </Box>
              {menu.filter(m => !m.subMenu || m.subMenu?.some(s => s.enabled)).map((item, index) => <div key={index} style={{
            marginTop: '0px',
            paddingBottom: '18px'
          }}>
                    {item.name != 'Support' && item.name != 'Sign Out' && <IconButton onMouseEnter={e => {
              handlePopoverOpen(e, item);
            }} sx={{
              width: '56px',
              height: '40px',
              textAlign: 'center',
              padding: 0,
              color: '#1D252D',
              background: checkChildrenPath(item) ? '#3898D9' : user.color_preference_secondary || '#fff',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: checkChildrenPath(item) ? '#3898D9' : '#C2E7FF',
                color: '#000'
              }
            }}>
                        <Badge badgeContent={item.badge} invisible={!popoverData?.badge} color="primary">
                          <>{checkChildrenPath(item) && item.iconFilled ? <item.iconFilled /> : <item.icon />}</>
                        </Badge>
                      </IconButton>}
                  </div>)}
            </Stack>
            <Box onMouseEnter={handlePopoverClose} sx={{
          height: '100%'
        }}></Box>
            <Stack spacing={2} sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          pb: '13px'
        }}>
              <Divider flexItem sx={{
            px: 2
          }} />
              {hasActionItemsToComplete() && <ActionItemButton onClick={handleActionItemsClick} onMouseEnter={e => {
            handlePopoverOpen(e, {
              name: 'Action Items',
              key: '/action-items'
            });
          }} sx={{
            width: '24px',
            height: '24px',
            textAlign: 'center',
            padding: 0,
            color: '#1D252D',
            background: checkChildrenPath({
              key: '/action-items'
            }) ? '#3EB87B' : user.color_preference_secondary || '#fff'
          }}>
                  <Badge badgeContent={getActionItemsToCompleteCount()} invisible={localStorage.getItem('hideActionItemsBadge') === 'true' || !hasActionItemsToComplete()} sx={{
              '& .MuiBadge-badge': {
                color: '#ffffff',
                backgroundColor: '#E35989'
              }
            }}>
                    {checkChildrenPath({
                key: '/action-items'
              }) ? <PriortyFilledIcon fill="#FFFFFF" /> : <PriorityIcon fill="#3EB87B" />}
                  </Badge>
                </ActionItemButton>}

              {flags?.release_notes && <StyledButton onClick={e => {
            handleReleaseNotesClick();
          }} onMouseEnter={e => {
            handlePopoverOpen(e, {
              name: "What's New"
            });
          }} sx={{
            width: '24px',
            height: '24px',
            textAlign: 'center',
            padding: 0,
            color: '#1D252D'
          }}>
                  <Badge badgeContent={releaseNotes.filter(n => !n.is_read)?.length} invisible={!releaseNotes.filter(n => !n.is_read)?.length} sx={{
              '& .MuiBadge-badge': {
                color: '#ffffff',
                backgroundColor: '#E35989'
              }
            }}>
                    <NewspaperIcon />
                  </Badge>
                </StyledButton>}

              <StyledButton onClick={handleSupportClick} onMouseEnter={e => {
            handlePopoverOpen(e, {
              name: 'Support'
            });
          }} sx={{
            width: '24px',
            height: '24px',
            textAlign: 'center',
            padding: 0,
            color: '#1D252D'
          }}>
                <Icon name="custom-headset_mic" size={24} />
              </StyledButton>
            </Stack>
          </Paper>}

        <Popover id="mouse-over-popover" sx={{
        pointerEvents: 'none',
        zIndex: 200
      }} open={open} anchorEl={anchorEl} anchorOrigin={{
        vertical: 0,
        horizontal: 60
      }} transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }} onClose={handlePopoverClose} PaperProps={{
        style: {
          width: '320px',
          borderRadius: '20px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)'
        }
      }} disableRestoreFocus>
          <Box display="flex" flexDirection="row" onMouseLeave={handlePopoverClose} sx={{
          pointerEvents: 'auto',
          zIndex: 200,
          cursor: 'pointer'
        }}>
            <Stack sx={{
            width: '100%'
          }}>
              <Button variant="text" fullWidth onClick={e => {
              if (popoverData?.name == 'Support') {
                handleSupportClick();
              }
              if (popoverData?.name == 'Sign Out') {
                handleLogoutClick();
              }
            }} sx={{
              borderRadius: '20px',
              height: '40px',
              cursor: 'pointer',
              color: '#1D252D',
              textTransform: 'none',
              justifyContent: 'flex-start',
              backgroundColor: checkChildrenPath(popoverData) ? '#3898D9' : '#C1E6FE',
              '&:hover': {
                backgroundColor: checkChildrenPath(popoverData) ? '#3898D9' : '#C1E6FE'
              }
            }}>
                <NavLink to={popoverData?.key ? popoverData.key : '#'} style={{
                textDecoration: 'none',
                color: '#1D252D',
                width: '100%'
              }}>
                  <Typography variant="tableHeader" sx={{
                  fontSize: 14,
                  pl: '24px',
                  mt: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  alignContent: 'center',
                  bottom: '2px',
                  position: 'relative',
                  color: checkChildrenPath(popoverData) ? '#FFFFFF' : '#1D252D'
                }}>
                    {popoverData?.name}
                  </Typography>
                </NavLink>
              </Button>
              <Box sx={{
              py: popoverData?.subMenu?.length > 0 ? '20px' : '0px'
            }}>
                <Stack spacing={1}>
                  {popoverData?.subMenu?.filter(sub => sub.enabled).map((subItem, index) => <NavLink key={subItem.key} to={subItem.key} style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%',
                  height: '100%'
                }}>
                        <Button variant="text" fullWidth key={subItem.key + index} onMouseEnter={e => {
                    setIconHover(subItem);
                  }} onMouseLeave={e => {
                    setIconHover(null);
                  }} sx={{
                    cursor: 'pointer',
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: '100%',
                    height: '40px',
                    borderRadius: '20px',
                    color: window?.location?.pathname === subItem.key ? '#53A6D6' : '#1D252D',
                    // borderLeft: '2px solid',
                    borderColor: window?.location?.pathname === subItem.key ? '#53A6D6' : '#EDEFF1',
                    background: '#fff',
                    '&:hover': {
                      backgroundColor: '#C2E7FF',
                      color: '#53A6D6'
                    }
                  }}>
                          <Stack sx={{
                      pl: '30px',
                      color: 'inherit'
                    }} direction="row">
                            <>
                              {isSubItem(subItem) ? <Box sx={{
                          pr: '5px',
                          borderLeft: '2px solid',
                          borderColor: '#53A6D6'
                        }} /> : <Box sx={{
                          pr: '7px'
                        }} />}

                              {window?.location?.pathname === subItem.key && subItem.iconFilled ? <subItem.iconFilled fill={isSubItem(subItem) ? '#53A6D6' : '#1D252D'} /> : <subItem.icon fill={isSubItem(subItem) ? '#53A6D6' : '#1D252D'} />}
                            </>
                            <Typography variant="tableHeader" sx={{
                        fontSize: 14,
                        pl: '24px',
                        mt: '5px',
                        color: isSubItem(subItem) ? '#53A6D6' : '#1D252D'
                      }}>
                              {subItem.name}
                            </Typography>
                            {popoverData.badge && <Typography flexGrow="1" variant="tableHeader" sx={{
                        fontSize: 14,
                        pr: '14px',
                        mt: '5px',
                        color: 'inherit',
                        textAlign: 'right'
                      }}>
                                {popoverData.badge}
                              </Typography>}
                          </Stack>
                        </Button>
                      </NavLink>)}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Popover>
      </Box>

      {releaseNotesModalOpen && <ReleaseNotesModal handleClose={() => setReleaseNotesModalOpen(false)} />}
    </>;
};