import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { LicenseInfo } from '@mui/x-license';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import { Icon } from '../../icon/icon.component';
import { FilterIcon } from '../icon';
export const FilterMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [yOffset, setYOffset] = React.useState(0);
  React.useEffect(() => {
    const filterButton = document.getElementById('filter-menu').getBoundingClientRect();
    const header = document.getElementById('page-header')?.getBoundingClientRect();
    setYOffset(Math.abs(header.top - filterButton.top));
  }, [document.getElementById('filter-menu')?.getBoundingClientRect()]);
  LicenseInfo.setLicenseKey(MUIX_API);
  const handleClick = event => {
    setAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return <React.Fragment>
      <Box id="filter-menu" sx={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: 'Nexa-Regular',
      fontWeight: 400,
      lineHeight: '16.8px'
    }}>
        <Tooltip title="Filters">
          <IconButton onClick={handleClick}>
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Popper anchorEl={anchorEl} id="account-menu" sx={{
      zIndex: 200
    }} open={open} onClose={handleClose} title="Filters" placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{
          p: 1,
          borderRadius: '16px',
          overflow: 'visible',
          width: '310px',
          overflowY: 'scroll',
          maxHeight: yOffset < 200 ? '75vh' : '40vh',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid container justifyContent="center">
                <Typography variant="tableHeader"> Filters</Typography>
              </Grid>

              {props.filterOptions.filter(f => !f.hidden?.hideInFilterList).map(filter => {
              const {
                key: k,
                ...subProps
              } = filter;
              return <div key={k}>
                      {React.createElement(filter.component, {
                  ...subProps,
                  filterKey: k,
                  filters: props.filters,
                  handleFilterChange: props.handleFilterChange
                })}
                      <Divider sx={{
                  my: 1
                }} />
                    </div>;
            })}

              <Grid container justifyContent="flex-end" sx={{
              height: '50px',
              position: 'sticky',
              bottom: '-8px',
              background: '#fff'
            }}>
                <Button variant="text" onClick={() => {
                props.handleFilterChange({});
                handleClose();
              }}>
                  <Typography sx={{
                  textTransform: 'none',
                  color: '#53A6D6'
                }}>Clear all </Typography>
                </Button>
              </Grid>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>;
};