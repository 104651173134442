import * as React from 'react';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { AntSwitch } from '../switch/ant-switch.component';
import { styled } from '@mui/system';
import { Stack } from '@mui/material';
const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#53A6D6'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
const SearchBox = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '14px'
  }
}));
export const MultiSelectFilter = props => {
  const {
    showChip,
    filterKey,
    filters,
    disabled,
    chipStyle,
    label,
    handleFilterChange,
    options,
    pluralLabel,
    localStorageKey
  } = props;
  if (showChip) {
    if (!filters[filterKey]) {
      return null;
    }
    const option = options.find(option => option?.value === filters[filterKey][0]);
    if (!option) {
      return null;
    }
    const extra = filters[filterKey].length > 1 ? ` +${filters[filterKey].length - 1}` : '';
    return <Chip sx={{
      margin: '8px',
      backgroundColor: '#DAEFFF',
      color: '#53A6D6',
      borderRadius: '8px',
      textTransform: 'none'
    }} label=<span>
          {label}: {option.chipLabel || option.label}
          {extra}
        </span> onDelete={() => {
      const {
        [filterKey]: _,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, JSON.stringify([]));
      }
    }} deleteIcon={<CloseOutlinedIcon sx={{
      transform: 'scale(0.75)'
    }} />} className={chipStyle} />;
  }
  const [selectAll, setSelectAll] = React.useState(filters[filterKey] && filters[filterKey].length > 0 || props.selectNone ? false : true);
  const [itemSearch, setItemSearch] = React.useState();
  const [showMoreValue, setShowMoreValue] = React.useState(10);
  const [onlyButton, setOnlyButton] = React.useState(null);
  const getChecked = option => {
    return selectAll || (filters[filterKey] || []).indexOf(option.value) > -1 || !!filters[filterKey]?.find(f => f == option.value);
  };
  return <FormGroup className="pt-1">
      <FormLabel>
        <Typography variant="tableHeader">{label}</Typography>
      </FormLabel>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <Typography> Select all </Typography>
        </Grid>
        <Grid xs={4}>
          <AntSwitch sx={{
          ml: '40px'
        }} disabled={disabled} checked={selectAll} onChange={() => {
          let {
            [filterKey]: _,
            ...newFilters
          } = filters || {};
          handleFilterChange(newFilters);
          setSelectAll(!selectAll);
          if (localStorageKey) {
            localStorage.removeItem(localStorageKey);
          }
        }} inputProps={{
          'aria-label': 'ant design'
        }} />
        </Grid>
      </Grid>
      {options?.length >= 10 && <Box sx={{
      py: 1
    }}>
          <SearchBox fullWidth disabled={disabled} onChange={(e, v) => {
        setItemSearch(e.target.value);
      }} id="outlined-basic" label={'Search ' + (pluralLabel ? pluralLabel : label + 's')} variant="outlined" />
        </Box>}
      {options?.filter(option => !itemSearch || (option.label || '').toLowerCase().includes(itemSearch.toLowerCase())).slice(0, itemSearch ? options.length : showMoreValue).map((option, index) => <Box sx={{
      display: 'flex'
    }} className="d-flex w-100 justify-content-between" key={option.value} onMouseEnter={() => setOnlyButton(index)} onMouseLeave={() => setOnlyButton(null)}>
            <FormControlLabel sx={{
        ml: '-4px'
      }} control={<CustomCheckbox disabled={disabled} checked={getChecked(option)} onClick={e => {
        if (!e.target.checked) {
          // Item turned off
          if (selectAll) {
            // Set all to off
            setSelectAll(false);
            // Set the search options to everything except the one item.
            handleFilterChange({
              ...(filters || {}),
              [filterKey]: options.filter(o => o.value !== option.value).map(o => o.value)
            });
            if (localStorageKey) {
              localStorage.setItem(localStorageKey, JSON.stringify(options.filter(o => o.value !== option.value).map(o => o.value)));
            }
          } else {
            // Remove the one item from current items
            handleFilterChange({
              ...(filters || {}),
              [filterKey]: (filters[filterKey] || []).filter(o => o !== option.value)
            });
            if (localStorageKey) {
              if ((filters[filterKey] || []).filter(o => o !== option.value).length == 0) {
                localStorage.removeItem(localStorageKey);
              } else {
                localStorage.setItem(localStorageKey, JSON.stringify((filters[filterKey] || []).filter(o => o !== option.value)));
              }
            }
          }
        } else {
          // Item turned on
          if ((filters[filterKey] || []).length + 1 === options.length) {
            // All are selected
            let {
              [filterKey]: _,
              ...newFilters
            } = filters || {};
            handleFilterChange(newFilters);
            setSelectAll(true);
            if (localStorageKey) {
              localStorage.removeItem(localStorageKey);
            }
          } else {
            handleFilterChange({
              ...(filters || {}),
              [filterKey]: [...(filters[filterKey] || []), option.value]
            });
            if (localStorageKey) {
              localStorage.setItem(localStorageKey, JSON.stringify([...(filters[filterKey] || []), option.value]));
            }
          }
        }
      }} />} label={<Stack spacing="10px" display="flex" alignItems="center" direction="row">
                  {' '}
                  {option.icon} <Typography sx={{
          color: option.color || '#1D252D'
        }}> {option.label}</Typography>
                </Stack>} />
            {onlyButton == index && <Button sx={{
        height: '32px',
        width: '80px',
        backgroundColor: '#DAEFFF',
        borderRadius: '23px',
        ':hover': {
          backgroundColor: '#DAEFFF'
        }
      }} onClick={e => {
        setSelectAll(false);
        handleFilterChange({
          ...(filters || {}),
          [filterKey]: [option.value]
        });
        if (localStorageKey) {
          localStorage.setItem(localStorageKey, JSON.stringify([option.value]));
        }
      }}>
                <Typography sx={{
          textTransform: 'none',
          color: '#53A6D6'
        }}>Only </Typography>
              </Button>}
          </Box>)}

      {options?.length > showMoreValue && !itemSearch && <Button onClick={() => {
      setShowMoreValue(showMoreValue + 5);
    }}>
          <Typography sx={{
        textTransform: 'none',
        color: '#53A6D6'
      }}>Show more </Typography>
        </Button>}
    </FormGroup>;
};