import React, { useContext, useEffect } from 'react';
import { Drawer } from '../drawer.component';
import { getOptStatusSMS, sendCustomerMessage, getPhoneNumbers, getCustomerDetails, getCustomerMessages, unsubscribeEmail, updateSMSStatus, getCustomerActions, getCustomer, getCustomerSubscriptions, getCustomerRewards } from '../../../shared/common.api';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import { toasterService } from '../../toaster/toaster-service';
import { DetailsComponent } from './details.component';
import { MessagingComponent } from './messaging.component';
import { TimelineComponent } from './timeline.component';
import { TransactionsComponent } from './transactions.component';
import moment from 'moment-timezone';
import { UserStateContext } from 'context/user-state-context';
import { Box, Stack, Typography } from '@mui/material';
import { Calendar30DaysIcon, CustomerDetailFilledIcon, FactCheckFilledIcon, FactCheckIcon, MessagingFilledIcon, MessagingIcon, TimelineFilledIcon, TimelineIcon, TransactionIcon } from 'components/mui';
import { SchedulesComponent } from './schedules.component';
import CircularProgress from '@mui/material/CircularProgress';
import { SubscriptionsComponent } from './subscriptions.component';
import { RewardsComponent } from './rewards.component';
const audio = new Audio('/assets/aud/wet.mp3');
const _tabs = [{
  key: 'summary',
  name: 'Summary',
  icon: CustomerDetailFilledIcon
}, {
  key: 'timeline',
  name: 'Timeline',
  icon: TimelineIcon,
  selectedIcon: TimelineFilledIcon
}, {
  key: 'messaging',
  name: 'Messaging',
  icon: MessagingIcon,
  selectedIcon: MessagingFilledIcon
}, {
  key: 'details',
  name: 'Details',
  icon: FactCheckIcon,
  selectedIcon: FactCheckFilledIcon
}];
export const DrawerCustomer = props => {
  const {
    asCompany,
    hasPermission,
    companyFields,
    flags
  } = useContext(UserStateContext);
  const [selectedTab, setSelectedTab] = React.useState('summary');
  const [customerDetails, setCustomerDetails] = React.useState(null);
  const [customerSchedules, setCustomerSchedules] = React.useState(null);
  const [customerTransactions, setCustomerTransactions] = React.useState(null);
  const [customerTimeline, setCustomerTimeline] = React.useState(null);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [messages, setMessages] = React.useState(null);
  const [selectedTransaction, setSelectedTransaction] = React.useState(null);
  const [customer, setCustomer] = React.useState(null);
  const [onHover, setOnHover] = React.useState(null);
  const messageTimeout = React.useRef();
  const messageController = React.useRef();
  const [filters, setFilters] = React.useState({
    date_after: moment().subtract(30, 'days').startOf('day').toISOString()
  });
  const [customerTimelineSummary, setCustomerTimelineSummary] = React.useState(null);
  const [selectedTimelineView, setSelectedTimelineView] = React.useState('summary');
  const [selectedDetailsTab, setSelectedDetailsTab] = React.useState('transactions');
  const [selectedSchedule, setSelectedSchedule] = React.useState(null);
  const [isLoadingTimeline, setIsLoadingTimeline] = React.useState(true);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = React.useState(true);
  const [isLoadingTransactions, setIsLoadingTransactions] = React.useState(true);
  const [isLoadingSchedules, setIsLoadingSchedules] = React.useState(true);
  const [isLoadingDetails, setIsLoadingDetails] = React.useState(true);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = React.useState(true);
  const [customerSubscriptions, setCustomerSubscriptions] = React.useState(null);
  const [isLoadingRewards, setIsLoadingRewards] = React.useState(true);
  const [customerRewards, setCustomerRewards] = React.useState(null);
  const [_detailsTabs, setDetailsTabs] = React.useState([{
    key: 'transactions',
    name: 'Transactions',
    icon: TransactionIcon
  }]);
  useEffect(() => {
    if (!asCompany?.id) {
      // Initial load. Not loaded enough yet.
      return;
    }

    // Get company phone numbers
    if (hasPermission('customer.view_two_way_messaging')) {
      getPhoneNumbers({
        company: asCompany.id,
        limit: 100
      }).then(({
        data
      }) => setPhoneNumbers(data.results));
    }
  }, [hasPermission, asCompany?.id]);
  useEffect(() => {
    const tabs = [{
      key: 'transactions',
      name: 'Transactions',
      icon: TransactionIcon
    }];
    if (companyFields?.find(model => model.id == 'schedule')) {
      tabs.push({
        key: 'schedules',
        name: 'Schedules',
        icon: Calendar30DaysIcon
      });
    }
    if (companyFields?.find(model => model.id == 'subscription') && flags?.customer_drawer_details_new_tabs) {
      tabs.push({
        key: 'subscriptions',
        name: 'Subscriptions',
        icon: TransactionIcon
      });
    }
    if (companyFields?.find(model => model.id == 'reward') && flags?.customer_drawer_details_new_tabs) {
      tabs.push({
        key: 'rewards',
        name: 'Rewards',
        icon: TransactionIcon
      });
    }
    setDetailsTabs(tabs);
  }, [flags, companyFields]);
  useEffect(() => {
    if (!hasPermission || !props?.customerID || !hasPermission('customer.view_customer')) {
      // We're not fully loaded or don't have permission
      return;
    }

    // Load the details for the customer
    loadCustomerDetails();
  }, [props?.customerID, hasPermission]);
  useEffect(() => {
    if (!customer?.phone || !phoneNumbers.length || selectedTab != 'messaging') {
      // We're not fully loaded or we don't have SMS enabled
      return;
    }

    // Once the details are loaded, load the messages
    messageTimeout.current = setTimeout(getUpdates, 5000);
    return () => {
      clearTimeout(messageTimeout.current);
      if (messageController.current) {
        // cancel the current running api call
        messageController.current.abort();
      }
    };
  }, [customer?.phone, phoneNumbers, selectedTab]);
  const getUpdates = lastCheck => {
    const newLastCheck = new Date();
    getOptStatusSMS({
      phone: customer.phone,
      company: asCompany.id
    }).then(({
      data
    }) => {
      if (!data || !data.results) {
        return;
      }
      setCustomerDetails(details => {
        if (data.results.length > 0 && !details?.sms_optstatus) {
          return {
            ...details,
            sms_optstatus: data.results[0].status
          };
        } else if (data.results.length === 0 && details?.sms_optstatus) {
          return {
            ...details,
            sms_optstatus: 'pending'
          };
        }
        return details;
      });
    });
    const promise = getCustomerMessages(customer.id, {
      after: moment(lastCheck).subtract(3, 'minutes').toDate()
    });
    messageController.current = promise.controller;
    promise.then(({
      data
    }) => {
      if (data.length) {
        setMessages(messages => {
          if (!messages) {
            messages = [];
          }
          let hasNewMessage = false;
          data.filter(message => message.twilio_phone != '+13852179309' && message.twilio_phone != '+18018500116' && message.twilio_phone != '+18559524624').reverse().forEach(message => {
            const oldIndex = messages.findIndex(oldMessage => oldMessage.id === message.id);
            if (oldIndex > -1) {
              messages[oldIndex] = message;
            } else {
              if (message.direction === 'incoming') {
                hasNewMessage = true;
              }
              messages.push(message);
            }
          });
          if (hasNewMessage) {
            audio.play();
          }
          return messages;
        });
      }
      messageTimeout.current = setTimeout(() => getUpdates(newLastCheck), 5000);
    }).catch(e => console.error(e));
  };
  const unsubscribeCustomer = emailStatus => {
    if (!customer.company || !customer.email) {
      toasterService.error('Customer is missing required information to unsubscribe');
      return;
    }
    unsubscribeEmail(customer.company, customer.email, customerDetails.unsubscribe_id, emailStatus).then(({
      data
    }) => {
      setCustomerDetails({
        ...customerDetails,
        unsubscribe_id: data.id,
        email_optstatus: emailStatus
      });
      toasterService.success('Successfully unsubscribed customer from future emails');
    }).catch(() => {
      toasterService.error('There was an unknown error trying to unsubscribe the customer');
    });
  };
  const unsubscribeCustomerSMS = () => {
    if (!customer.company || !customer.phone) {
      toasterService.error('Customer is missing required information to unsubscribe');
      return;
    }
    updateSMSStatus(customer.company, customer.phone, customerDetails.sms_optstatus_id, 'opt_out').then(response => {
      const data = response.data;
      customerDetails.sms_optstatus_id = data.id;
      customerDetails.sms_optstatus = data.status;
      setCustomerDetails({
        ...customerDetails
      });
      toasterService.success('Successfully unsubscribed customer from future text messages');
    }).catch(() => {
      toasterService.error('There was an unknown error trying to unsubscribe the customer');
    });
  };
  const resetCustomerSMS = () => {
    if (!customer.company || !customer.phone) {
      toasterService.error('Customer is missing required information to unsubscribe');
      return;
    }
    updateSMSStatus(customer.company, customer.phone, customerDetails.sms_optstatus_id, 'pending').then(response => {
      const data = response.data;
      customerDetails.sms_optstatus_id = data.id;
      customerDetails.sms_optstatus = data.status;
      setCustomerDetails({
        ...customerDetails
      });
      toasterService.success('Successfully reset customer status for future text messages');
    }).catch(() => {
      toasterService.error('There was an unknown error trying to reset status for the customer');
    });
  };
  const sendMessage = (message, phoneNumber, attachments) => {
    return sendCustomerMessage(customer.id, {
      message,
      phone: phoneNumber,
      attachments: attachments.map(a => a.url)
    }).then(({
      data
    }) => {
      audio.play();
      setMessages([...messages, data]);
    }).catch(() => {
      toasterService.error('Unable to send message, Please try again.');
    });
  };
  const handleTab = tab => {
    if (tab == 'details') {
      setSelectedTab('details');
      props.onTabChange('transactions');
    } else if (tab == 'transactions' || tab == 'schedules' || tab == 'subscriptions' || tab == 'rewards') {
      setSelectedTab('details');
      setSelectedDetailsTab(tab);
      props.onTabChange(tab);
    } else {
      setSelectedTab(tab);
      props.onTabChange(tab);
    }
  };
  const handleDetailsTab = tab => {
    setSelectedTab('details');
    setSelectedDetailsTab(tab);
    props.onTabChange(tab);
  };
  const goToTransaction = transactionId => {
    handleTab('transactions');
    setSelectedTransaction(transactionId);
  };
  const goToSchedule = scheduleId => {
    handleTab('schedules');
    setSelectedSchedule(scheduleId);
  };
  const goToMessages = () => {
    handleTab('messaging');
  };
  const loadTransactions = () => {
    setIsLoadingTransactions(true);
    const params = {
      type: 'transaction'
    };
    const promises = [getCustomerActions(props.customerID, params)];
    Promise.all(promises).then(([actions]) => {
      setCustomerTransactions(actions?.data?.transactions);
      setIsLoadingTransactions(false);
    });
  };
  const loadSchedules = () => {
    setIsLoadingSchedules(true);
    const params = {
      type: 'schedule'
    };
    const promises = [getCustomerActions(props.customerID, params)];
    Promise.all(promises).then(([actions]) => {
      setCustomerSchedules(actions?.data?.schedules);
      setIsLoadingSchedules(false);
    });
  };
  const loadSubscriptions = () => {
    setIsLoadingSubscriptions(true);
    const promises = [getCustomerSubscriptions(props.customerID)];
    Promise.all(promises).then(([actions]) => {
      setCustomerSubscriptions(actions?.data);
      setIsLoadingSubscriptions(false);
    });
  };
  const loadRewards = () => {
    setIsLoadingRewards(true);
    const promises = [getCustomerRewards(props.customerID)];
    Promise.all(promises).then(([actions]) => {
      setCustomerRewards(actions?.data);
      setIsLoadingRewards(false);
    });
  };
  const loadMessages = () => {
    setIsLoadingMessages(true);
    const promises = [getCustomerMessages(props.customerID)];
    Promise.all(promises).then(([messages]) => {
      // TODO: Hardcoded outbound numbers. May need to fix some day. But not today

      setMessages(messages.data.filter(message => message.twilio_phone != '+13852179309' && message.twilio_phone != '+18018500116' && message.twilio_phone != '+18559524624').reverse());
      setIsLoadingMessages(false);
    });
  };
  const loadTimeline = () => {
    setIsLoadingTimeline(true);
    const params = {
      ...filters
    };
    const promises = [getCustomerActions(props.customerID, params)];
    Promise.all(promises).then(([actions]) => {
      let timeline = [];
      let timelineSummary = [];
      actions.data.sms.forEach(sms => {
        sms.events.reduce((timeline, event) => {
          timeline.push({
            id: event.id,
            created_when: event.created_when,
            type: 'SMS',
            detail1: event.event,
            detail2: sms.message
          });
          return timeline;
        }, timeline);
      });

      //sms summary view
      actions.data.sms.forEach(sms => {
        //determine best event status to report
        let status = 'queued';
        for (let i = 0; i < sms.events.length; i++) {
          if (sms.events[i].event == 'delivered' || sms.events[i].event == 'sent') {
            status = sms.events[i].event;
            break;
          }
          if (sms.events[i].event == 'undelivered' || sms.events[i].event == 'failed') {
            status = sms.events[i].event;
          }
        }
        timelineSummary.push({
          id: sms.id,
          created_when: sms.created_when,
          type: 'SMS',
          detail1: status,
          detail2: `${sms.message || ''}`,
          data: sms
        });
      });

      //transactions log view
      actions.data.transactions.reduce((timeline, transaction) => {
        timeline.push({
          id: transaction.id,
          created_when: transaction.transaction_when,
          type: 'Transaction',
          detail1: `$${transaction.total}`,
          detail2: `Invoice #${transaction.entity_number || ''}`
        });
        return timeline;
      }, timeline);

      //transaction summary view
      actions.data.transactions.forEach(transaction => {
        timelineSummary.push({
          id: transaction.id,
          created_when: transaction.transaction_when,
          type: 'Transaction',
          detail1: `$${transaction.total}`,
          detail2: `${transaction.location_friendly_name || 'Unnamed Location'}`,
          data: transaction
        });
      });
      actions.data.mailers.reduce((timeline, mailer) => {
        timeline.push({
          id: mailer.id,
          created_when: mailer.created_when,
          type: 'Mailer',
          detail1: mailer.mail_status,
          detail2: mailer.template_name
        });
        return timeline;
      }, timeline);
      actions.data.mailers.forEach(mailer => {
        timelineSummary.push({
          id: mailer.id,
          created_when: mailer.created_when,
          type: 'Mailer',
          detail1: mailer.mail_status,
          detail2: mailer.template_name
        });
      });
      actions.data.emails.forEach(email => {
        email.events.reduce((timeline, event) => {
          timeline.push({
            id: event.id,
            // this is probably not whats wanted, but will check later
            created_when: event.created_when,
            type: 'Email',
            detail1: event.event,
            detail2: email.template_name
          });
          return timeline;
        }, timeline);
      });

      //email summary view
      actions.data.emails.forEach(email => {
        const detail2 = email.template_subject + ' - ' + email.template_summary;

        //determine best event status to report
        let status = 'processed';
        for (let i = 0; i < email.events.length; i++) {
          if (email.events[i].event == 'open' || email.events[i].event == 'clicked') {
            status = email.events[i].event;
            break;
          }
          if (email.events[i].event == 'bounced') {
            status = email.events[i].event;
          }
          if (email.events[i].event == 'delivered' && status != 'bounced') {
            status = email.events[i].event;
          }
        }
        timelineSummary.push({
          id: email.id,
          created_when: email.created_when,
          type: 'Email',
          //detail1: email.status_event_type,
          detail1: status,
          detail2: detail2,
          sg_message_id: email.sg_message_id,
          events: email.events
        });
      });
      actions.data.schedules.reduce((timeline, schedule) => {
        timeline.push({
          id: schedule.id,
          created_when: schedule.appointment,
          type: 'Schedule',
          detail1: schedule.type,
          detail2: moment(schedule.appointment).format('MMM D, YYYY hh:mm A')
        });
        return timeline;
      }, timeline);

      //schedules summary view
      actions.data.schedules.forEach(schedule => {
        timelineSummary.push({
          id: schedule.id,
          created_when: schedule.appointment,
          type: 'Schedule',
          detail1: schedule.type,
          data: schedule
        });
      });
      actions.data.reviews.reduce((timeline, review) => {
        timeline.push({
          id: review.id,
          created_when: review.review_when,
          type: 'Review',
          detail1: review.score,
          detail2: review.comment
        });
        return timeline;
      }, timeline);

      //reviews summary view
      actions.data.reviews.forEach(review => {
        timelineSummary.push({
          id: review.id,
          created_when: review.review_when,
          type: 'Review',
          detail1: review.reply_when,
          detail2: review.normalized_score,
          comment: review.comment
        });
      });
      //journeys log view
      actions.data.journeys.reduce((timeline, journey) => {
        timeline.push({
          id: journey.id,
          created_when: journey.created_when,
          type: 'Journey',
          detail1: journey.journey_name,
          customer_session_id: journey.customer_session_id
        });
        return timeline;
      }, timeline);

      //journeys summary view
      actions.data.journeys.forEach(journey => {
        timelineSummary.push({
          id: journey.id,
          created_when: journey.created_when,
          type: 'Journey',
          detail1: journey.journey_name,
          customer_session_id: journey.customer_session_id,
          journey_id: journey.journey_id
        });
      });
      // log view survey
      actions.data.surveys.reduce((timeline, survey) => {
        timeline.push({
          id: survey.id,
          created_when: survey.started_when,
          type: 'Survey',
          detail1: 'Survey Started',
          detail2: 'Score: ' + survey.overall_score * 100 + '%'
        });
        return timeline;
      }, timeline);

      //survey summary view
      actions.data.surveys.forEach(survey => {
        timelineSummary.push({
          id: survey.id,
          created_when: survey.started_when,
          type: 'Survey',
          detail1: survey.survey_name
        });
      });
      timeline = timeline.sort((a, b) => {
        if (a.created_when === b.created_when) return 0;
        return a.created_when > b.created_when ? -1 : 1;
      });
      timelineSummary = timelineSummary.sort((a, b) => {
        if (a.created_when === b.created_when) return 0;
        return a.created_when > b.created_when ? -1 : 1;
      });
      setCustomerTimeline(timeline);
      setCustomerTimelineSummary(timelineSummary);
      setIsLoadingTimeline(false);
    });
  };
  const loadCustomerDetails = () => {
    setIsLoadingDetails(true);
    handleTab(props.startTab);
    setCustomerDetails(null);
    const promises = [getCustomerDetails(props.customerID), getCustomer(props.customerID)];
    Promise.all(promises).then(([details, customer]) => {
      if (customer) {
        setCustomer(customer.data);
      }
      setCustomerDetails(details.data);
      setIsLoadingDetails(false);
    });
  };
  const updateFilters = filters => {
    setFilters(filters);
  };
  useEffect(() => {
    if (!initialLoad) {
      loadTimeline();
    }
    setInitialLoad(false);
  }, [filters]);
  useEffect(() => {
    if (selectedTab == 'timeline' && !customerTimeline && !customerTimelineSummary) {
      loadTimeline();
    } else if (selectedTab == 'messaging' && !messages) {
      loadMessages();
    }
  }, [selectedTab]);
  useEffect(() => {
    if (selectedTab == 'details' && selectedDetailsTab === 'transactions' && !customerTransactions) {
      loadTransactions();
    } else if (selectedTab == 'details' && selectedDetailsTab == 'schedules' && !customerSchedules) {
      loadSchedules();
    } else if (selectedTab == 'details' && selectedDetailsTab == 'subscriptions' && !customerSchedules) {
      loadSubscriptions();
    } else if (selectedTab == 'details' && selectedDetailsTab == 'rewards' && !customerRewards) {
      loadRewards();
    }
  }, [selectedTab, selectedDetailsTab]);

  // console.log(customerDetails, 'customerDetails');

  return <Drawer open={props.isOpen} onClose={e => {
    setFilters({
      date_after: moment().subtract(30, 'days').startOf('day').toISOString()
    });
    setCustomer(null);
    setCustomerDetails(null);
    setCustomerSchedules(null);
    setCustomerSubscriptions(null);
    setCustomerRewards(null);
    setIsLoadingSchedules(true);
    setCustomerTimeline(null);
    setCustomerTimelineSummary(null);
    setIsLoadingTimeline(true);
    setInitialLoad(true);
    setIsLoadingMessages(true);
    setIsLoadingTransactions(true);
    setMessages(null);
    setCustomerTransactions(null);
    setSelectedTab('summary');
    setSelectedTransaction(null);
    setOnHover(null);
    setSelectedTimelineView('summary');
    setSelectedDetailsTab('transactions');
    setSelectedSchedule(null);
    setIsLoadingDetails(true);
    props.onClose();
  }} allowBackdropClick size="xl">
      <Scoped css={styles}>
        {!!customer ? <>
            <Stack sx={{
          pt: '30px'
        }} spacing="30px">
              <Box sx={{
            px: '24px'
          }}>
                {!customer.first_name && !customer.last_name ? <Typography fontSize={'24px'}>(No Name)</Typography> : <Typography fontSize={'24px'}>
                    {customer.first_name || '(No First Name)'} {customer.last_name || '(No Last Name)'}
                  </Typography>}
              </Box>

              <Stack sx={{
            px: '24px'
          }} spacing="10px" direction={'row'}>
                {_tabs.map((t, index) => <Box key={index} className="box-button" sx={{
              backgroundColor: selectedTab == t.key ? '#3898D9' : onHover == t.key ? '#F3F3F4' : '#ffffff'
            }} onMouseEnter={() => setOnHover(t.key)} onMouseLeave={() => setOnHover(null)} onClick={() => {
              handleTab(t.key);
            }}>
                    {t.selectedIcon && selectedTab == t.key ? <t.selectedIcon fill={'#ffffff'} /> : <t.icon fill={selectedTab == t.key ? '#ffffff' : '#1D252D'} />}
                    {}
                    <Typography sx={{
                pl: '10px',
                color: selectedTab == t.key ? '#ffffff' : '#1D252D'
              }}>{t.name} </Typography>
                  </Box>)}
              </Stack>

              <Box sx={{
            height: 'calc(100vh - 17rem)'
          }}>
                {selectedTab === 'summary' && <DetailsComponent customer={customer} availableModels={companyFields.map(i => i.id)} availableModelsFields={companyFields} customerDetails={customerDetails} goToSchedule={goToSchedule} unsubscribeCustomer={unsubscribeCustomer} unsubscribeCustomerSMS={unsubscribeCustomerSMS} resetCustomerSMS={resetCustomerSMS} isLoading={isLoadingDetails} loadCustomerDetails={loadCustomerDetails} />}

                {selectedTab === 'timeline' && <TimelineComponent customerTimeline={customerTimeline} customerTimelineSummary={customerTimelineSummary} customerDetails={customerDetails} goToSchedule={goToSchedule} goToMessages={goToMessages} goToTransaction={goToTransaction} filters={filters} updateFilters={updateFilters} customerID={props.customerID} selectedTimelineView={selectedTimelineView} setSelectedTimelineView={setSelectedTimelineView} isLoading={isLoadingTimeline} />}
                {selectedTab === 'messaging' && customerDetails && <MessagingComponent messages={messages} customerDetails={customerDetails} customer={customer} phoneNumbers={phoneNumbers} sendMessage={sendMessage} isLoading={isLoadingMessages} />}

                {selectedTab === 'details' && selectedDetailsTab === 'transactions' && <TransactionsComponent transactions={customerTransactions} customerDetails={customerDetails} availableModelsFields={companyFields} availableModels={companyFields.map(i => i.id)} selectedTransaction={selectedTransaction || props.startTransaction} filters={filters} updateFilters={updateFilters} isLoading={isLoadingTransactions} _detailsTabs={_detailsTabs} handleDetailsTab={handleDetailsTab} selectedDetailsTab={selectedDetailsTab} scrollToTransactionDetails={props.scrollToTransactionDetails} />}

                {selectedTab === 'details' && selectedDetailsTab === 'schedules' && <SchedulesComponent schedules={customerSchedules} selectedSchedule={selectedSchedule} availableModelsFields={companyFields} availableModels={companyFields.map(i => i.id)} _detailsTabs={_detailsTabs} handleDetailsTab={handleDetailsTab} selectedDetailsTab={selectedDetailsTab} isLoading={isLoadingSchedules} />}

                {selectedTab === 'details' && selectedDetailsTab === 'subscriptions' && <SubscriptionsComponent isLoading={isLoadingSubscriptions} subscriptions={customerSubscriptions} customerDetails={customerDetails} availableModelsFields={companyFields} availableModels={companyFields.map(i => i.id)} _detailsTabs={_detailsTabs} handleDetailsTab={handleDetailsTab} selectedDetailsTab={selectedDetailsTab} />}

                {selectedTab === 'details' && selectedDetailsTab === 'rewards' && <RewardsComponent isLoading={isLoadingRewards} rewards={customerRewards} customerDetails={customerDetails} availableModelsFields={companyFields} availableModels={companyFields.map(i => i.id)} _detailsTabs={_detailsTabs} handleDetailsTab={handleDetailsTab} selectedDetailsTab={selectedDetailsTab} />}
              </Box>
            </Stack>
          </> : <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: '225px'
      }}>
            <CircularProgress />
          </Box>}
      </Scoped>
    </Drawer>;
};