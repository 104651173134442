import { Help } from '@mui/icons-material';
import { Box, Button, Card, Divider, IconButton, Stack, Typography, Grid } from '@mui/material';
import { ArrowCooldownIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, HelpIcon, InformationIcon, StatusDotIcon } from 'components/mui';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
export function ImportDetails({
  open,
  close,
  importDetails
}) {
  const [showMoreErrors, setShowMoreErrors] = useState(false);
  const importTypeMapping = {
    optout_email: 'Email Opt Out',
    optin_email: 'Email Opt In',
    optout_phone: 'Phone Opt Out',
    optin_phone: 'Phone Opt In'
  };
  const importTypeDisplay = importTypeMapping[importDetails.type] || (importDetails.type ? importDetails.type.charAt(0).toUpperCase() + importDetails.type.slice(1) : 'None');
  const toggleShowMoreErrors = () => {
    setShowMoreErrors(!showMoreErrors);
  };
  const errorsToShow = showMoreErrors ? importDetails.options.import_issues.slice(0, 100) : importDetails.options.import_issues.slice(0, 15);
  const makeOverflowTooltip = (content, length, bold) => {
    if (content) {
      return <Tooltip title={content.length > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {content.length > length ? <>{('' + content).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <>No details</>;
  };
  const LightTooltip = styled(({
    className,
    ...props
  }) => <Tooltip {...props} classes={{
    popper: className
  }} />)(({
    theme
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth: '312px',
      borderRadius: '16px',
      padding: '12px',
      lineHeight: '16px'
    }
  }));
  return <>
      <Card sx={{
      padding: 2,
      margin: 2,
      borderRadius: '14px',
      overflowY: 'auto'
    }} elevation={3}>
        <Box sx={{
        margin: '8px'
      }}>
          <Stack direction={'row'} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
            <Box sx={{
            py: '24px'
          }}>
              <h1>Import Details:</h1>
            </Box>
            <Box>
              <IconButton onClick={() => {
              close();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row" spacing={3} sx={{
          display: 'flex',
          alignItems: 'flex-start'
        }}>
            <Stack direction={'column'} id="import-details-row-labels" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            pl: '30px'
          }} spacing={3}>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>File Name:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>File Type:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>File Source:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Segment Name:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Created on:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Created by:</Typography>
              </Stack>
              <Stack direction={'row'} sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Box>
                  <Typography fontWeight={'bold'} sx={{
                  pr: '16px'
                }}>
                    Import Status:
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack direction={'column'} id="import-details-row-data" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            pl: '40px'
          }} spacing={3}>
              <Typography>{`${importDetails.filename ? importDetails.filename : 'None'}`}</Typography>
              <Typography>{importTypeDisplay}</Typography>
              <Typography>{importDetails.source ? importDetails.source : 'None'}</Typography>
              <Typography>{importDetails.options.new_segment ? importDetails.options.new_segment : 'None'}</Typography>
              <Typography>{moment(importDetails.created_when).format('MMMM Do YYYY')}</Typography>
              <Typography>
                {importDetails.created_by_first_name && importDetails.created_by_last_name ? importDetails.created_by_first_name + ' ' + importDetails.created_by_last_name : 'None'}
              </Typography>

              <Stack direction={'row'} spacing={1} sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <StatusDotIcon fill={importDetails.status === 'success' ? '#66CC99' : importDetails.status === 'paused' || importDetails.status === 'stopped' || importDetails.status === 'partial_success' ? '#F3D250' : '#F30F0F'} />
                </Box>
                <Box>
                  <Typography>
                    {importDetails.status === 'partial_success' ? 'Partial Success' : importDetails.status.charAt(0).toUpperCase() + importDetails.status.slice(1)}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{
          mt: '24px',
          mb: '24px'
        }} />
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          py: '24px'
        }}>
            <h1>Import Mapping:</h1>
          </Box>
          <Stack direction="row" spacing={3} sx={{
          display: 'flex',
          alignItems: 'flex-start',
          alignContent: 'flex-start'
        }}>
            <Stack id="import-mapping-row-labels" direction="column" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            pl: '30px'
          }} spacing={3}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Stack direction={'row'} spacing={1} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '16px'
              }}>
                  <Typography fontWeight="bold">Total Records:</Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography sx={{
                      fontWeight: 'bold'
                    }}>Total Records</Typography>
                          <br />
                          <Typography sx={{
                      textAlign: 'left'
                    }}>
                            This is the total count of records in your CSV file that were processed during the import. This includes records
                            that were imported, updated, or that were incompatable. This will also include any duplicate records in the CSV
                            file.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} size="sm" />
                    </Box>
                  </LightTooltip>
                </Stack>
              </Box>

              <Box>
                <Typography fontWeight="bold">Records Imported:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Records Updated:</Typography>
              </Box>

              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                <Stack direction={'row'} spacing={1} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '16px'
              }}>
                  <Typography fontWeight="bold">Incompatable Data:</Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography sx={{
                      fontWeight: 'bold'
                    }}>Incompatable Data</Typography>
                          <br />
                          <Typography sx={{
                      textAlign: 'left'
                    }}>
                            This is a count of the amount of CSV cells in your file that had data that was not imported. This could be due
                            to data that was not formatted correctly before using the import process.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box sx={{
                    height: '20px',
                    width: '20px'
                  }}>
                      <HelpIcon fill={'#53A6D6'} size="sm" />
                    </Box>
                  </LightTooltip>
                </Stack>
              </Box>
              <Box>
                <Typography fontWeight="bold">Unique ID:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Import Issues:</Typography>
              </Box>
              {importDetails?.options?.import_issues && <Box>
                  <Typography fontWeight="bold">Issue Description:</Typography>
                </Box>}
            </Stack>
            <Stack direction={'column'} id="import-mapping-row-data" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            pl: '30px'
          }} spacing={3}>
              <Typography>{importDetails.total_records ? importDetails.total_records : 'None'}</Typography>
              <Typography>{importDetails.records_inserted ? importDetails.records_inserted : 'None'}</Typography>
              <Typography>{importDetails.records_updated ? importDetails.records_updated : 'None'}</Typography>
              <Typography>{importDetails.records_skipped ? importDetails.records_skipped : 'None'}</Typography>
              <Typography>
                {importDetails?.options?.unique_id ? importDetails?.options?.unique_id.charAt(0).toUpperCase() + importDetails?.options?.unique_id.slice(1) : 'None'}
              </Typography>
              <Typography>{importDetails?.options?.import_issues.length > 0 ? 'Yes' : 'No'}</Typography>
              {importDetails?.options?.import_issues && importDetails.options.import_issues.length > 0 ? <>
                  {errorsToShow.map((issue, index) => <Stack key={index} direction="row" spacing={2} alignItems="center">
                      <Box>
                        <Typography>{`${issue && issue.line ? 'Row: ' + issue.line + ':' : 'File Warning: '} ${issue.issue}`}</Typography>
                      </Box>
                    </Stack>)}
                  {importDetails.options.import_issues.length > 15 && !showMoreErrors || importDetails.options.import_issues.length > 100 ? <Stack direction="row" spacing={1} alignItems="center">
                      <Box>
                        <Typography>{`${importDetails.options.import_issues.length - errorsToShow.length}+ issues found`}</Typography>
                      </Box>
                    </Stack> : null}
                  {importDetails.options.import_issues.length > 15 && <Button onClick={toggleShowMoreErrors}>
                      <Stack sx={{
                  display: 'flex',
                  alignItems: 'center'
                }} direction="row" spacing="10px">
                        <Typography sx={{
                    color: '#3898D9',
                    textTransform: 'none'
                  }}>
                          {showMoreErrors ? 'View less' : 'View more'}
                        </Typography>
                        {showMoreErrors ? <CarrotUpIcon /> : <CarrotDownIcon />}
                      </Stack>
                    </Button>}
                </> : <Typography>No import issues found.</Typography>}
            </Stack>
          </Stack>
          <Divider sx={{
          mt: '24px',
          mb: '24px'
        }} />
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          py: '24px'
        }}>
            <h1>Import Fields:</h1>
          </Box>

          <Stack direction={'row'} spacing={3} sx={{
          display: 'flex',
          alignItems: 'flex-start',
          pl: '30px',
          pb: '24px'
        }}>
            <Box>
              <Typography fontWeight="bold">CSV Field:</Typography>
            </Box>
            <Box id="spacer" sx={{
            pl: '25px',
            pr: '112px'
          }}></Box>
            <Box>
              <Typography fontWeight="bold">Cinch Data Field:</Typography>
            </Box>
          </Stack>
          <Stack direction="row" id="import-mapping-row-data" spacing={3} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: '43px'
        }}>
            {importDetails?.options?.maps && <Stack direction="column" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }} spacing={2}>
                {Object.entries(importDetails.options.maps).map(([key, value]) => <Stack key={key} direction="row" spacing={1} alignItems="center" sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}>
                    <Box sx={{
                width: '120px'
              }}>
                      <Typography>{makeOverflowTooltip(key, 18)}</Typography>
                    </Box>
                    <Box sx={{
                pl: '30px',
                pr: '80px'
              }}>
                      <ArrowCooldownIcon />
                    </Box>
                    <Box sx={{
                flex: 1
              }}>
                      <Typography>{value}</Typography>
                    </Box>
                  </Stack>)}
              </Stack>}
          </Stack>
        </Box>
      </Card>
    </>;
}