import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../../../root.component';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip as MuiTooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DraftIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, PausedIcon, PublishedIcon, ShieldPersonIcon, StoppedIcon } from 'components/mui';
import { getJourneyVolumesWidget, getWidgetInstalls } from 'shared/common.api';
import { styled } from '@mui/material/styles';
import utils from '../../../../shared/utils';
const BorderLinearProgress = styled(LinearProgress)(({
  theme
}) => ({
  height: 10,
  borderRadius: 10,
  [`&.${LinearProgress.colorPrimary}`]: {
    backgroundColor: '#3898D9'
  },
  [`& .${LinearProgress.bar}`]: {
    backgroundColor: '#BAE3FF'
  }
}));
export const JourneyVolumesChart33 = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [activeJourneys, setActiveJourneys] = useState([]);
  const [maxJourneyCustomersTriggered, setMaxJourneyCustomersTriggered] = useState(1);
  useEffect(() => {
    setLoading(true);
    getJourneyVolumesWidget(asCompany.id).then(response => {
      console.log(response.data, 'getJourneyVolumesWidget');
      let maxCustomerCount = 0;
      const journeys = response.data.recentActiveJourneys.filter((j, c) => c < 5); //throw away all but the first 10 for now. Need to add pagination/scrolling to this widget later
      journeys.map(journey => {
        journey.journeyName = journey.journeyName.length > 50 ? journey.journeyName.substring(0, 50) + '...' : journey.journeyName;
        journey.backgroundColor = journey?.status === 'Published' ? '#C5EAD7' : journey?.status === 'Draft' ? '#fff' : journey?.status === 'Paused' ? '#FFEABF' : journey?.status === 'Stopped' ? '#F3C2C2' : '#fff';
        journey.borderColor = journey?.status === 'Published' ? '#3EB87B' : journey?.status === 'Draft' ? '#1D252D' : journey?.status === 'Paused' ? '#FFB82B' : journey?.status === 'Stopped' ? '#EF3C34' : '#1D252D';
        maxCustomerCount = Math.max(maxCustomerCount, journey.customersTriggered);
      });
      setMaxJourneyCustomersTriggered(maxCustomerCount);
      setActiveJourneys(journeys);
      setLoading(false);
    });
  }, []);
  const normalize = value => {
    return value * 100 / maxJourneyCustomersTriggered;
  };
  return <>
      {loading ? <>
          <Stack spacing="36px">
            <Stack direction="row" spacing={'16px'}>
              <Box>
                <DefaultIntegrationIcon />
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Skeleton variant="rounded" width={210} height={20} />
              </Box>
            </Stack>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pb: '12px'
        }}>
              <Skeleton variant="rounded" width={270} height={20} />
            </Box>
          </Stack>
        </> : <Stack sx={{
      width: '100%'
    }}>
          {activeJourneys.map(journey => <Box key={journey.key} sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)',
          borderRadius: '8px'
        }
      }}>
              <Stack spacing="6px" onClick={e => {
          history.push(`/customer-journeys/${journey.id}?breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
        }} sx={{
          width: '100%',
          display: 'flex',
          p: 1
        }}>
                <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
                  <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                    <Typography sx={{
                // fontWeight: '700',
                // lineHeight: '20px',
                // fontSize: '18px',
              }}>
                      {journey.journeyName}
                    </Typography>
                  </Box>

                  <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                    <Typography sx={{
                // fontWeight: '700',
                //  lineHeight: '20px',
                // fontSize: '18px',
              }}>
                      {utils.commaize(journey?.customersTriggered || 0)}
                    </Typography>
                  </Box>
                </Box>
                <LinearProgress variant="determinate" value={normalize(journey?.customersTriggered || 0)} sx={{
            height: '10px',
            borderRadius: '10px',
            '&.MuiLinearProgress-colorPrimary': {
              backgroundColor: journey?.backgroundColor
            },
            '& .MuiLinearProgress-bar': {
              backgroundColor: journey?.borderColor
            }
          }} />
              </Stack>
            </Box>)}
        </Stack>}
    </>;
};