import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';
import { updateFolder, getTemplates, patchPrints, patchSms, patchEmail, createNewFolder, getTemplatesTags, createEmailFromTemplate, postEmail, createSmsFromTemplate, postSms, createPrintFromTemplate, postPrints, getJourneys, getBroadcasts, getSmsBroadcasts, deleteFolder, deleteMail, deleteSms, deleteEmail, duplicateMail, duplicateEmail, duplicateSms, getTemplateCreatedByUsers } from 'shared/common.api';
import { Loader, PageHeaderMui } from 'components';
import TagsMui from 'components/tags/tagsMui.component';
import { TemplatesIcon, DraftIcon, RevisionIcon, InboxIcon, MailIcon, SMSIcon, AddItemIcon, AddFolderIcon, ArchivedIcon, MoveToFolderIcon, AddTagsIcon, DeleteIcon, PublishedIcon, DuplicateIcon, MultiCompanyPrompt, ArchiveIcon, RestoreIcon, InboxMoveIcon, PlaylistAddCheckIcon, HandymanIcon } from 'components/mui';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { UserStateContext } from 'context/user-state-context';
import { Icon } from 'components/icon/icon.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Box, Tab, Tabs, Stack, Typography, Tooltip, IconButton, Menu, MenuItem, Popover, Button } from '@mui/material';
import { TagFilter } from 'components/mui/filter/tags.component';
import { UserFilter } from 'components/mui/filter/user.component';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import NewTemplateDialog from './new-template-dialog.component';
import { TagModal } from 'components/tags/tag-modal.component';
import { ModalContext } from 'context/modal-context';
import FolderSelectDialog from 'components/mui/folder/folder-select-dialog';
import { Modal as NewModal } from '../../components/modal/modal.component';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';
import { Scoped } from 'kremling';
import styles from './template-list-mui.styles.scss';
import { NewButton } from 'components/mui/button/new.component';
const getPatchFunction = type => {
  if (type === 'email') {
    return patchEmail;
  } else if (type === 'mail') {
    return patchPrints;
  } else if (type === 'sms') {
    return patchSms;
  }
};
const templateSizes = {
  '11x6': {
    width: 11,
    length: 6
  },
  '6x4': {
    width: 6,
    length: 4
  }
};
export const TemplateList = ({
  history
}) => {
  const {
    hasPermission,
    asCompany,
    user,
    hasMultiPermission,
    flags
  } = useContext(UserStateContext);
  const {
    prompt,
    show
  } = useContext(ModalContext);
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [hoverAnchorEl, setHoverAnchorEl] = useState();
  const hoverOpen = Boolean(hoverAnchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [renderIteration, setRenderIteration] = useState(0);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [templateTags, setTemplateTags] = React.useState([]);
  const [folderSelectDialog, setFolderSelectDialog] = React.useState([]);
  const [archiveModal, setArchiveModal] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState([]);
  const [tabButtonHover, setTabButtonHover] = React.useState(null);
  React.useEffect(() => {
    getTemplatesTags(asCompany.id).then(({
      data
    }) => {
      const tags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      setTemplateTags(tags);
    });
  }, [asCompany]);
  const filterOptions = [{
    component: TagFilter,
    api: getTemplatesTags,
    refreshApi: renderIteration,
    key: 'tags'
  }, {
    component: MultiSelectFilter,
    label: 'Template Type',
    key: 'type_in',
    options: [{
      label: 'Email',
      value: 'email',
      icon: <InboxMoveIcon size="sm" />
    }, {
      label: 'Text',
      value: 'sms',
      icon: <SMSIcon />
    }, {
      label: 'Mailer',
      value: 'mail',
      icon: <MailIcon size="sm" />
    }]
  }, {
    component: MultiSelectFilter,
    label: 'Status',
    key: 'status_in',
    options: [{
      label: 'Draft',
      value: 'draft',
      icon: <DraftIcon />
    }, {
      label: 'Published',
      value: 'published',
      icon: <PublishedIcon />
    }]
  }, {
    component: UserFilter,
    label: 'Created By',
    pluralLabel: 'Created By',
    key: 'created_by_id',
    api: getTemplateCreatedByUsers
  }, {
    component: DateRangeFilter,
    label: 'Created Date',
    key: 'created'
  }, {
    key: 'archived',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }];
  const [filters, setFilters] = useState(() => getFiltersFromURL(filterOptions));
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const folderParam = searchParams.get('folder');
  const [currentTab, setCurrentTab] = React.useState(() => {
    if (filters['archived']) {
      return 1;
    } else {
      return 0;
    }
  });
  useEffect(() => {
    if (currentTab == 1) {
      setFilters({
        ...(filters || {}),
        archived: true
      });
    } else {
      const {
        archived: _,
        ...newFilters
      } = filters;
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    }
  }, [currentTab]);
  const updateFolderName = name => {
    updateFolder(name.id, {
      name: name.name
    }).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const addNewFolder = () => {
    prompt({
      title: 'Create New Folder',
      placeholder: 'Folder Name',
      submitText: 'Create'
    }).then(newFolderName => {
      setIsLoading(true);
      createNewFolder({
        name: newFolderName,
        model: 'template',
        parent: folderParam,
        company: asCompany.id
      }).then(() => {
        setRenderIteration(i => i + 1);
        setIsLoading(false);
        setShowCreateFolderModal(false);
      });
    }).catch(() => {});
    setAnchorEl();
  };
  const handleDrop = (id, patchData, originalData) => {
    return getPatchFunction(originalData.type)(id, patchData);
  };
  const handleRowClick = template => {
    if (hasPermission('template.change_template') || template.type == 'sms' && this.context.hasPermission('template.view_template')) {
      const id = template.id;
      if (template.type === 'mail') {
        history.push(template.published ? `/templates/print/${id}` : `/templates/edit/print/${id}`);
      } else if (template.type === 'email') {
        history.push(`/templates/edit/email/${id}`);
      } else if (template.type === 'sms') {
        history.push(`/templates/edit/sms/${id}`);
      }
    } else {
      snackbarService.popup({
        type: 'error',
        message: 'You need the "Manage Templates" permission to view this template'
      });
    }
  };
  const addTagFromSegmentRow = (e, tag) => {
    e.stopPropagation();
    //check if already selected
    if (!filters['tags']?.includes(tag)) {
      setFilters({
        ...(filters || {}),
        ['tags']: [...(filters['tags'] || []), tag]
      });
    }
  };
  const headCells = [{
    key: 'name',
    label: 'Name',
    width: '48%',
    displayComponent: ({
      data,
      isNested
    }) => <Stack direction="row" spacing={1}>
          {isNested ? <>
              <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
                <Icon name="custom-folder_open" />{' '}
              </Box>
              <Typography sx={{
          display: 'flex',
          alignItems: 'center'
        }}> {data.path + data.name}</Typography>
            </> : <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.name}</Typography>}
          <TagsMui tags={data.tags} onClick={(e, tag) => {
        addTagFromSegmentRow(e, tag);
      }} />
        </Stack>
  }, {
    key: 'published',
    label: 'Status',
    width: '10%',
    displayComponent: ({
      data
    }) => !data.published ? <Tooltip title="Draft">
            <span>
              <DraftIcon />
            </span>
          </Tooltip> : <Tooltip title="Published">
            <span>
              <PublishedIcon />
            </span>
          </Tooltip>
  }, {
    key: 'type',
    label: 'Type',
    width: '10%',
    displayComponent: ({
      data
    }) => {
      if (data.type === 'email') return <Tooltip title="Email">
              <span>
                <InboxMoveIcon size="sm" />
              </span>
            </Tooltip>;
      if (data.type === 'mail') return <Tooltip title="Mailer">
              <span>
                <MailIcon size="sm" />
              </span>
            </Tooltip>;
      return <Tooltip title="Text">
            <span>
              <SMSIcon />
            </span>
          </Tooltip>;
    }
  }, {
    key: 'updated_when',
    label: 'Updated',
    width: '10%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <Tooltip title={data.updated_by || 'Unknown'}>
          <span>{moment(data.updated_when).format('MMM D, YYYY')}</span>
        </Tooltip>
  }, {
    key: 'created_by',
    label: 'Created By',
    width: '12%',
    displayComponent: ({
      data
    }) => data.created_by
  }];
  const handleNewFormDialogContinue = template => {
    setShowCreateModal(false);
    if (template.type == 'email') {
      goToEmailTemplate(template);
    }
    if (template.type == 'sms') {
      goToSMS(template);
    }
    if (template.type == 'mail') {
      goToPrints(template);
    }
  };
  const goToEmailTemplate = template => {
    if (template.id !== '') {
      createEmailFromTemplate(template.id, asCompany.id).then(({
        data
      }) => {
        history.push(`/templates/edit/email/${data.id}`);
      });
    } else {
      postEmail({
        name: template.name || 'Untitled',
        company: asCompany.id
      }).then(({
        data
      }) => {
        history.push({
          pathname: `/templates/edit/email/${data.id}`,
          isFromCreate: true
        });
      });
    }
  };
  const goToSMS = template => {
    if (template.id !== '') {
      createSmsFromTemplate(template.id, asCompany.id).then(({
        data
      }) => {
        history.push(`/templates/edit/sms/${data.id}`);
      });
    } else {
      postSms({
        name: template.name,
        company: asCompany.id
      }).then(({
        data
      }) => {
        history.push({
          pathname: `/templates/edit/sms/${data.id}`,
          isFromCreate: true
        });
      });
    }
  };
  const goToPrints = template => {
    if (template.id !== '') {
      createPrintFromTemplate(template.id, asCompany.id).then(({
        data
      }) => {
        history.push(`/templates/edit/print/${data.id}`);
      });
    } else {
      postPrints({
        name: template.name,
        company: asCompany.id,
        substrate_width_inches: templateSizes[template.mailTemplateSize].width,
        substrate_length_inches: templateSizes[template.mailTemplateSize].length
      }).then(({
        data
      }) => {
        history.push(`/templates/edit/print/${data.id}`);
      });
    }
  };
  function onClose() {
    setFolderSelectDialog([]);
  }
  function onSubmitData(destinationId, destinationName) {
    const promises = [];
    folderSelectDialog.filter(f => f._type === 'folder').map(s => {
      promises.push(updateFolder(s.id, {
        parent: destinationId === 'home' ? null : destinationId
      }));
    });
    folderSelectDialog.filter(f => f._type === 'rowItem').map(s => {
      promises.push(getPatchFunction(s.type)(s.id, {
        folder: destinationId === 'home' ? null : destinationId
      }));
    });
    Promise.all(promises).then(() => {
      snackbarService.popup({
        type: 'success',
        message: folderSelectDialog?.length > 1 ? `Moved  ${folderSelectDialog.length} items to ${destinationName}` : `Moved ${folderSelectDialog[0]?.name} to ${destinationName}`
      });
      setRenderIteration(i => i + 1);
      setFolderSelectDialog([]);
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'There was an error moving the item(s).'
      });
    });
  }
  const closeArchiveModal = () => {
    setArchiveModal([]);
  };
  const openArchiveModal = templates => {
    const promises = [];
    templates.map(template => {
      if (template.type === 'email' || template.type === 'mail') {
        promises.push(getJourneys({
          company: asCompany.id,
          node_template: template.id,
          archived: false
        }));
      }
      if (template.type === 'email') {
        promises.push(getBroadcasts({
          company: asCompany.id,
          email_template: template.id,
          broadcast_when: false
        }));
      }
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newTemplates = templates.map(template => {
        const tem = {
          ...template
        };
        if (template.type == 'email') {
          tem.journeys = data[index].data.results;
          index += 1;
          tem.emailBroadcasts = data[index].data.results;
          index += 1;
        } else if (template.type == 'mail') {
          tem.journeys = data[index].data.results;
          index += 1;
        }
        return tem;
      });
      setArchiveModal(newTemplates);
    });
  };
  const hasMixedItemTypes = selected => {
    const uniqueValues = new Set(selected.map(v => v?._type));
    if (uniqueValues.size > 1) return true;
    return false;
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {/* For now don't allow duplicating folders */}
        {hasPermission('template.change_template') && data.selected?.length > 0 && data.selected.filter(s => s._type === 'folder').length === 0 && <Tooltip title="Duplicate">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          handleDuplicateTemplate(data.selected);
        }}>
                <DuplicateIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('template.change_template') && data.selected?.length > 0 && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Move to folder">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          setFolderSelectDialog(data.selected);
        }}>
                <AddFolderIcon />
              </IconButton>
            </Tooltip>}
        {hasPermission('template.change_template') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Archive">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          openArchiveModal(data.selected);
        }}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('template.change_template') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !!s.archived_when).length > 0 && <Tooltip title="Restore">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          restoreTemplate(data.selected);
        }}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>}

        {hasPermission('template.delete_template') && data.selected?.length > 0 && <Tooltip title="Delete">
            <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          e.stopPropagation();
          openDeleteModal(data.selected);
        }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
      </Stack>
  };
  const canArchive = () => {
    return !archiveModal.some(template => template.journeys?.length > 0 || template.emailBroadcasts?.length > 0);
  };
  const archiveTemplate = () => {
    const promises = [];
    archiveModal.map(template => {
      promises.push(getPatchFunction(template.type)(template.id, {
        archived_when: DateTime.local()
      }));
    });
    Promise.all(promises).then(data => {
      setRenderIteration(i => i + 1);
      closeArchiveModal();
    });
  };
  const getIcon = type => {
    if (type == 'email') {
      return <InboxMoveIcon size="sm" />;
    }
    if (type == 'mail') {
      return <MailIcon size="sm" />;
    }
    if (type == 'sms') {
      return <SMSIcon />;
    }
  };
  const TemplatesApiWrapper = () => {
    return params => getTemplates({
      archived: false,
      ...params
    });
  };
  const restoreTemplate = templates => {
    const promises = [];
    templates.map(template => {
      promises.push(getPatchFunction(template.type)(template.id, {
        archived_when: null
      }));
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
    });
  };
  const openDeleteModal = templates => {
    const promises = [];
    templates.filter(f => f._type === 'rowItem').map(template => {
      if (template.type === 'email' || template.type === 'mail') {
        promises.push(getJourneys({
          company: asCompany.id,
          node_template: template.id,
          archived: false
        }));
      }
      if (template.type === 'email') {
        promises.push(getBroadcasts({
          company: asCompany.id,
          email_template: template.id,
          broadcast_when: false
        }));
      }
    });
    Promise.all(promises).then(data => {
      let index = 0;
      const newTemplates = templates.filter(f => f._type === 'rowItem').map(template => {
        const tem = {
          ...template
        };
        if (template.type == 'email') {
          tem.journeys = data[index].data.results;
          index += 1;
          tem.emailBroadcasts = data[index].data.results;
          index += 1;
        } else if (template.type == 'mail') {
          tem.journeys = data[index].data.results;
          index += 1;
        }
        return tem;
      });
      const templateFolders = templates.filter(f => f._type === 'folder').map(s => {
        return s;
      });
      setDeleteModal([...newTemplates, ...templateFolders]);
    });
  };
  const canDelete = () => {
    return !deleteModal.filter(f => f._type === 'rowItem').some(template => template.journeys?.length > 0 || template.emailBroadcasts?.length > 0);
  };
  const closeDeleteModal = () => {
    setDeleteModal([]);
  };
  const deleteTemplates = () => {
    const promises = [];
    deleteModal.filter(f => f._type === 'folder').map(s => {
      promises.push(deleteFolder(s.id));
    });
    deleteModal.filter(f => f._type === 'rowItem').map(s => {
      if (s.type == 'email') {
        promises.push(deleteEmail(s.id));
      }
      if (s.type === 'mail') {
        promises.push(deleteMail(s.id));
      }
      if (s.type === 'sms') {
        promises.push(deleteSms(s.id));
      }
    });
    Promise.all(promises).then(() => {
      setRenderIteration(i => i + 1);
      closeDeleteModal([]);
    });
  };
  const handleDuplicateTemplate = templates => {
    let promise;
    if (hasMultiPermission('template.change_email')) {
      promise = show(MultiCompanyPrompt, {
        title: 'Duplicate Template',
        permission: 'template.change_email'
      });
    } else {
      promise = Promise.resolve();
    }
    promise.then(companyIds => {
      const promises = [];
      // Loop over each selected template and make a call to duplicate
      templates.map(template => {
        if (template.type === 'mail') {
          promises.push(duplicateMail(template.id, companyIds));
        }
        if (template.type === 'email') {
          promises.push(duplicateEmail(template.id, companyIds));
        }
        if (template.type === 'sms') {
          promises.push(duplicateSms(template.id, companyIds));
        }
      });
      Promise.all(promises).then(data => {
        // If multiple duplicated or duplicated to other companies, refresh data, else go to the new one
        if (templates.length > 1 || !!companyIds) {
          setRenderIteration(i => i + 1);
        } else {
          const temp = data[0].data;
          temp.type = templates[0].type;
          handleRowClick(temp);
        }
        let message = templates.length > 1 ? 'Templates successfully duplicated.' : 'Template successfully duplicated.';
        snackbarService.popup({
          type: 'success',
          message: `${message}${companyIds ? ' Duplicated to other companies.' : ''}`
        });
      }).catch(() => {
        let message = templates.length > 1 ? 'There was an error trying to duplicate the templates.' : 'There was an error trying to duplicate the template.';
        snackbarService.popup({
          type: 'error',
          message: `${message}`
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <Scoped css={styles}>
        <div className="wrapper">
          <PageHeaderMui type={currentTab == 1 ? 'Templates - Archived' : 'Templates'} icon={<TemplatesIcon size="lg" />} folderBreadcrumbs updateName={updateFolderName} updateNamePermission={hasPermission('template.change_template') && folderParam} />

          <Stack sx={{
          px: '20px',
          pt: '20px'
        }} spacing="15px" direction={'row'}>
            <Box className="box-button" sx={{
            backgroundColor: currentTab == 0 ? '#3898D9' : tabButtonHover == 0 ? '#C2E7FF' : '#F3F6FC'
          }} onMouseEnter={() => setTabButtonHover(0)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
            setCurrentTab(0);
          }}>
              <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

              <Typography sx={{
              pl: '10px',
              color: currentTab == 0 ? '#ffffff' : '#1D252D'
            }}>
                Active
              </Typography>
            </Box>
            <Box className="box-button" sx={{
            backgroundColor: currentTab == 1 ? '#3898D9' : tabButtonHover == 1 ? '#C2E7FF' : '#F3F6FC'
          }} onMouseEnter={() => setTabButtonHover(1)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
            setCurrentTab(1);
          }}>
              <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />

              <Typography sx={{
              pl: '10px',
              color: currentTab == 1 ? '#ffffff' : '#1D252D'
            }}>
                Archived
              </Typography>
            </Box>
          </Stack>

          <div className="mui-wrapper">
            <EnhancedTable type={currentTab == 0 ? 'Templates' : 'Templates - Archived'} headCells={headCells} api={TemplatesApiWrapper()} enableCheckboxes defaultSort={'-updated_when'} folderModel="template" folderApi={handleDrop} handleRowClick={handleRowClick} handleRowHover={(el, row) => {
            setHoverAnchorEl(el);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} renderIteration={renderIteration} actionButtons={actionButtons} actions={currentTab == 0 && hasPermission('template.add_template') && <NewButton onClick={e => setAnchorEl(e.currentTarget)} />} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('template.change_template') && <Tooltip title="Duplicate">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleDuplicateTemplate([data]);
              }}>
                        <DuplicateIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('template.change_template') && <Tooltip title="Move to folder">
                      <IconButton onClick={e => {
                e.stopPropagation();
                setFolderSelectDialog([data]);
              }}>
                        <MoveToFolderIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('template.change_template') && <Tooltip title="Add tags">
                      <IconButton onClick={() => setShowTagModal(data)}>
                        <AddTagsIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('template.change_template') && !data.archived_when && <Tooltip title="Archive">
                      <IconButton onClick={e => {
                e.stopPropagation();
                openArchiveModal([data]);
              }}>
                        <ArchivedIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('template.change_template') && !!data.archived_when && <Tooltip title="Restore">
                      <IconButton onClick={e => {
                e.stopPropagation();
                restoreTemplate([data]);
              }}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('template.delete_template') && <Tooltip title="Delete">
                      <IconButton onClick={e => {
                e.stopPropagation();
                openDeleteModal([data]);
              }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>

          <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl()}>
            {(user.is_superuser || flags.cinch_testers) && <MenuItem onClick={e => {
            history.push('/prebuilt/install/email');
            setAnchorEl();
          }}>
                <HandymanIcon />
                Prebuilt Templates
              </MenuItem>}

            <MenuItem onClick={() => {
            setShowCreateModal(true);
            setAnchorEl();
          }}>
              <AddItemIcon /> {user.is_superuser || flags.cinch_testers ? 'New Custom Template' : 'New Template'}
            </MenuItem>
            <MenuItem onClick={addNewFolder}>
              <AddFolderIcon /> New Folder
            </MenuItem>
          </Menu>

          <NewTemplateDialog open={showCreateModal} onClose={() => setShowCreateModal(false)} onContinue={handleNewFormDialogContinue} />

          <TagModal onClose={() => setShowTagModal(false)} onSuccess={() => {
          setRenderIteration(i => i + 1);
          setShowTagModal(false);
        }} objectTarget={showTagModal} getViewTags={getTemplatesTags} patchView={showTagModal ? getPatchFunction(showTagModal.type) : null} viewName="Templates" />

          {folderSelectDialog.length > 0 && <FolderSelectDialog data={folderSelectDialog} onClose={onClose} onSubmit={onSubmitData} folderModel="template"></FolderSelectDialog>}

          <NewModal title={canArchive() ? 'Archive Template(s)' : 'Cannot Archive Template(s)'} onClose={closeArchiveModal} open={archiveModal.length > 0} allowBackdropClick>
            <div className="modal__body">
              {canArchive() ? <>
                  <p>
                    Are you sure you would like to archive the following template(s)? They will no longer be available to use in journeys or
                    broadcasts
                  </p>
                  {archiveModal.map(template => <Stack direction="row" spacing="8px" key={template.id}>
                      <> {getIcon(template.type)}</>{' '}
                      <Typography sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>{template.name} </Typography>
                    </Stack>)}
                </> : archiveModal.filter(s => s.journeys?.length > 0 || s.emailBroadcasts?.length > 0).map(template => <>
                      <p>
                        The template{' '}
                        <strong>
                          {' '}
                          {getIcon(template.type)} {template.name}{' '}
                        </strong>{' '}
                        cannot be archived because it is used in the following places.
                      </p>
                      {!!template.journeys?.length && <>
                          <h4>Journeys</h4>
                          <Box sx={{
                  px: 2
                }}>
                            {template.journeys.map((j, index) => {
                    return <Button key={index} onClick={e => {
                      closeArchiveModal();
                      history.push(`/customer-journeys/${j.id}`);
                    }}>
                                  {j.name}
                                </Button>;
                  })}
                          </Box>{' '}
                        </>}

                      {!!template.emailBroadcasts?.length && <>
                          <h4>Email Broadcasts</h4>
                          <Box sx={{
                  px: 2
                }}>
                            {template.emailBroadcasts.map((j, index) => {
                    return <Button key={index} onClick={e => {
                      closeArchiveModal();
                      history.push({
                        pathname: `/email-broadcasts`,
                        viewExistingId: j.id
                      });
                    }}>
                                  {j.email_template_name}
                                </Button>;
                  })}
                          </Box>{' '}
                        </>}
                    </>)}
            </div>
            <div className="modal__actions">
              {canArchive() ? <>
                  <Button actionType="flat" onClick={closeArchiveModal}>
                    Cancel
                  </Button>
                  <Button actionType="primary" onClick={() => archiveTemplate()}>
                    Confirm
                  </Button>
                </> : <Button actionType="primary" onClick={closeArchiveModal}>
                  Okay
                </Button>}
            </div>
          </NewModal>

          <NewModal title={canDelete() ? 'Delete item(s)' : 'Cannot Delete Item(s)'} onClose={closeDeleteModal} open={deleteModal.length > 0} allowBackdropClick>
            <div className="modal__body">
              {canDelete() ? <>
                  <p>Are you sure you would like to delete the following items?</p>
                  {deleteModal.some(i => i._type == 'folder') && <Stack sx={{
                p: 1
              }}>
                      <Typography component="span">Folders:</Typography>
                      <em component="span">
                        If these folders have any templates in them, they will not be deleted, but will be moved back to the home folder.
                      </em>
                      {deleteModal.filter(s => s._type == 'folder').map(folder => <Box component="span" key={folder.id} sx={{
                  py: 1,
                  pl: 2
                }}>
                            z{folder.name}{' '}
                          </Box>)}
                    </Stack>}

                  {deleteModal.some(i => i._type == 'rowItem') && <Stack sx={{
                p: 1
              }}>
                      <Typography component="span">Templates:</Typography>
                      {deleteModal.filter(s => s._type == 'rowItem').map(template => <Box sx={{
                  py: 1,
                  pl: 2
                }} component="span" key={template.id}>
                            {getIcon(template.type)}
                            {template.name}{' '}
                          </Box>)}
                    </Stack>}
                </> : deleteModal.filter(s => s._type == 'rowItem').filter(s => s.journeys?.length > 0 || s.emailBroadcasts?.length > 0).map(template => <>
                      <p>
                        The template{' '}
                        <strong>
                          {' '}
                          {getIcon(template.type)} {template.name}{' '}
                        </strong>{' '}
                        cannot be deleted because it is used in the following places.
                      </p>
                      {!!template.journeys?.length && <>
                          <h4>Journeys</h4>
                          <Box sx={{
                  px: 2
                }}>
                            {template.journeys?.map((j, index) => {
                    return <Button key={index} onClick={e => {
                      closeDeleteModal();
                      history.push(`/customer-journeys/${j.id}`);
                    }}>
                                  {j.name}
                                </Button>;
                  })}
                          </Box>{' '}
                        </>}

                      {!!template.emailBroadcasts?.length && <>
                          <h4>Email Broadcasts</h4>
                          <Box sx={{
                  px: 2
                }}>
                            {template.emailBroadcasts.map((j, index) => {
                    return <Button key={index} onClick={e => {
                      closeDeleteModal();
                      history.push({
                        pathname: `/email-broadcasts`,
                        viewExistingId: j.id
                      });
                    }}>
                                  {j.email_template_name}
                                </Button>;
                  })}
                          </Box>{' '}
                        </>}
                    </>)}
            </div>
            <div className="modal__actions">
              {canDelete() ? <>
                  <Button actionType="flat" onClick={closeDeleteModal}>
                    Cancel
                  </Button>
                  <Button actionType="primary" onClick={() => deleteTemplates()}>
                    Confirm
                  </Button>
                </> : <Button actionType="primary" onClick={closeDeleteModal}>
                  Okay
                </Button>}
            </div>
          </NewModal>
        </div>
      </Scoped>
    </>;
};