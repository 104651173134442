import React, { useContext, useState, useEffect } from 'react';
import { Scoped } from 'kremling';
import moment from 'moment';
import { api, unwrap } from '../../shared/api';
import styles from './customers.styles.scss';
import { Loader } from '../../components/loader/loader.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import { Icon, PageHeaderMui } from 'components';
import { getCustomersTotals, getLocations } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography, DialogActions, Button, Modal } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CustomerDetailIcon, CustomerIcon, InboxIcon, InboxMoveIcon, LocationOnIcon, PhoneIcon, WarningRedIcon, InformationIcon } from 'components/mui';
import utils from 'shared/utils';
import { LocationTooltip } from 'components/mui/tooltip/location-tooltip.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { gridClasses } from '@mui/x-data-grid-pro';
export const Customers = () => {
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const {
    asCompany,
    companyFields,
    isCatalystUser,
    userLocations
  } = useContext(UserStateContext);
  const [renderIteration, setRenderIteration] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [paginationDataCount, setPaginationDataCount] = useState(0);
  const [locations, setLocations] = React.useState([]);
  const [stats, setStats] = useState({});
  const [toolbarExpanded, setToolbarExpanded] = useState(false);
  const [search, setSearch] = React.useState([]);
  const [tempFields, setTempFields] = useState([]);
  const [rows, setRows] = useState([]);
  const [updatedFields, setUpdatedFields] = useState(tempFields);
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const [showMaxFieldWarning, setShowMaxFieldWarning] = useState(false);
  React.useEffect(() => {
    setLoading(true);
    getLocations({
      company: asCompany.id,
      limit: 1000,
      user_locations: isCatalystUser,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setLocations(data.results);
      }
    });
    const customerFieldsStr = localStorage.getItem('customerFields');
    if (customerFieldsStr) {
      const f = JSON.parse(customerFieldsStr);
      // TODO: remove from location list, customer list, segment customer list pages
      f.forEach(field => {
        if (field.name === 'Adress') {
          field.name = 'Address';
        }
      });
      setFields(f);
      loadHeaders(f);
    } else {
      const f = [{
        id: 'name',
        name: 'Name',
        type: 'text',
        width: '20%',
        sortKey: 'first_name'
      }, {
        id: 'email',
        name: 'Email',
        type: 'text'
      }, {
        id: 'phone',
        name: 'Phone',
        type: 'phone'
      }, {
        id: 'stat_last_location__name',
        name: 'Last Location Name',
        type: 'location'
      }, {
        id: 'last_transaction_when',
        name: 'Last Transaction Date',
        type: 'date'
      }];
      setFields(f);
      loadHeaders(f);
    }
    const fields = companyFields.find(i => i.id === 'customer')?.fields;
    setAllFields(fields);
    setLoading(false);
  }, [asCompany.id, isCatalystUser]);
  useEffect(() => {
    if (!isCatalystUser) {
      return;
    }
    setRenderIteration(r => r + 1);
  }, [userLocations, isCatalystUser]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Location',
    key: 'location',
    options: locations.map(location => ({
      label: location.friendly_name || 'Unnamed Location',
      value: location.id
    }))
  }, {
    component: DateRangeFilter,
    label: 'Last Transaction Date',
    key: 'last_transaction_when'
  }];
  const [filters, setFilters] = React.useState(() => {
    return getFiltersFromURL(filterOptions);
  });
  React.useEffect(() => {
    getCustomersTotals({
      company: asCompany.id,
      location: !!isCatalystUser ? userLocations : null,
      ...filters,
      search
    }).then(({
      data
    }) => {
      if (data) {
        setStats(data);
      }
    });
  }, [asCompany.id, filters, search]);
  const getCellDisplayComponent = (data, index, onHover, isNested, selected, field, i) => {
    if (field.id == 'name') {
      return data.first_name || data.last_name ? (data.first_name || '(No first name)') + ' ' + (data.last_name || '(No last name)') : <Typography>(No name) </Typography>;
    } else if (field.type == 'date') {
      return data[field.id] ? moment(data[field.id]).format('MMM D, YYYY') : <Typography key={field.id}> --</Typography>;
    } else if (field.type == 'datetime') {
      return data[field.id] ? moment(data[field.id]).format('MMM D, YYYY h:mm A') : <Typography key={field.id}> --</Typography>;
    } else if (field.type == 'phone') {
      return data[field.id] ? utils.formatPhoneNumber(data[field.id]) : <Typography key={field.id}> --</Typography>;
    } else if (field.id.startsWith('stat_last_location')) {
      return data[field.id] ? <LocationTooltip key={i} fieldId={field.id} location={data.full_location} /> : <Typography key={field.id}> --</Typography>;
    } else if (data['entity_data'] && field.id?.startsWith('entity_data')) {
      const key = field.id.split('__')[1];
      return data['entity_data'][key] || data['entity_data'][field.id] || <Typography key={field.id}> --</Typography>;
    } else return data[field.id] || <Typography key={field.id}> --</Typography>;
  };
  const loadHeaders = fields => {
    const cells = [];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      cells.push({
        key: field.id,
        align: 'left',
        disablePadding: true,
        label: field.name,
        width: field.width || '12%',
        sortKey: field.sortKey || null,
        displayComponent: ({
          data,
          index,
          onHover,
          isNested,
          selected
        }) => <>{[getCellDisplayComponent(data, index, onHover, isNested, selected, field, i)]}</>
      });
    }
    setHeadCells(cells);
  };
  const handleRowClick = row => {
    openCustomerDrawer(row.id);
  };
  const commas = x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  const editFields = () => {
    if (fields.length >= 6) {
      setShowMaxFieldWarning(true);
    } else {
      setShowMaxFieldWarning(false);
    }
    setEditFieldsOpen(true);
    setTempFields(JSON.parse(JSON.stringify(fields)));
  };
  const addTemp = id => {
    const newTempFields = JSON.parse(JSON.stringify(tempFields));
    if (id && newTempFields.length < 6 && !newTempFields.find(i => i.id === id)) {
      newTempFields.push(allFields.find(i => i.id === id));
      setTempFields(newTempFields);
      setUpdatedFields(newTempFields);
    }
    if (newTempFields.length >= 6) {
      setShowMaxFieldWarning(true);
    }
  };
  const removeTemp = id => {
    const newTempFields = JSON.parse(JSON.stringify(tempFields));
    if (id) {
      const index = newTempFields.findIndex(i => i.id === id);
      if (index > -1) {
        newTempFields.splice(index, 1);
        setTempFields(newTempFields);
        setUpdatedFields(newTempFields);
      }
    }
    if (newTempFields.length <= 5) {
      setShowMaxFieldWarning(false);
    }
  };
  const saveFields = () => {
    const newTempFields = tempFields;
    localStorage.setItem('customerFields', JSON.stringify(newTempFields));
    setFields(newTempFields);
    loadHeaders(newTempFields);
    handleEditFieldsClose();
  };
  function getCustomers(params) {
    if (isCatalystUser && !params.location) {
      params.location = userLocations;
    }
    return api.get('/customers', {
      params
    }).then(data => {
      const unwrappedResults = [];
      data.data.results.map(customer => {
        if (customer.full_location) {
          customer.stat_last_location__name = customer.full_location.name;
          customer.stat_last_location_id = customer.full_location.id;
          customer.stat_last_location__entity_ref = customer.full_location.entity_ref;
        }
        unwrappedResults.push(customer);
      });
      data.data.results = unwrappedResults;
      return data;
    });
  }
  useEffect(() => {
    const filteredRows = allFields.filter(field => tempFields && !tempFields.find(temp => temp.id === field.id)).map(field => ({
      id: field.id,
      name: field.name
    }));
    setRows(filteredRows);
  }, [allFields, tempFields]);
  const handleRowOrderChange = params => {
    const paramsArray = Object.values(params);
    array_move(paramsArray, params.oldIndex, params.targetIndex);
    const updatedFields = Array.from(tempFields);
    const [movedField] = updatedFields.splice(params.oldIndex, 1);
    updatedFields.splice(params.targetIndex, 0, movedField);
    setUpdatedFields(updatedFields);
    setTempFields(updatedFields);
  };
  const handleEditFieldsClose = () => {
    setTempFields(updatedFields);
    setEditFieldsOpen(false);
  };
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '16px',
              fontWeight: 'bold'
            },
            '& .MuiDataGrid-cell': {
              fontSize: '14px',
              paddingLeft: '8px'
            },
            '& .MuiDataGrid-cell--draggable': {
              padding: '0px'
            },
            '& .MuiDataGrid-columnHeader': {
              textAlign: 'left'
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {},
            // make it not clickable
            '& .MuiDataGrid-columnHeader--clickable': {
              pointerEvents: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                marginTop: '20px',
                marginBottom: '20px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }
          }
        }
      }
    }
  });
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.slice(new_index, 0, arr.slice(old_index, 1)[0]);
    return arr; // for testing
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        {loading && <Loader overlay background="rgba(255,255,255,.5)" />}
        <PageHeaderMui
      //type={`Customers ${paginationDataCount ? `(${commas(paginationDataCount)})` : ''}`}
      type={`Customers`} icon={<Icon name="custom-group" size={34} />} />
        <div className="mui-wrapper">
          <Stack sx={{
          pt: 3
        }}>
            <Box sx={{
            px: 3
          }}>
              <Paper sx={{
              height: toolbarExpanded ? '345px' : '65px'
            }}>
                <Stack sx={{
                height: '100%',
                py: '10px'
              }} className="d-flex justify-content-between align-items-center w-100" direction="row">
                  <Stack sx={{
                  pl: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} spacing="20px" direction="row">
                    <Typography fontSize="20px">Customer Data:</Typography>
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <CustomerIcon />
                      <Typography variant="tableHeader"> Total Customers:</Typography>
                      <Typography> {` ${paginationDataCount ? `${commas(paginationDataCount)}` : ''}`}</Typography>
                    </Stack>
                    <Divider orientation="vertical" sx={{
                    height: '24px'
                  }} />
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <InboxMoveIcon size="sm" />
                      <Typography variant="tableHeader"> Email Addresses:</Typography>
                      <Typography> {stats.email_count ? commas(stats.email_count) : ''} </Typography>
                    </Stack>
                    <Divider orientation="vertical" sx={{
                    height: '24px'
                  }} />
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <LocationOnIcon />
                      <Typography variant="tableHeader"> Street Addresses:</Typography>
                      <Typography> {stats.address_count ? commas(stats.address_count) : ''} </Typography>
                    </Stack>
                    <Stack spacing="10px" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row">
                      <PhoneIcon />
                      <Typography variant="tableHeader"> Phone Numbers:</Typography>
                      <Typography> {stats.phone_count ? commas(stats.phone_count) : ''} </Typography>
                    </Stack>
                  </Stack>
                  {/* hiding this for now unless we need the toolbar again in the future */}
                  {/* <IconButton
                    onClick={(e) => {
                      setToolbarExpanded(!toolbarExpanded);
                    }}
                   >
                    {toolbarExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                   </IconButton> */}
                </Stack>
              </Paper>
            </Box>

            <EnhancedTable type={'Customers'} headCells={headCells} api={getCustomers} filterOptions={filterOptions} filtersState={[filters, setFilters]} searchCount={count => setPaginationDataCount(count)} getSearch={value => setSearch(value)} handleRowClick={row => {
            handleRowClick(row);
          }} renderIteration={renderIteration} middleActions={<Tooltip title="Edit Fields">
                  <IconButton sx={{
              width: '40px',
              height: '40px',
              textAlign: 'center',
              padding: 0,
              backgroundColor: '#fff',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={editFields}>
                    <Icon fill="#1D252D" name="custom-view_week" />
                  </IconButton>
                </Tooltip>} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  <Tooltip title="Customer Details">
                    <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleRowClick(data);
              }}>
                      <CustomerDetailIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>} />
          </Stack>
        </div>
      </div>

      <Modal open={editFieldsOpen} onClose={() => setTempFields(null)} hideBackdrop={true}>
        <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '520px',
        width: '560px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: '24px',
        borderRadius: '28px',
        borderBottom: '1px solid #E2E2E2'
      }}>
          <Box>
            <Typography sx={{
            fontSize: '24px',
            fontWeight: '400',
            fontStyle: 'normal',
            pt: '0px'
          }}>
              Edit Fields
            </Typography>
          </Box>
          <Tooltip title="Click on a field to add or remove it.">
            <Box sx={{
            position: 'absolute',
            top: '22px',
            left: '150px'
          }}>
              <InformationIcon height="20px" width="20px" />
            </Box>
          </Tooltip>
          <Divider sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          py: '12px'
        }} />
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '367px',
          width: '244px',
          pt: '24px',
          pb: '24px'
        }}>
            <Box sx={{
            pr: '20px',
            pt: '80px'
          }}>
              <ThemeProvider theme={theme}>
                {showMaxFieldWarning ? <Box sx={{
                position: 'absolute',
                top: '86px',
                left: '24px',
                height: '355px',
                width: '244px',
                backgroundColor: '#1D252DCC',
                borderRadius: '14px',
                zIndex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                    <Stack sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%'
                }}>
                      <WarningRedIcon fill="#FFF" sx={{
                    pb: '20px'
                  }} />
                      <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#FFF',
                    textalign: 'center',
                    lineHeight: '16.8px',
                    width: '168px',
                    pt: '20px'
                  }}>
                        Remove at least one item from "Displayed fields" before adding a new one.{' '}
                      </Typography>
                    </Stack>
                  </Box> : null}
                <DataGridPro sx={{
                height: '355px',
                width: '244px',
                borderRadius: '14px',
                border: '1px solid #BEBEBE',
                cursor: 'pointer',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                  padding: '0px'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                  padding: '0px',
                  color: '#1C1B1F'
                },
                '.MuiDataGrid-menuIconButton': {
                  visibility: 'visible',
                  position: 'absolute',
                  top: '15px',
                  left: '172px',
                  width: '20px',
                  height: '20px',
                  color: '#1C1B1F'
                },
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  borderTop: 'none'
                },
                '.MuiDataGrid-columnHeaderTitle': {
                  pl: '22px'
                },
                [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                  outline: 'transparent'
                },
                [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none'
                },
                '& .MuiDataGrid-cellContent': {
                  position: 'absolute',
                  left: '29px',
                  justifyContent: 'flex-start'
                }
              }} rows={rows} columns={[{
                headerAlign: 'left',
                field: 'name',
                headerName: 'Available Fields',
                fontStyle: 'normal',
                fontFamily: 'Nexa Text',
                fontWeight: '700',
                width: 250,
                pl: '19px',
                baseTooltipProps: {
                  placement: 'top'
                },
                renderCell: params => <Tooltip title={params.value.length > 27 ? params.value : ''}>
                          <div>
                            <Typography className="cellContent" sx={{
                      pl: '24px'
                    }} variant="bold">
                              {params.value.length > 27 ? <>{('' + params.value).slice(0, 27) + '...'}</> : <>{params.value}</>}
                            </Typography>
                          </div>
                        </Tooltip>
              }, {
                // the id column is hidden but is necessary to store the id of each row
                field: 'id',
                hide: true
              }]} onFilterModelChange={params => {
                setTempFields(params.rows);
              }} onRowClick={params => {
                addTemp(params.row.id);
              }} loading={loading} rowHeight={32} headerHeight={49} columnHeaderHeight={49} showColumnRightBorder disableColumnFilter disableColumnSelector disableColumnResize disableColumnPinning hideFooter MuiDataGrid-columnHeadersInner--scrollable slotsProps={{
                headerSlotClassName: 'MuiDataGrid-columnHeadersInner--scrollable',
                columnHeaderHeight: {
                  height: '49px'
                }
              }} />
              </ThemeProvider>
            </Box>
            <Box sx={{
            pt: '80px'
          }}>
              <ThemeProvider theme={theme}>
                <DataGridPro sx={{
                height: '355px',
                width: '244px',
                borderRadius: '14px',
                border: '1px solid #BEBEBE',
                cursor: 'pointer',
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible'
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                  position: 'absolute',
                  left: '7px'
                },
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  borderTop: 'none'
                },
                '.MuiDataGrid-columnHeader': {
                  borderBottom: '1px solid #BEBEBE'
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                  visibility: 'hidden',
                  color: 'transparent'
                },
                [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                  outline: 'transparent'
                },
                [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  position: 'absolute',
                  right: '115px'
                },
                '& .MuiDataGrid-cellContent': {
                  position: 'absolute',
                  left: '29px',
                  justifyContent: 'flex-start'
                }
              }} rows={tempFields} columns={[{
                headerAlign: 'left',
                field: 'name',
                headerName: 'Displayed Fields (6 max)',
                width: 300,
                position: 'absolute',
                sortable: false,
                renderCell: params => <Tooltip title={params.value.length > 27 ? params.value : ''}>
                          <div>
                            <Typography className="cellContent" sx={{
                      pl: '24px'
                    }} variant="bold">
                              {params.value.length > 27 ? <>{('' + params.value).slice(0, 27) + '...'}</> : <>{params.value}</>}
                            </Typography>
                          </div>
                        </Tooltip>
              }]} onRowOrderChange={handleRowOrderChange} onRowClick={params => {
                removeTemp(params.row.id);
              }} loading={loading} rowReordering rowHeight={32} headerHeight={49} disableColumnMenu disableColumnResize hideFooter slotsProps={{
                headerSlotClassName: 'MuiDataGrid-columnHeadersInner--scrollable'
              }} />
              </ThemeProvider>
            </Box>
          </Box>
          <Divider sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          top: '436px',
          pb: '28px'
        }} />
          <DialogActions sx={{
          pt: '70px',
          pb: '10px'
        }}>
            <Button variant="text" sx={{
            width: '75px',
            borderRadius: '28px',
            mr: 1,
            textTransform: 'none'
          }} onClick={() => handleEditFieldsClose()}>
              Cancel
            </Button>
            <Button onClick={() => {
            saveFields();
          }} variant="text" sx={{
            width: '75px',
            borderRadius: '28px',
            mr: 1,
            textTransform: 'none'
          }}>
              Save
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </Scoped>;
};