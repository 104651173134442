import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import moment from 'moment';
import { LicenseInfo } from '@mui/x-license';
import { Typography, Button, Box, Popper, Paper, Stack, ClickAwayListener } from '@mui/material';
import { Calendar24HoursIcon, Calendar30DaysIcon, Calendar365DaysIcon, Calendar7DaysIcon, CalendarAllIcon, CalendarDateRangeIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, RightCaretIcon } from '../icon';
export default function DatePicker(props) {
  const {
    after,
    before,
    filters,
    handleFilterChange
  } = props;
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = React.useState(null);
  const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState(false);
  const [dateRangeCalendarPending, setDateRangeCalendarPending] = React.useState([]);
  const [datePickerSelection, setDatePickerSelection] = React.useState('all');
  const [datePickerMenuOpen, setDatePickerMenuOpen] = React.useState(false);
  const [datePickerMenuAnchorEl, setDatePickerMenuAnchorEl] = React.useState(null);
  const [dateRangeCalendar, setDateRangeCalendar] = React.useState(() => {
    if (before) {
      return [moment(after), moment(before)];
    }
    return [];
  });
  useEffect(() => {
    if (!after && !before) {
      setDatePickerSelection('all');
    } else {
      const days = moment().diff(moment(after), 'days');
      if (days < 5) {
        setDatePickerSelection('24hours');
      } else if (days < 25) {
        setDatePickerSelection('7days');
      } else if (days < 40) {
        setDatePickerSelection('30days');
      } else if (days < 100) {
        setDatePickerSelection('90days');
      } else if (days < 200) {
        setDatePickerSelection('180days');
      } else if (days < 300) {
        setDatePickerSelection('270days');
      } else if (days < 400) {
        setDatePickerSelection('365days');
      }
    }
  }, [after, before]);
  const datePickerTypes = props.types || [{
    key: 'all',
    name: 'All',
    icon: <CalendarAllIcon />
  }, {
    key: '24hours',
    name: 'Last 24 Hours',
    icon: <Calendar24HoursIcon />
  }, {
    key: '7days',
    name: 'Last 7 Days',
    icon: <Calendar7DaysIcon />
  }, {
    key: '30days',
    name: 'Last 30 Days',
    icon: <Calendar30DaysIcon />
  }, {
    key: '365days',
    name: 'Last year',
    icon: <Calendar365DaysIcon />
  }];
  const handleDatePickerClose = () => {
    if (!dateRangeMenuOpen) {
      setDatePickerMenuOpen(false);
      setDatePickerMenuAnchorEl(null);
    }
  };
  const handleDatePickerClick = (e, date) => {
    setDatePickerMenuOpen(true);
    setDatePickerMenuAnchorEl(e.currentTarget);
  };
  const handleDatePickerMenuClick = key => {
    setDateRangeCalendar([]);
    //setDatePickerSelection(key);
    const new_filters = {
      ...filters
    };
    delete new_filters['date_after'];
    delete new_filters['date_before'];
    if (key == '24hours') {
      new_filters['date_after'] = moment().subtract(24, 'hours').startOf('day').toISOString();
    }
    if (key == '7days') {
      new_filters['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
    }
    if (key == '30days') {
      new_filters['date_after'] = moment().subtract(30, 'days').startOf('day').toISOString();
    }
    if (key == '90days') {
      new_filters['date_after'] = moment().subtract(90, 'days').startOf('day').toISOString();
    }
    if (key == '180days') {
      new_filters['date_after'] = moment().subtract(180, 'days').startOf('day').toISOString();
    }
    if (key == '270days') {
      new_filters['date_after'] = moment().subtract(270, 'days').startOf('day').toISOString();
    }
    if (key == '365days') {
      new_filters['date_after'] = moment().subtract(365, 'days').startOf('day').toISOString();
    }
    handleFilterChange(new_filters);
    setDatePickerMenuOpen(false);
    setDatePickerMenuAnchorEl(null);
    setDateRangeAnchorEl(null);
    setDateRangeMenuOpen(false);
  };
  const handleDateRangeClose = () => {
    setDateRangeAnchorEl(null);
    setDateRangeMenuOpen(false);
  };
  const handleDatePickerCalendarSubmit = () => {
    handleDateRangeClose();
    setDatePickerMenuOpen(false);
    setDatePickerMenuAnchorEl(null);
    setDateRangeCalendar(dateRangeCalendarPending);
    const new_filters = {
      ...filters
    };
    // when the user clicks confirm with no date range set
    if (dateRangeCalendarPending.length < 1) {
      delete new_filters['date_before'];
      delete new_filters['date_after'];
      handleFilterChange(new_filters);
      setDatePickerMenuOpen(false);
      setDateRangeCalendarPending([]);
      // set back to whatever the user selected last
      setDatePickerSelection(datePickerSelection);
      return;
    }
    // if the user selects a second date for the range, otherwise, use the first date
    if (dateRangeCalendarPending[1] !== null) {
      new_filters['date_after'] = dateRangeCalendarPending[0].toISOString();
      // set to 11:59PM (end of day)
      new_filters['date_before'] = dateRangeCalendarPending[1].endOf('day').subtract(1, 'second').toISOString();
      //new_filters['date_before'] = dateRangeCalendarPending[1].toISOString();
    } else {
      new_filters['date_after'] = dateRangeCalendarPending[0].startOf('day').toISOString();
      new_filters['date_before'] = dateRangeCalendarPending[0].endOf('day').toISOString();
    }
    handleFilterChange(new_filters);
    setDatePickerMenuOpen(false);
    setDateRangeCalendarPending([]);
  };
  LicenseInfo.setLicenseKey(MUIX_API);
  return <>
      <Box sx={{
      background: props.background || 'inherit',
      borderRadius: '16px'
    }}>
        <Popper anchorEl={datePickerMenuAnchorEl} open={datePickerMenuOpen} sx={{
        zIndex: 1500,
        width: '312px'
      }} onClose={handleDatePickerClose} placement="bottom">
          <ClickAwayListener onClickAway={handleDatePickerClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
          }}>
              <Box sx={{
              p: '12px'
            }} className="d-flex h-100 justify-content-between">
                <Typography variant="tableHeader">Date</Typography>
                <Box sx={{
                cursor: 'pointer'
              }} onClick={e => {
                setDatePickerMenuOpen(false);
                setDatePickerMenuAnchorEl(null);
                setDateRangeAnchorEl(null);
                setDateRangeMenuOpen(false);
              }}>
                  <CloseIcon />
                </Box>
              </Box>
              {datePickerTypes.map(filter => <Stack direction="row" sx={{
              cursor: 'pointer',
              p: '12px',
              display: 'flex',
              alignItems: 'center',
              ':hover': {
                backgroundColor: '#F3F3F4'
              }
            }} onMouseEnter={handleDateRangeClose} key={filter.key} spacing="12px" onClick={e => {
              handleDatePickerMenuClick(filter.key);
            }}>
                  <Box> {filter.icon}</Box>
                  <Typography> {filter.name}</Typography>
                </Stack>)}
              <Box sx={{
              pr: '12px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              ':hover': {
                backgroundColor: '#F3F3F4'
              }
            }} onMouseEnter={e => {
              setDateRangeAnchorEl(e.currentTarget);
              setDateRangeMenuOpen(true);
            }} className="d-flex h-100 justify-content-between">
                <Stack direction="row" spacing="12px" sx={{
                p: '12px',
                display: 'flex',
                alignItems: 'center'
              }}>
                  <CalendarDateRangeIcon />
                  <Typography>Date Range </Typography>
                </Stack>
                <RightCaretIcon />
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
        <Box className="d-flex h-100 justify-content-between" onClick={e => {
        handleDatePickerClick(e, datePickerTypes.find(d => d.key === datePickerSelection));
      }} sx={{
        borderRadius: '8px',
        py: '6px',
        px: '4px',
        cursor: 'pointer',
        border: '1px solid #E3E5E7',
        display: 'flex',
        alignItems: 'center',
        width: '222px'
      }}>
          <Stack spacing="8px" sx={{
          display: 'flex',
          alignItems: 'center'
        }} direction="row">
            {dateRangeCalendar.length > 0 && <CalendarDateRangeIcon />}
            {dateRangeCalendar.length > 0 && <Typography>
                {dateRangeCalendar[0] && dateRangeCalendar[1] ? dateRangeCalendar[0]?.format('MM/DD/YY') + ' to ' + dateRangeCalendar[1]?.format('MM/DD/YY') : dateRangeCalendar[0]?.format('MM/DD/YY') + ' to ' + dateRangeCalendar[0]?.format('MM/DD/YY')}
              </Typography>}
            {dateRangeCalendar.length == 0 && <Box> {datePickerTypes.find(d => d.key === datePickerSelection)?.icon}</Box>}
            {dateRangeCalendar.length == 0 && <Typography> {datePickerTypes.find(d => d.key === datePickerSelection)?.name}</Typography>}
          </Stack>
          {datePickerMenuOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}
        </Box>
        {props?.label && <Box sx={{
        width: '65px',
        position: 'relative',
        top: '-65px',
        left: '10px',
        zIndex: 1,
        background: props.background || 'inherit'
      }}>
            <Typography sx={{
          fontSize: '11px',
          color: datePickerMenuOpen ? '#53A6D6' : '#1D252D'
        }}> {props?.label} </Typography>
          </Box>}
        <Popper anchorEl={dateRangeAnchorEl} sx={{
        zIndex: 1500
      }} open={dateRangeMenuOpen} onClose={handleDateRangeClose} placement="left">
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{
            after: 'after date',
            before: 'before date'
          }} sx={{
            padding: '10px'
          }}>
              <DateRangeCalendar value={dateRangeCalendarPending} onChange={newValue => {
              setDateRangeCalendarPending(newValue);
            }} />
            </LocalizationProvider>
            <Stack sx={{
            pb: '12px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse'
          }} direction="row">
              <Button onClick={() => {
              handleDatePickerCalendarSubmit();
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Confirm </Typography>
              </Button>
              <Button onClick={() => {
              handleDateRangeClose();
            }}>
                <Typography sx={{
                textTransform: 'none'
              }}> Cancel </Typography>
              </Button>
            </Stack>
          </Paper>
        </Popper>
      </Box>
    </>;
}