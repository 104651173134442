import React, { useContext, useEffect } from 'react';
import { PageHeaderMui, TagModal } from 'components';
import { getPrebuiltJourneys, getPrebuiltJourneysTags, getNAICS, getBrands, getAccounts, getPrebuiltJourneyCreatedByUsers, patchPrebuiltJourney, archivePrebuiltJourney } from '../../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../../components/mui/enhanced-table/enhanced-table.component';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { Icon } from '../../../components/icon/icon.component';
import { history } from '../../../root.component';
import TagsMui from 'components/tags/tagsMui.component';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { Modal as NewModal } from '../../../components/modal/modal.component';
import { TagFilter } from 'components/mui/filter/tags.component';
import { UserFilter } from 'components/mui/filter/user.component';
import { AddTagsIcon, ArchiveIcon, DraftIcon, EditSquareIcon, HandymanIcon, InformationIcon, NewVersionIcon, PublishedIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { PrebuiltAdminListNavigationTabs } from '../pbj-admin-list-navigationTabs.component';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
import { getIntegrations } from '../../integrations/integrations.resource';
export const PrebuiltAdminJourneysList = () => {
  const {
    asCompany,
    hasPermission,
    user
  } = useContext(UserStateContext);
  const [renderIteration, setRenderIteration] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tagModal, setTagModal] = React.useState(null);
  const [archiveModal, setArchiveModal] = React.useState([]);
  const [naics, setNaics] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const [integrations, setIntegrations] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  useEffect(() => {
    const promises = [getNAICS({
      limit: 999,
      ordering: 'title'
    }), getBrands({
      limit: 999,
      ordering: 'name'
    }), getAccounts({
      limit: 999,
      ordering: 'name'
    }), getIntegrations({
      limit: 999,
      ordering: 'name'
    })];
    Promise.all(promises).then(([naics, brands, accounts, integrations]) => {
      setNaics(naics.data.results);
      setBrands(brands.data.results);
      setAccounts(accounts.data.results);
      setIntegrations(integrations.results);
      setCompanies(user.companies);
      setIsLoading(false);
    });
  }, []);
  const filterOptions = [{
    component: TagFilter,
    api: getPrebuiltJourneysTags,
    refreshApi: renderIteration,
    key: 'tags'
  }, {
    component: UserFilter,
    label: 'Created By',
    pluralLabel: 'Created By',
    key: 'created_by_id',
    api: getPrebuiltJourneyCreatedByUsers
  }, {
    component: MultiSelectFilter,
    label: 'Status',
    key: 'status_in',
    options: [{
      label: 'Released',
      value: 'released',
      icon: <Icon name="custom-how_to_reg" />
    }, {
      label: 'Unreleased',
      value: 'draft',
      icon: <Icon name="custom-directions_car" />
    }]
  }, {
    component: MultiSelectFilter,
    label: 'Industry',
    pluralLabel: 'Industries',
    key: 'industry',
    options: naics.map(i => ({
      label: i.title,
      value: i.id
    }))
  }, {
    component: MultiSelectFilter,
    label: 'Brand',
    key: 'brand',
    options: brands.map(i => ({
      label: i.name,
      value: i.id
    }))
  }, {
    component: MultiSelectFilter,
    label: 'Account',
    key: 'account',
    options: accounts.map(i => ({
      label: i.name,
      value: i.id
    }))
  }, {
    component: MultiSelectFilter,
    label: 'Integration',
    key: 'integration',
    options: integrations.map(i => ({
      label: i.name,
      value: i.key
    }))
  }, {
    component: MultiSelectFilter,
    label: 'Company',
    pluralLabel: 'Companies',
    key: 'company_in',
    options: companies.map(i => ({
      label: i.name,
      value: i.id
    }))
  }, {
    component: DateRangeFilter,
    label: 'Updated Date',
    key: 'updated'
  }, {
    component: ToggleFilter,
    label: 'Archived',
    key: 'archived',
    defaultVal: 'false',
    hidden: {
      hideFilterButton: true,
      hideChip: false
    }
  }];
  const [filters, setFilters] = React.useState(() => getFiltersFromURL(filterOptions));
  const headCells = [{
    key: 'active_version__title',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    width: '28%',
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={1}>
          <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data?.active_version?.title}</Typography>
          <TagsMui tags={data.tags} onClick={(e, tag) => {
        addTagFromRow(e, tag);
      }} />
        </Stack>
  }, {
    key: 'active_version__released_when',
    align: 'left',
    disablePadding: true,
    label: 'Type',
    type: 'string',
    width: '8.3%',
    displayComponent: ({
      data
    }) => <>{getType(data)}</>
  }, {
    key: 'availability',
    align: 'left',
    disablePadding: true,
    noSort: true,
    label: 'Availability',
    type: 'string',
    width: '8.3%',
    displayComponent: ({
      data
    }) => <>{getAvailability(data.active_version)}</>
  }, {
    key: 'install_count',
    label: 'Total Installs',
    width: '10%',
    displayComponent: ({
      data,
      index,
      onHover,
      isNested,
      selected
    }) => <>{data.install_count}</>
  }, {
    key: 'active_version__version',
    label: 'Active Version',
    width: '10%',
    noSort: true,
    displayComponent: ({
      data,
      index,
      onHover,
      isNested,
      selected
    }) => <>{data?.active_version?.version}</>
  }, {
    key: 'updated_when',
    align: 'left',
    disablePadding: true,
    label: 'Updated',
    width: '8.3%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <>{moment(data.updated_when).format('MMM D, YYYY')}</>
  }];
  const handleRowClick = row => {
    if (!!row?.active_version?.released_when) {
      history.push(`/prebuilt/admin/journeys/${row.id}`);
    } else {
      history.push(`/prebuilt/admin/journeys/${row.id}/edit`);
    }
  };
  const handleCreateClick = event => {
    history.push(`/prebuilt/admin/journeys/add-new`);
  };
  const addTagFromRow = (e, tag) => {
    e.stopPropagation();
    //check if already selected
    if (!filters['tags']?.includes(tag)) {
      setFilters({
        ...(filters || {}),
        ['tags']: [...(filters['tags'] || []), tag]
      });
    }
  };
  const hasMixedItemTypes = selected => {
    const uniqueValues = new Set(selected.map(v => v?._type));
    if (uniqueValues.size > 1) return true;
    return false;
  };
  const getType = data => {
    if (data?.active_version?.archived_when) {
      return <Tooltip title="Archived">
          <span>
            <ArchiveIcon />
          </span>
        </Tooltip>;
    }
    if (data?.active_version?.released_when) {
      return <Tooltip title="Released">
          <span>
            <PublishedIcon />
          </span>
        </Tooltip>;
    }
    return <Tooltip title="Unreleased">
        <span>
          <DraftIcon />
        </span>
      </Tooltip>;
  };
  const getAvailability = data => {
    if (!data?.available || !data?.availability) {
      return <Tooltip title="This prebuilt is not available to any company or group. No one can use it">
          <Typography sx={{
          fontWeight: 200,
          fontStyle: 'italic'
        }}>Not Available</Typography>
        </Tooltip>;
    }
    if (Object.keys(data?.availability).length == 0) {
      return <Tooltip title="This prebuilt is available to anyone who can import prebuilts. No restrictions are active">
          <Typography>Available to All</Typography>
        </Tooltip>;
    }
    let totalLength = 0;
    for (let key in data?.availability) {
      if (Array.isArray(data?.availability[key])) {
        totalLength += data?.availability[key].length;
      }
    }
    return <Tooltip title={<Stack spacing="16px">
            {data?.availability?.company && <Stack>
                <span>Companies:</span>
                {data?.availability?.company?.map(company => <span key={company.id}>{company.name}</span>)}
              </Stack>}
            {data?.availability?.account && <Stack>
                <span>Accounts:</span>
                {data?.availability?.account?.map(company => <span key={company.id}>{company.name}</span>)}
              </Stack>}
            {data?.availability?.brand && <Stack>
                <span>Brands:</span>
                {data?.availability?.brand?.map(company => <span key={company.id}>{company.name}</span>)}
              </Stack>}

            {data?.availability?.naics && <Stack>
                <span>Industries:</span>
                {data?.availability?.naics?.map(company => <span key={company.id}>{company.title}</span>)}
              </Stack>}

            {data?.availability?.integration && <Stack>
                <span>Integration:</span>
                {data?.availability?.integration?.map(company => <span key={company.id}>{company.name}</span>)}
              </Stack>}
          </Stack>}>
        <Typography>Available to {totalLength} groups</Typography>
      </Tooltip>;
  };
  const actionButtons = {
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={2}>
        {hasPermission('template.change_template') && data.selected?.length > 0 && !hasMixedItemTypes(data.selected) && data.selected[0]._type != 'folder' && data.selected.filter(s => !s.archived_when).length > 0 && <Tooltip title="Archive">
              <IconButton sx={{
          ':hover': {
            backgroundColor: '#DAEFFF'
          }
        }} onClick={e => {
          setArchiveModal(data.selected);
        }}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>}
      </Stack>
  };
  const closeArchiveModal = () => {
    setArchiveModal([]);
  };
  const archivePrebuiltModel = () => {
    const promises = [];
    archiveModal.map(prebuilt => {
      promises.push(archivePrebuiltJourney(prebuilt.id));
    });
    Promise.all(promises).then(data => {
      setRenderIteration(i => i + 1);
      closeArchiveModal();
    });
  };
  const PrebuiltParentModelApiWrapper = () => {
    return params => getPrebuiltJourneys({
      archived: false,
      ...params
    });
  };
  return <>
      <CssBaseline />
      <>
        <div className="wrapper">
          <PageHeaderMui type={'Prebuilt Admin - Journeys'} icon={<HandymanIcon />} />
          <Box sx={{
          px: '20px',
          pt: '20px',
          width: 'fit-content'
        }}>
            <PrebuiltAdminListNavigationTabs currentTab="journeys" />
          </Box>

          <div className="mui-wrapper">
            <EnhancedTable type={'Prebuilt Journeys'} headCells={headCells} actionButtons={actionButtons} api={PrebuiltParentModelApiWrapper()} enableCheckboxes defaultSort={'-updated_when'} handleRowClick={row => {
            handleRowClick(row);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} parentLoading={isLoading} renderIteration={renderIteration} actions={hasPermission('template.add_template') && <NewButton onClick={e => handleCreateClick(e)} />} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('template.change_template') && !!data?.active_version?.released_when && <Tooltip title="View Install Stats">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleRowClick(data);
              }}>
                        <InformationIcon />
                      </IconButton>
                    </Tooltip>}
                  {hasPermission('template.change_template') && <Tooltip title="Add tags">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setTagModal({
                  ...data,
                  company: 'fakecompanyid'
                });
              }}>
                        <AddTagsIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('template.change_template') && <Tooltip title="Edit">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                history.push(`/prebuilt/admin/journeys/${data.id}/edit`);
              }}>
                        <EditSquareIcon />
                      </IconButton>
                    </Tooltip>}

                  {hasPermission('template.change_template') && <Tooltip title="Make New Version">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                history.push(`/prebuilt/admin/journeys/${data.id}/add-new-version`);
              }}>
                        <NewVersionIcon />
                      </IconButton>
                    </Tooltip>}

                  {!data.archived_when && hasPermission('template.change_template') && <Tooltip title="Archive">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                setArchiveModal([data]);
              }}>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>
        </div>

        <TagModal onClose={() => setTagModal(null)} onSuccess={() => {
        setRenderIteration(i => i + 1);
      }} objectTarget={tagModal} getViewTags={getPrebuiltJourneysTags} patchView={patchPrebuiltJourney} viewName={'Prebuilt Journeys'}></TagModal>

        <NewModal title={'Archive Prebuilt Journey'} onClose={closeArchiveModal} open={archiveModal.length > 0} allowBackdropClick>
          <div className="modal__body">
            <>
              <p>
                Are you sure you would like to archive the following journey(s)? This will hide all published versions of this item from the
                end-user install page. It will not affect versions that have already been installed by an end-user. This action cannot be
                undone.
              </p>
              {archiveModal.map(prebuilt => <div key={prebuilt.id}>{prebuilt?.active_version?.title}</div>)}
            </>
          </div>
          <div className="modal__actions">
            <>
              <Button actionType="flat" onClick={closeArchiveModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => archivePrebuiltModel()}>
                Confirm
              </Button>
            </>
          </div>
        </NewModal>
      </>
    </>;
};