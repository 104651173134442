import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../../../root.component';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip as MuiTooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DraftIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, PausedIcon, PublishedIcon, ShieldPersonIcon, StoppedIcon } from 'components/mui';
import { getJourneyVolumesWidget, getWidgetInstalls } from 'shared/common.api';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import utils from '../../../../shared/utils';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);
export const JourneyVolumesChart100 = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [activeJourneys, setActiveJourneys] = useState([]);
  const [KPIs, setKPIs] = useState([]);
  const componentRef = useRef();
  const [resizeBreakpoint, setResizeBreakpoint] = useState(1600);
  useEffect(() => {
    setLoading(true);
    getJourneyVolumesWidget(asCompany.id).then(response => {
      console.log(response.data, 'getJourneyVolumesWidget');
      //setInstalledWidgets(response.data.results);
      const journeys = response.data.recentActiveJourneys.filter((j, c) => c < 10); //throw away all but the first 10 for now. Need to add pagination/scrolling to this widget later
      journeys.map(journey => {
        journey.journeyName = journey.journeyName.length > 50 ? journey.journeyName.substring(0, 50) + '...' : journey.journeyName;
        journey.backgroundColor = journey?.status === 'Published' ? '#C5EAD7' : journey?.status === 'Draft' ? '#fff' : journey?.status === 'Paused' ? '#FFEABF' : journey?.status === 'Stopped' ? '#F3C2C2' : '#fff';
        journey.borderColor = journey?.status === 'Published' ? '#3EB87B' : journey?.status === 'Draft' ? '#1D252D' : journey?.status === 'Paused' ? '#FFB82B' : journey?.status === 'Stopped' ? '#EF3C34' : '#1D252D';
      });
      setActiveJourneys(journeys);
      setKPIs([{
        id: 'published',
        title: 'Published',
        link: '/customer-journeys?status_in%5B0%5D="published"',
        backgroundColor: '#C5EAD7',
        extraLargeIcon: <PublishedIcon size="xlg" fill="#3EB87B" />,
        largeIcon: <PublishedIcon size="lg" fill="#3EB87B" />,
        icon: <PublishedIcon fill="#3EB87B" />,
        stat: response?.data?.statusCounts?.published
      }, {
        id: 'drafts',
        title: 'Drafts',
        link: '/customer-journeys?status_in%5B0%5D="draft"',
        backgroundColor: '#C2E7FF',
        extraLargeIcon: <DraftIcon size="xlg" fill="#3898D9" />,
        largeIcon: <DraftIcon size="lg" fill="#3898D9" />,
        icon: <DraftIcon fill="#3898D9" />,
        stat: response?.data?.statusCounts?.draft
      }, {
        id: 'paused',
        title: 'Paused',
        link: '/customer-journeys?status_in%5B0%5D="paused"',
        extraLargeIcon: <PausedIcon size="xlg" fill="#FFB82B" />,
        largeIcon: <PausedIcon size="lg" fill="#FFB82B" />,
        icon: <PausedIcon fill="#FFB82B" />,
        backgroundColor: '#FFEABF',
        stat: response?.data?.statusCounts?.paused
      }, {
        id: 'stopped',
        title: 'Stopped',
        link: '/customer-journeys?status_in%5B0%5D="stopped"',
        extraLargeIcon: <StoppedIcon size="xlg" fill="#EF3C34" />,
        largeIcon: <StoppedIcon size="lg" fill="#EF3C34" />,
        icon: <StoppedIcon fill="#EF3C34" />,
        backgroundColor: '#F3C2C2',
        stat: response?.data?.statusCounts?.stopped
      }]);
      setLoading(false);
      setResizeBreakpoint(componentRef.current.offsetWidth);
    });
  }, []);
  React.useEffect(() => {
    const resizeListener = () => {
      console.log(componentRef?.current?.offsetWidth, 'ref.current.offsetWidth');
      if (componentRef?.current?.offsetWidth) {
        setResizeBreakpoint(componentRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const getGridWidth = () => {
    if (resizeBreakpoint > 1130) {
      return '250px';
    }
    if (resizeBreakpoint > 1000) {
      return '350px';
    }
    if (resizeBreakpoint > 835) {
      return '300px';
    }
    if (resizeBreakpoint > 685) {
      return '250px';
    }
    if (resizeBreakpoint > 465) {
      return '200px';
    }
    if (resizeBreakpoint > 465) {
      return '200px';
    }

    //// all above are large style kpis

    // medium style kpis
    if (resizeBreakpoint > 365) {
      return '150px';
    }

    //smol style kpis
    if (resizeBreakpoint > 265) {
      return '100px';
    }
    return '110px';
  };
  return <>
      {loading ? <>
          <Stack spacing="36px">
            <Stack direction="row" spacing={'16px'}>
              <Box>
                <DefaultIntegrationIcon />
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Skeleton variant="rounded" width={210} height={20} />
              </Box>
            </Stack>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pb: '12px'
        }}>
              <Skeleton variant="rounded" width={270} height={20} />
            </Box>
          </Stack>
        </> : <Stack spacing="16px" sx={{
      width: '100%'
    }}>
          {/*    <Box sx={{ width: '100%', display: 'flex' }}>
            <Typography sx={{ fontWeight: '300', fontSize: '14px', lineHeight: '20px' }}>
              See which journeys are running and which may need your attention
            </Typography>
           </Box> */}
          <Box sx={{
        width: '100%',
        display: 'flex'
      }}>
            <Stack ref={componentRef} spacing="32px" sx={{
          width: '33%'
        }}>
              {/*     <Typography
                sx={{
                  fontWeight: '700',
                  lineHeight: '20px',
                  fontSize: '16px',
                }}
               >
                Status Counts
               </Typography> */}

              {resizeBreakpoint > 266 ? <Grid justifyContent="space-around" sx={{
            px: '8px',
            width: '100%'
          }} container spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3
          }} columns={{
            xs: 4,
            sm: 12,
            md: 12,
            lg: 12
          }}>
                  {KPIs.map(kpi => <Grid key={kpi.id} xs="auto" sm="auto" md="auto" lg="auto">
                      <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: getGridWidth(),
                border: '1px solid #E2E2E2',
                cursor: 'pointer',
                borderRadius: '8px',
                '&:hover': {
                  boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                }
              }} onClick={e => {
                history.push(kpi.link + '&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard');
              }}>
                        <Stack sx={{
                  width: '100%',
                  height: '100%',
                  py: resizeBreakpoint > 365 ? '30px' : '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} direction={'row'} spacing="14px">
                          {resizeBreakpoint > 365 && <Box sx={{
                    p: '7px',
                    backgroundColor: kpi.backgroundColor,
                    borderRadius: '8px'
                  }}>
                              {resizeBreakpoint > 465 ? kpi.extraLargeIcon : kpi.largeIcon}
                            </Box>}

                          <Stack spacing="12px" sx={{
                    display: 'flex',
                    alignContent: 'center',
                    height: '100%'
                  }}>
                            {resizeBreakpoint > 265 && <Stack direction="row" spacing="8px">
                                {/*   {resizeBreakpoint < 365 && (
                                 <Box sx={{ p: '7px', backgroundColor: kpi.backgroundColor, borderRadius: '4px' }}>{kpi.icon}</Box>
                                 )} */}
                                <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: '12px',
                        justifyContent: resizeBreakpoint > 365 ? 'flex-start' : 'center',
                        width: '100%'
                      }}>
                                  <Typography sx={{
                          fontWeight: '800',
                          lineHeight: '20px',
                          fontSize: resizeBreakpoint > 465 ? '40px' : resizeBreakpoint > 365 ? '30px' : '20px'
                        }}>
                                    {kpi.stat}
                                  </Typography>
                                </Box>
                              </Stack>}
                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                              <Typography sx={{
                        fontWeight: '300',
                        lineHeight: '20px',
                        fontSize: '14px'
                      }}>
                                {kpi.title}
                              </Typography>
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>)}
                </Grid> : <Stack sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: '12px'
          }} spacing="12px">
                  {KPIs.map(kpi => <Stack spacing="12px" key={kpi.id}>
                      <Stack onClick={e => {
                history.push(kpi.link + '&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard');
              }} sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center'
              }} direction="row" spacing="8px">
                        <Typography sx={{
                  fontWeight: '800'
                }}> {kpi.stat} </Typography>
                        <Typography> {kpi.title}</Typography>
                      </Stack>
                      <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                        <Box sx={{
                  width: '100px'
                }}>
                          <Divider />
                        </Box>
                      </Box>
                    </Stack>)}
                </Stack>}
            </Stack>
            <Stack spacing="16px" sx={{
          width: '66%',
          cursor: 'pointer',
          pt: '12px'
        }}>
              {/*  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                  sx={{
                    fontWeight: '700',
                    lineHeight: '20px',
                    fontSize: '16px',
                  }}
                >
                  Active Journeys (Last 7 Days)
                </Typography>
               </Box>*/}
              <Box sx={{
            border: '1px solid #E2E2E2',
            borderRadius: '8px'
          }}>
                <Box sx={{
              p: 1,
              height: '35vh'
            }}>
                  <Bar plugins={[{
                afterDraw: chart => {
                  if (activeJourneys.length > 0) {
                    return;
                  }
                  let ctx = chart.ctx;
                  let width = chart.width;
                  let height = chart.height;

                  //chart.clear();
                  ctx.save();
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  ctx.font = '16px Nexa';
                  ctx.fillText('No Journeys', width / 2, (height - 20) / 2);
                  ctx.restore();
                }
              }]} options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                  bar: {
                    // borderSkipped: false, // Apply setting to all bar datasets
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: false
                    },
                    ticks: {
                      color: '#1D252D',
                      font: {
                        family: 'Nexa'
                      },
                      precision: 0,
                      beginAtZero: true
                    }
                  },
                  y: {
                    ticks: {
                      precision: 0,
                      beginAtZero: true,
                      color: '#1D252D',
                      font: {
                        family: 'Nexa'
                      }
                    },
                    grid: {
                      display: false
                    }
                  }
                },
                plugins: {
                  title: {
                    display: false
                  },
                  legend: {
                    display: resizeBreakpoint > 200 ? true : false,
                    events: [],
                    position: 'bottom',
                    labels: {
                      generateLabels: function (chart) {
                        return [{
                          text: '  ',
                          fillStyle: '#fff',
                          strokeStyle: '#fff',
                          // lineWidth: 2,
                          hidden: false,
                          index: 0,
                          borderRadius: 4
                        }, {
                          text: 'Published',
                          fillStyle: '#C5EAD7',
                          strokeStyle: '#3EB87B',
                          //lineWidth: 2,
                          hidden: false,
                          index: 1,
                          borderRadius: 4
                        }, {
                          text: 'Paused',
                          fillStyle: '#FFEABF',
                          strokeStyle: '#FFB82B',
                          // lineWidth: 2,
                          hidden: false,
                          index: 2,
                          borderRadius: 4
                        }, {
                          text: 'Stopped',
                          fillStyle: '#F3C2C2',
                          strokeStyle: '#EF3C34',
                          // lineWidth: 2,
                          hidden: false,
                          index: 3,
                          borderRadius: 4
                        }];
                      }
                    },
                    font: {
                      family: 'Nexa'
                    }
                  },
                  tooltip: {
                    //intersect: false,
                    // events: [],
                    displayColors: false,
                    callbacks: {
                      label: function (context) {
                        return context?.parsed?.x ? utils.commaize(context.parsed.x) + ' customers triggered in the past 7 days' : '0 customers triggered in the past 7 days';
                      },
                      title: function (context) {
                        return '';
                      }
                    }
                  }
                },
                onClick: function (e, item) {
                  setLoading(true);
                  const journey = activeJourneys[item[0]?.index];
                  if (journey) {
                    history.push(`/customer-journeys/${journey.id}?breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
                  } else {
                    setLoading(false);
                  }
                }
              }} data={{
                labels: activeJourneys?.map(journey => journey.journeyName),
                datasets: [{
                  data: activeJourneys?.map(journey => {
                    return journey?.customersTriggered ? journey.customersTriggered : 0;
                  }),
                  label: 'Published',
                  backgroundColor: activeJourneys.map(journey => journey.backgroundColor),
                  /// barThickness: 16,
                  borderRadius: 10,
                  borderSkipped: 'start',
                  borderColor: activeJourneys.map(journey => journey.borderColor),
                  borderWidth: 2
                }]
              }} />
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>}
    </>;
};