import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../../../root.component';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip as MuiTooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, DraftIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, PausedIcon, PublishedIcon, ShieldPersonIcon, StoppedIcon } from 'components/mui';
import { getJourneyVolumesWidget, getWidgetInstalls } from 'shared/common.api';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import utils from '../../../../shared/utils';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);
export const JourneyVolumesChart50 = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [activeJourneys, setActiveJourneys] = useState([]);
  const [KPIs, setKPIs] = useState([]);
  const componentRef = useRef();
  const [resizeBreakpoint, setResizeBreakpoint] = useState(1600);
  const [maxJourneyCustomersTriggered, setMaxJourneyCustomersTriggered] = useState(1);
  useEffect(() => {
    setLoading(true);
    getJourneyVolumesWidget(asCompany.id).then(response => {
      console.log(response.data, 'getJourneyVolumesWidget');
      //setInstalledWidgets(response.data.results);
      let maxCustomerCount = 0;
      const journeys = response.data.recentActiveJourneys.filter((j, c) => c < 5); //throw away all but the first 10 for now. Need to add pagination/scrolling to this widget later
      journeys.map(journey => {
        journey.journeyName = journey.journeyName.length > 50 ? journey.journeyName.substring(0, 50) + '...' : journey.journeyName;
        journey.backgroundColor = journey?.status === 'Published' ? '#C5EAD7' : journey?.status === 'Draft' ? '#fff' : journey?.status === 'Paused' ? '#FFEABF' : journey?.status === 'Stopped' ? '#F3C2C2' : '#fff';
        journey.borderColor = journey?.status === 'Published' ? '#3EB87B' : journey?.status === 'Draft' ? '#1D252D' : journey?.status === 'Paused' ? '#FFB82B' : journey?.status === 'Stopped' ? '#EF3C34' : '#1D252D';
        maxCustomerCount = Math.max(maxCustomerCount, journey.customersTriggered);
      });
      setMaxJourneyCustomersTriggered(maxCustomerCount);
      setActiveJourneys(journeys);
      setKPIs([{
        id: 'published',
        title: 'Published',
        link: '/customer-journeys?status_in%5B0%5D="published"',
        backgroundColor: '#C5EAD7',
        extraLargeIcon: <PublishedIcon size="xlg" fill="#3EB87B" />,
        largeIcon: <PublishedIcon size="lg" fill="#3EB87B" />,
        icon: <PublishedIcon fill="#3EB87B" />,
        stat: response?.data?.statusCounts?.published
      }, {
        id: 'drafts',
        title: 'Drafts',
        link: '/customer-journeys?status_in%5B0%5D="draft"',
        backgroundColor: '#C2E7FF',
        extraLargeIcon: <DraftIcon size="xlg" fill="#3898D9" />,
        largeIcon: <DraftIcon size="lg" fill="#3898D9" />,
        icon: <DraftIcon fill="#3898D9" />,
        stat: response?.data?.statusCounts?.draft
      }, {
        id: 'paused',
        title: 'Paused',
        link: '/customer-journeys?status_in%5B0%5D="paused"',
        extraLargeIcon: <PausedIcon size="xlg" fill="#FFB82B" />,
        largeIcon: <PausedIcon size="lg" fill="#FFB82B" />,
        icon: <PausedIcon fill="#FFB82B" />,
        backgroundColor: '#FFEABF',
        stat: response?.data?.statusCounts?.paused
      }, {
        id: 'stopped',
        title: 'Stopped',
        link: '/customer-journeys?status_in%5B0%5D="stopped"',
        extraLargeIcon: <StoppedIcon size="xlg" fill="#EF3C34" />,
        largeIcon: <StoppedIcon size="lg" fill="#EF3C34" />,
        icon: <StoppedIcon fill="#EF3C34" />,
        backgroundColor: '#F3C2C2',
        stat: response?.data?.statusCounts?.stopped
      }]);
      setLoading(false);
      setResizeBreakpoint(componentRef.current.offsetWidth);
    });
  }, []);
  React.useEffect(() => {
    const resizeListener = () => {
      console.log(componentRef?.current?.offsetWidth, 'ref.current.offsetWidth');
      if (componentRef?.current?.offsetWidth) {
        setResizeBreakpoint(componentRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const getGridWidth = () => {
    if (resizeBreakpoint > 1130) {
      return '250px';
    }
    if (resizeBreakpoint > 1000) {
      return '350px';
    }
    if (resizeBreakpoint > 835) {
      return '300px';
    }
    if (resizeBreakpoint > 685) {
      return '250px';
    }
    if (resizeBreakpoint > 465) {
      return '200px';
    }
    if (resizeBreakpoint > 465) {
      return '200px';
    }

    //// all above are large style kpis

    // medium style kpis
    if (resizeBreakpoint > 365) {
      return '150px';
    }

    //smol style kpis
    if (resizeBreakpoint > 265) {
      return '100px';
    }
    return '110px';
  };
  const normalize = value => {
    return value * 100 / maxJourneyCustomersTriggered;
  };
  return <>
      {loading ? <>
          <Stack spacing="36px">
            <Stack direction="row" spacing={'16px'}>
              <Box>
                <DefaultIntegrationIcon />
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Skeleton variant="rounded" width={210} height={20} />
              </Box>
            </Stack>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pb: '12px'
        }}>
              <Skeleton variant="rounded" width={270} height={20} />
            </Box>
          </Stack>
        </> : <Stack sx={{
      width: '100%'
    }} direction={'row'}>
          <Box sx={{
        width: '50%'
      }} ref={componentRef}>
            {resizeBreakpoint > 266 ? <Box>
                <Grid justifyContent="space-around" sx={{
            px: '8px',
            width: '100%'
          }} container spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3
          }} columns={{
            xs: 4,
            sm: 12,
            md: 12,
            lg: 12
          }}>
                  {KPIs.map(kpi => <Grid key={kpi.id} xs="auto" sm="auto" md="auto" lg="auto">
                      <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: getGridWidth(),
                border: '1px solid #E2E2E2',
                cursor: 'pointer',
                borderRadius: '8px',
                '&:hover': {
                  boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                }
              }} onClick={e => {
                history.push(kpi.link + '&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard');
              }}>
                        <Stack sx={{
                  width: '100%',
                  height: '100%',
                  py: resizeBreakpoint > 365 ? '30px' : '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} direction={'row'} spacing="14px">
                          {resizeBreakpoint > 365 && <Box sx={{
                    p: '7px',
                    backgroundColor: kpi.backgroundColor,
                    borderRadius: '8px'
                  }}>
                              {resizeBreakpoint > 465 ? kpi.extraLargeIcon : kpi.largeIcon}
                            </Box>}

                          <Stack spacing="12px" sx={{
                    display: 'flex',
                    alignContent: 'center',
                    height: '100%'
                  }}>
                            {resizeBreakpoint > 265 && <Stack direction="row" spacing="8px">
                                {/*   {resizeBreakpoint < 365 && (
                                 <Box sx={{ p: '7px', backgroundColor: kpi.backgroundColor, borderRadius: '4px' }}>{kpi.icon}</Box>
                                 )} */}
                                <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: '12px',
                        justifyContent: resizeBreakpoint > 365 ? 'flex-start' : 'center',
                        width: '100%'
                      }}>
                                  <Typography sx={{
                          fontWeight: '800',
                          lineHeight: '20px',
                          fontSize: resizeBreakpoint > 465 ? '40px' : resizeBreakpoint > 365 ? '30px' : '20px'
                        }}>
                                    {kpi.stat}
                                  </Typography>
                                </Box>
                              </Stack>}
                            <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                              <Typography sx={{
                        fontWeight: '300',
                        lineHeight: '20px',
                        fontSize: '14px'
                      }}>
                                {kpi.title}
                              </Typography>
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>)}
                </Grid>
              </Box> : <Stack sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: '12px'
        }} spacing="12px">
                {KPIs.map(kpi => <Stack spacing="12px" key={kpi.id}>
                    <Stack onClick={e => {
              history.push(kpi.link + '&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard');
            }} sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center'
            }} direction="row" spacing="8px">
                      <Typography sx={{
                fontWeight: '800'
              }}> {kpi.stat} </Typography>
                      <Typography> {kpi.title}</Typography>
                    </Stack>
                    <Box sx={{
              display: 'flex',
              justifyContent: 'center'
            }}>
                      <Box sx={{
                width: '100px'
              }}>
                        <Divider />
                      </Box>
                    </Box>
                  </Stack>)}
              </Stack>}
          </Box>

          <Stack sx={{
        width: '50%'
      }}>
            {activeJourneys.map(journey => <Box key={journey.key} sx={{
          cursor: 'pointer',
          '&:hover': {
            boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)',
            borderRadius: '8px'
          }
        }}>
                <Stack spacing="6px" onClick={e => {
            history.push(`/customer-journeys/${journey.id}?breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
          }} sx={{
            width: '100%',
            display: 'flex',
            p: 1
          }}>
                  <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
                    <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                      <Typography sx={{
                  // fontWeight: '700',
                  // lineHeight: '20px',
                  // fontSize: '18px',
                }}>
                        {journey.journeyName}
                      </Typography>
                    </Box>

                    <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                      <Typography sx={{
                  // fontWeight: '700',
                  //  lineHeight: '20px',
                  // fontSize: '18px',
                }}>
                        {utils.commaize(journey?.customersTriggered || 0)}
                      </Typography>
                    </Box>
                  </Box>
                  <LinearProgress variant="determinate" value={normalize(journey?.customersTriggered || 0)} sx={{
              height: '10px',
              borderRadius: '10px',
              '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: journey?.backgroundColor
              },
              '& .MuiLinearProgress-bar': {
                backgroundColor: journey?.borderColor
              }
            }} />
                </Stack>
              </Box>)}
          </Stack>
        </Stack>}
    </>;
};