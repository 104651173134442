import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, CardContent, CardHeader, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Collapse, Box, FormControlLabel, Icon, Paper, Stack, Modal, Divider, Tooltip, CircularProgress } from '@mui/material';
import { ExpandMore, ExpandLess, Search, Settings } from '@mui/icons-material';
import { Scoped } from 'kremling';
import { PageHeader, PageHeaderMui } from 'components';
import styles from '../data-fields/data-fields.styles.scss';
import { AddIcon, DeleteIcon, DragIndicatorIcon, EditIcon } from 'components/mui';
import { getInstall, getIntegrationVersion, updateIntegrationVersion } from './integrations.resource';
import { UserStateContext } from 'context/user-state-context';
export const IntegrationEditDataFields = props => {
  const [config, setConfig] = useState([]);
  const [expandedEntities, setExpandedEntities] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [editingEntityIndex, setEditingEntityIndex] = useState(null);
  const [editingFieldIndex, setEditingFieldIndex] = useState(null);
  const [isFieldDialogOpen, setIsFieldDialogOpen] = useState({
    open: false,
    new: false
  });
  const [isModelDialogOpen, setIsModelDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredConfig, setFilteredConfig] = useState(config);
  const [deleteWarning, setDeleteWarning] = useState({
    open: false,
    field: null,
    entityIndex: null,
    fieldIndex: null
  });
  const {
    asCompany
  } = useContext(UserStateContext);
  const [isModelVisibile, setIsModelVisible] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [integration, setIntegration] = useState({});
  useEffect(() => {
    let modelVisibility = [];
    let storedModelFields = JSON.parse(localStorage.getItem('modelFields')) || {};
    getInstall(props?.match?.params?.id).then(data => {
      setIntegration(data?.data);
      getIntegrationVersion(data.data.integration.version).then(data => {
        setConfig(data?.data?.fields);
        // check localStorage for model visibility configuration, if nothing, then assume all models are visible
        if (!storedModelFields[data.data.id]) {
          modelVisibility = {
            [data?.data?.id]: data?.data?.fields.map(model => ({
              id: model.id,
              name: model.name,
              isVisible: true
            }))
          };
          localStorage.setItem('modelFields', JSON.stringify({
            ...storedModelFields,
            ...modelVisibility
          }));
        }
        setIsModelVisible(storedModelFields[data?.data?.id] || modelVisibility[data?.data?.id]);
      });
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    const lowercasedSearch = searchTerm.toLowerCase();
    const filtered = config.filter(entity => isModelVisibile.find(model => model.id === entity.id)?.isVisible === true).map(entity => ({
      ...entity,
      fields: entity.fields.filter(field => field.name.toLowerCase().includes(lowercasedSearch) || field.description.toLowerCase().includes(lowercasedSearch))
    })).filter(entity => entity.name.toLowerCase().includes(lowercasedSearch) || entity.fields.length > 0);
    setFilteredConfig(filtered);
    setExpandedEntities(filtered.map(entity => entity.id));
  }, [searchTerm, config, isModelVisibile, deleteWarning]);
  const handleExpand = entityId => {
    setExpandedEntities(prev => prev.includes(entityId) ? prev.filter(id => id !== entityId) : [...prev, entityId]);
  };
  const handleEditField = (entityIndex, fieldIndex) => {
    setEditingField({
      ...config[entityIndex].fields[fieldIndex]
    });
    setEditingEntityIndex(entityIndex);
    setEditingFieldIndex(fieldIndex);
    setIsFieldDialogOpen({
      open: true,
      new: false
    });
  };
  const handleSaveField = () => {
    if (editingField && editingEntityIndex !== null && editingFieldIndex !== null) {
      const newConfig = [...config];
      newConfig[editingEntityIndex].fields[editingFieldIndex] = editingField;
      setConfig(newConfig);
      setIsFieldDialogOpen({
        open: false,
        new: false
      });
      updateIntegrationVersion(integration?.integration?.version, {
        fields: newConfig
      });
    }
  };
  const handleDeleteField = (entityIndex, fieldIndex) => {
    const newConfig = [...config];
    newConfig[entityIndex].fields.splice(fieldIndex, 1);
    setConfig(newConfig);
    updateIntegrationVersion(integration?.integration?.version, {
      fields: newConfig
    });
    setDeleteWarning({
      open: false,
      field: null,
      entityIndex: null,
      fieldIndex: null
    });
  };
  const handleAddField = (entityIndex, addNewField) => {
    const newField = {
      id: '',
      name: '',
      type: 'text',
      indexed: false,
      description: ''
    };
    setEditingField(newField);
    setEditingEntityIndex(entityIndex);
    setEditingFieldIndex(config[entityIndex].fields.length);
    setIsFieldDialogOpen({
      open: true,
      new: addNewField
    });
  };
  const handleFieldChange = (key, value) => {
    if (editingField && key !== 'id') {
      setEditingField({
        ...editingField,
        [key]: value
      });
    }
    if (isFieldDialogOpen.new && key === 'id') {
      setEditingField({
        ...editingField,
        [key]: value.trim().toLowerCase()
      });
    }
  };
  const onDragEnd = result => {
    const {
      source,
      destination
    } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = Number.parseInt(source.droppableId);
    const destinationIndex = Number.parseInt(destination.droppableId);
    if (sourceIndex === destinationIndex) {
      const newFields = Array.from(config[sourceIndex].fields);
      const [reorderedField] = newFields.splice(source.index, 1);
      newFields.splice(destination.index, 0, reorderedField);
      const newConfig = [...config];
      newConfig[sourceIndex] = {
        ...newConfig[sourceIndex],
        fields: newFields
      };
      setConfig(newConfig);
    }
  };
  const handleToggleModelVisibility = modelId => {
    // get the current visibility from localStorage, if nothing, then assume visibility is true
    const storedModelFields = JSON.parse(localStorage.getItem('modelFields')) || {};
    const visibilityStorage = storedModelFields[integration?.integration?.version];
    if (visibilityStorage) {
      const modelVisibility = visibilityStorage.map(model => model.id === modelId ? {
        ...model,
        isVisible: !model.isVisible
      } : model);
      storedModelFields[integration?.integration?.version] = modelVisibility;
      localStorage.setItem('modelFields', JSON.stringify(storedModelFields));
      setIsModelVisible(modelVisibility);
    }
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <>
          <PageHeaderMui type="Integrations and Apps" name={'Data Fields'} namePrefixAction={e => {
          props.history.push(`/integrations/manage/${integration?.id}`);
        }} namePrefix={<>
                {integration?.integration?.name && integration?.integration?.icon && <Stack direction="row" spacing={1} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                    <Box sx={{
              pl: '8px'
            }}>
                      <img src={integration?.integration?.icon} height="40" width="40" alt={integration?.integration?.name} />
                    </Box>
                    <Box>{integration?.integration?.name || ''}</Box>
                  </Stack>}
              </>}
        // icon={<Icon name="custom-call_merge" size={34} />}
        />

          {isLoading ? <>
              <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
          }}>
                <CircularProgress />
              </Box>
            </> : <>
              <Box sx={{
            p: '20px'
          }}>
                <Paper sx={{
              p: 2,
              marginTop: 2,
              marginBottom: 2,
              borderRadius: '24px'
            }}>
                  <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                marginTop: '16px'
              }}>
                    <Box sx={{
                  position: 'relative',
                  width: 250
                }}>
                      <TextField fullWidth variant="outlined" placeholder="Search models and fields..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} InputProps={{
                    startAdornment: <Search sx={{
                      color: 'text.secondary',
                      marginRight: 1
                    }} />
                  }} />
                    </Box>
                    <Button variant="contained" startIcon={<Settings />} onClick={() => setIsModelDialogOpen(true)}>
                      View Models
                    </Button>
                  </Box>
                </Paper>

                <DragDropContext onDragEnd={onDragEnd}>
                  {filteredConfig.map((entity, entityIndex) => <Card key={entity.id} sx={{
                marginBottom: 2,
                borderRadius: '24px'
              }}>
                      <CardHeader title={<Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                            <IconButton onClick={() => handleExpand(entity.id)} size="small">
                              {expandedEntities.includes(entity.id) ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <Typography variant="h6" component="span">
                              {entity.name}{' '}
                              <Typography component="span" color="text.secondary" sx={{
                      fontSize: '1.4rem'
                    }}>
                                ({entity.fields.length} fields)
                              </Typography>
                            </Typography>
                          </Box>} action={<Stack direction={'row'} display={'flex'} sx={{
                  pr: '8px',
                  justifyContent: 'space-between'
                }}>
                            <Box>
                              <Button sx={{
                      borderRadius: '20px',
                      bgcolor: '#F3F3F4',
                      p: '10px 24px 10px 14px',
                      height: '32px',
                      display: 'inline-flex',
                      gap: '6px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textTransform: 'none'
                    }} onClick={e => {
                      handleAddField(entityIndex, true);
                    }}>
                                <AddIcon fill="#3898D9" />
                                <Typography sx={{
                        color: '#3898D9'
                      }}>New field</Typography>
                              </Button>
                            </Box>
                          </Stack>} />
                      <Collapse in={expandedEntities.includes(entity.id)}>
                        <CardContent>
                          <Droppable droppableId={entityIndex.toString()}>
                            {provided => <div {...provided.droppableProps} ref={provided.innerRef}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell padding="checkbox"></TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Type</TableCell>
                                      <TableCell>Indexed</TableCell>
                                      <TableCell>Description</TableCell>
                                      <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {entity.fields.map((field, fieldIndex) => <Draggable key={field.id} draggableId={field.id} index={fieldIndex}>
                                        {(provided, snapshot) => <TableRow ref={provided.innerRef} {...provided.draggableProps} style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging ? 'rgba(0, 0, 0, 0.05)' : 'transparent'
                              }}>
                                            <TableCell padding="checkbox">
                                              <Box {...provided.dragHandleProps}>
                                                <DragIndicatorIcon />
                                              </Box>
                                            </TableCell>
                                            <TableCell>{field.name}</TableCell>
                                            <TableCell>{field.type}</TableCell>
                                            <TableCell>{field.indexed ? 'Yes' : 'No'}</TableCell>
                                            <TableCell sx={{
                                  maxWidth: 300,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}>
                                              {field.description}
                                            </TableCell>
                                            <TableCell align="right">
                                              <Tooltip title="Edit field" placement="top">
                                                <IconButton onClick={() => handleEditField(entityIndex, fieldIndex)}>
                                                  <EditIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Delete field" placement="top">
                                                <IconButton onClick={() => {
                                      setDeleteWarning({
                                        open: true,
                                        field,
                                        entityIndex,
                                        fieldIndex
                                      });
                                    }}>
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>}
                                      </Draggable>)}
                                    {provided.placeholder}
                                  </TableBody>
                                </Table>
                              </div>}
                          </Droppable>
                        </CardContent>
                      </Collapse>
                    </Card>)}
                </DragDropContext>

                <Dialog open={isFieldDialogOpen.open} onClose={() => setIsFieldDialogOpen({
              open: false,
              new: false
            })} sx={{
              '& .MuiPaper-root': {
                borderRadius: '24px'
              }
            }}>
                  <DialogTitle>{isFieldDialogOpen.new ? 'Add Field' : 'Edit Field'}</DialogTitle>
                  <Divider />
                  <DialogContent>
                    <FormControlLabel sx={{
                  marginLeft: '3px'
                }} control={<Checkbox sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }} checked={editingField?.indexed || false} onChange={e => {
                  handleFieldChange('indexed', e.target.checked);
                }} />} label="Indexed" />
                    <TextField margin="dense" label="ID" fullWidth defaultValue={isFieldDialogOpen.new ? '' : editingField?.id} disabled={isFieldDialogOpen.new ? false : true} error={editingField?.id.trim() === ''} helperText={editingField?.id === '' ? 'Field ID is required' : null} onChange={e => handleFieldChange('id', e.target.value)} />
                    <TextField margin="dense" label="Name" fullWidth value={editingField?.name || ''} onChange={e => handleFieldChange('name', e.target.value)} />
                    <TextField margin="dense" label="Type" fullWidth select value={editingField?.type || 'text'} onChange={e => {
                  handleFieldChange('type', e.target.value);
                }} SelectProps={{
                  native: true
                }}>
                      <option value="text">Text</option>
                      <option value="number">Number</option>
                      <option value="boolean">Boolean</option>
                    </TextField>
                    <TextField margin="dense" label="Description" fullWidth multiline rows={4} value={editingField?.description || ''} onChange={e => handleFieldChange('description', e.target.value)} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsFieldDialogOpen({
                  open: false,
                  new: false
                })} variant="text" sx={{
                  textTransform: 'none',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}>
                      Cancel
                    </Button>
                    <Button disabled={!editingField?.id.trim()} onClick={handleSaveField} variant="text" sx={{
                  textTransform: 'none',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={isModelDialogOpen} onClose={() => setIsModelDialogOpen(false)} sx={{
              '& .MuiPaper-root': {
                borderRadius: '24px'
              }
            }}>
                  <DialogTitle>View Models</DialogTitle>
                  <Divider />
                  <DialogContent>
                    <Box sx={{
                  pb: '15px'
                }}>
                      <Typography variant="body1">Select which models you would like to view for your integration.</Typography>
                    </Box>

                    {config.map(model => <FormControlLabel key={model.id} control={<Checkbox checked={isModelVisibile.find(m => m.id === model.id)?.isVisible} sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }} onChange={() => handleToggleModelVisibility(model.id)} />} label={model.name} />)}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsModelDialogOpen(false)} variant="text" sx={{
                  textTransform: 'none',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>

                <Modal id="delete-integration-data-field-modal" open={deleteWarning.open} onClose={() => setDeleteWarning({
              open: false,
              field: null,
              entityIndex: null,
              fieldIndex: null
            })}>
                  <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '250px',
                width: '450px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: '24px',
                borderRadius: '28px',
                borderBottom: '1px solid #E2E2E2',
                zIndex: '1000'
              }}>
                    <Box sx={{
                  p: '0px 8px 20px 14px'
                }}>
                      <Typography sx={{
                    fontSize: '24px',
                    fontWeight: '400',
                    fontStyle: 'normal'
                  }}>
                        Delete Warning
                      </Typography>
                    </Box>

                    <Box sx={{
                  p: 0,
                  marginLeft: '-24px',
                  marginRight: '-24px'
                }}>
                      <Divider />
                    </Box>

                    <Stack sx={{
                  pt: '30px'
                }}>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                        <Typography>
                          {`You have selected `}
                          <strong>{deleteWarning?.field?.name}</strong>
                          {` for deletion. By deleting this field, it will no longer be usable by any segments, journeys, and other features that rely on this data field. Are you sure you want to delete this field?`}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    pt: '30px'
                  }}>
                        <Button variant="text" sx={{
                      textTransform: 'none',
                      borderRadius: '20px',
                      minWidth: '100px'
                    }} onClick={() => {
                      setDeleteWarning({
                        open: false,
                        field: null,
                        entityIndex: null,
                        fieldIndex: null
                      });
                    }}>
                          Cancel
                        </Button>
                      </Box>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    pt: '30px'
                  }}>
                        <Button variant="text" sx={{
                      textTransform: 'none',
                      borderRadius: '20px',
                      minWidth: '100px'
                    }} onClick={() => {
                      handleDeleteField(deleteWarning.entityIndex, deleteWarning.fieldIndex);
                    }}>
                          Confirm
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </Modal>
              </Box>
            </>}
        </>
      </div>
    </Scoped>;
};