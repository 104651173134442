import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, PageHeaderMui, Toggle } from 'components';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CloseIcon, DefaultIntegrationIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import { history } from '../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { createWidgetInstall, getAvailableWidgets, getWidgetInstalls } from 'shared/common.api';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F3F3F4',
      borderRadius: `24px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
export const WidgetsAddNew = () => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [installedWidgets, setInstalledWidgets] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredAvailableWidgets, setFilteredAvailableWidgets] = useState([]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    const promises = [getWidgetInstalls({
      company: asCompany.id,
      ordering: 'order',
      ...filters
    }), getAvailableWidgets({
      company: asCompany.id,
      ...filters
    })];
    Promise.all(promises).then(([installs, available]) => {
      console.log(installs, 'installs');
      console.log(available, 'available');
      //setFilteredIntegrations(response);
      setInstalledWidgets(installs.data.results);
      setFilteredAvailableWidgets(available.data.results.filter(i => {
        return installs.data.results.findIndex(ii => ii.widget === i.widget) === -1;
      }));
      setLoading(false);
    });
  }, [hasPermission, asCompany?.id, filters]);
  useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        search: search
      });
      setSelected([]);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const onSubmit = () => {
    const promises = [];
    selected.forEach((widget, index) => {
      promises.push(createWidgetInstall({
        widget: widget.widget,
        company: asCompany.id,
        order: installedWidgets.length + index + 1
      }));
    });
    Promise.all(promises).then(response => {
      console.log(response, 'response');
      snackbarService.popup({
        type: 'success',
        message: 'Widgets added successfully'
      });
      history.push('/dashboard');
    });
  };
  const formatWidth = width => {
    if (width == '100%') {
      return <Typography sx={{
        color: '#3898D9'
      }}>Large </Typography>;
    }
    if (width == '50%') {
      return <Typography sx={{
        color: '#3898D9'
      }}>Medium </Typography>;
    }
    if (width == '33%') {
      return <Typography sx={{
        color: '#3898D9'
      }}>Small </Typography>;
    }
    return width;
  };
  return <>
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Dashboards" icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        px: '20px',
        pt: '20px',
        //makes the footer act a little weird
        pb: '20px'
      }}>
          <Paper sx={{
          borderRadius: '14px'
        }}>
            <Stack>
              <Stack>
                <Stack spacing="20px" sx={{
                backgroundColor: '#fff',
                width: '100%',
                position: 'sticky',
                top: 0,
                py: '10px',
                px: '20px',
                borderRadius: '14px'
              }}>
                  <Stack spacing={'10px'}>
                    <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                      <Typography sx={{
                      fontSize: '20px',
                      lineHeight: '26px'
                    }}>Add Widget </Typography>
                      <Box>
                        <IconButton onClick={e => {
                        history.push('/dashboard');
                      }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>
                  <Box>
                    <CustomTextField variant="outlined" sx={{
                    width: '500px'
                  }} placeholder="Search" autoComplete="new-password" InputProps={{
                    startAdornment: <InputAdornment position="start">
                            <Icon name="custom-search" />
                          </InputAdornment>,
                    endAdornment: search != '' && <InputAdornment onClick={e => {
                      setSearch('');
                    }} position="start" sx={{
                      cursor: 'pointer'
                    }}>
                            <CloseIcon />
                          </InputAdornment>
                  }} value={search || ''} onChange={e => {
                    setSearch(e.target.value);
                  }} />
                  </Box>
                  <Stack direction="row">
                    <Box>
                      {selected.length > 0 ? <Stack sx={{
                      backgroundColor: '#C2E7FF',
                      borderRadius: '20px'
                    }} direction="row" spacing="4px">
                          <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pl: '16px',
                        py: '9px'
                      }}>
                            <Typography sx={{
                          color: '#3898D9',
                          fontSize: '14px',
                          fontWeight: '700',
                          lineHeight: '18px'
                        }}>
                              {selected.length} selected
                            </Typography>
                          </Box>
                          <IconButton onClick={e => {
                        setSelected([]);
                      }}>
                            <CloseIcon size="sm" fill="#3898D9" />
                          </IconButton>
                        </Stack> : <>
                          {/* {!!filters?.type ? (
                            <Stack sx={{ backgroundColor: '#C2E7FF', borderRadius: '20px' }} direction="row" spacing="4px">
                              <Box sx={{ display: 'flex', alignItems: 'center', pl: '16px', py: '9px' }}>
                                <Typography
                                  sx={{
                                    color: '#3898D9',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {filters?.type}
                                </Typography>
                              </Box>
                              <IconButton
                                onClick={(e) => {
                                  setFilters({ ...filters, type: '', integration__type: '' });
                                }}
                              >
                                <CloseIcon size="sm" fill="#3898D9" />
                              </IconButton>
                            </Stack>
                           ) : (
                            <FormControl size="small" sx={{ width: '150px' }}>
                              <InputLabel id="integration-type-label">Type</InputLabel>
                              <Select
                                sx={{ borderRadius: '24px' }}
                                id="integration-type"
                                labelId="integration-type-label"
                                input={<OutlinedInput label="Type" />}
                                label="type"
                                value={filters?.type || ''}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    type: e.target.value,
                                    integration__type: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem value={'data'}>Data</MenuItem>
                                <MenuItem value={'feature'}>Feature</MenuItem>
                                <MenuItem value={'product'}>Product</MenuItem>
                              </Select>
                            </FormControl>
                           )} */}
                        </>}
                    </Box>
                  </Stack>
                  <Divider />
                </Stack>
                <Stack spacing={'20px'}>
                  <Box sx={{
                  px: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '16.8px'
                  }}> Installed </Typography>
                  </Box>
                  {loading ? <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                            <Box sx={{
                      p: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      backgroundColor: '#fff'
                    }}>
                              <Stack direction="row" spacing={'16px'}>
                                <Box>
                                  <DefaultIntegrationIcon />
                                </Box>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Skeleton variant="rounded" width={210} height={20} />
                                </Box>
                              </Stack>
                            </Box>
                          </Grid>)}
                    </Grid> : <Grid sx={{
                  maxWidth: '85vw',
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {installedWidgets.length > 0 ? <>
                          {installedWidgets.map(widget => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={widget?.key}>
                              <Box key={widget?.key} sx={{
                        p: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid #E8EAED',
                        borderRadius: '8px',
                        width: '315px',
                        height: '122px',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F3F3F4',
                          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                        }
                      }} onClick={e => {
                        history.push('/dashboard');
                      }}>
                                <Stack spacing={'16px'}>
                                  <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                      <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '20px'
                              }}>
                                        {widget?.widget_detail?.name}
                                      </Typography>
                                    </Box>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                      <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '20px'
                              }}>
                                        {formatWidth(widget?.widget_detail?.width)}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Tooltip title={widget?.widget_detail?.description}>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              pl: 1
                            }}>
                                      <Typography sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                fontSize: '14px',
                                fontWeight: '200',
                                lineHeight: '20px'
                              }}>
                                        {widget?.widget_detail?.description}
                                      </Typography>
                                    </Box>
                                  </Tooltip>
                                </Stack>
                              </Box>
                            </Grid>)}
                        </> : <>
                          {Object.keys(filters).length != 0 ? <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pl: '24px'
                    }}>
                              <Typography>No search results. Try adjusting your search or filters.</Typography>
                            </Box> : <Box sx={{
                      px: '10px',
                      width: '100%'
                    }}>
                              <Box sx={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '14px',
                        width: '100%'
                      }}>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: '32px',
                          justifyContent: 'center'
                        }}>
                                  <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '300px',
                            lineHeight: '17px'
                          }}>
                                    You have not installed any widgets yet
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>}
                        </>}
                    </Grid>}
                  <Box sx={{
                  px: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '16.8px'
                  }}> Available </Typography>
                  </Box>
                  {loading ? <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                            <Box sx={{
                      p: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      backgroundColor: '#fff'
                    }}>
                              <Stack direction="row" spacing={'16px'}>
                                <Box>
                                  <DefaultIntegrationIcon />
                                </Box>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Skeleton variant="rounded" width={210} height={20} />
                                </Box>
                              </Stack>
                            </Box>
                          </Grid>)}
                    </Grid> : <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {filteredAvailableWidgets.length > 0 ? <>
                          {filteredAvailableWidgets.map((widget, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index + widget?.widget}>
                              <Box onClick={e => {
                        if (hasPermission('widgets.add_widgetinstall')) {
                          if (selected.find(i => i.widget === widget.widget)) {
                            setSelected(selected.filter(i => i.widget !== widget.widget));
                          } else {
                            setSelected([...selected, widget]);
                          }
                        } else {
                          snackbarService.popup({
                            type: 'error',
                            message: 'You do not have permission to add widgets'
                          });
                        }
                      }} sx={{
                        p: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: selected.find(i => i.widget === widget.widget) ? '2px solid #3898D9' : '1px solid #E8EAED',
                        borderRadius: '8px',
                        width: '315px',
                        cursor: 'pointer',
                        backgroundColor: selected.find(i => i.widget === widget.widget) ? '#F3F3F4' : '#fff',
                        '&:hover': {
                          backgroundColor: '#F3F3F4',
                          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                        }
                      }}>
                                <Stack spacing={'16px'}>
                                  <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                      <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '20px'
                              }}>
                                        {widget?.widget_detail.name}
                                      </Typography>
                                    </Box>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                                      <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '20px'
                              }}>
                                        {formatWidth(widget?.widget_detail?.width)}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Tooltip title={widget?.widget_detail?.description}>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              pl: 1
                            }}>
                                      <Typography sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                fontSize: '14px',
                                fontWeight: '200',
                                lineHeight: '20px'
                              }}>
                                        {widget?.widget_detail?.description}
                                      </Typography>
                                    </Box>
                                  </Tooltip>
                                </Stack>
                              </Box>
                            </Grid>)}
                        </> : <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '24px'
                  }}>
                          <Typography> No results</Typography>
                        </Box>}
                    </Grid>}
                </Stack>
              </Stack>
              <Stack spacing="24px" sx={{
              backgroundColor: '#fff',
              width: '100%',
              position: 'sticky',
              bottom: 0,
              pt: 1,
              px: 2,
              pb: 2,
              borderRadius: '14px'
            }}>
                <Divider />
                <Box sx={{
                display: 'flex',
                justifyContent: 'right'
              }}>
                  <Stack spacing="16px" direction={'row'}>
                    <Button sx={{
                    backgroundColor: '#F3F3F4',
                    '&:hover': {
                      backgroundColor: '#F3F3F4'
                    }
                  }} onClick={e => {
                    history.push('/dashboard');
                  }} variant="contained">
                      <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                        <Typography sx={{
                        color: '#3898D9'
                      }}>Cancel </Typography>
                      </Box>
                    </Button>
                    <Button onClick={e => {
                    onSubmit();
                  }} disabled={selected?.length == 0} variant="contained" color="primary" sx={{
                    backgroundColor: '#3898D9',
                    '&:hover': {
                      backgroundColor: '#3898D9'
                    }
                  }}>
                      <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                        <Typography sx={{
                        color: '#fff'
                      }}> Add selected</Typography>
                      </Box>
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </div>
    </>;
};