import React, { useState, useRef, useEffect } from 'react';
import { getJourney, patchJourney, getJourneyEndStats, getJourneysTags, patchRevision, getJourneySummary, getJourneyKPIs } from '../../shared/common.api';
import moment from 'moment-timezone';
import { Scoped, k } from 'kremling';
import styles from './customer-journey.styles.scss';
import { Icon, PageHeaderMui, Save, TagModal } from 'components';
import { UserStateContext } from '../../context/user-state-context';
import { useContext } from 'react';
import { history } from '../../root.component';
import PropTypes from 'prop-types';
import { Box, Paper, Typography, Stack, TextField, InputAdornment, Tooltip as MuiTooltip, Chip, IconButton, Divider, Table, TableHead, TableRow, TableCell, TableBody, Button, Popper, ClickAwayListener, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Slide, Zoom, Fade, Grow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AddCircleFilledIcon, AddIcon, ArchivedIcon, ArrowRightAltIcon, Calendar24HoursIcon, Calendar30DaysIcon, Calendar365DaysIcon, Calendar7DaysIcon, CloseIcon, DraftIcon, EditIcon, HelpIcon, InboxMoveIcon, InsertChartDataIcon, IntegrationsIcon, MailboxFilledIcon, MailboxIcon, MailEnvelopeFilledIcon, MailEnvelopeIcon, MailIcon, NewspaperIcon, OpenFullIcon, PausedIcon, PlaylistAddCheckIcon, PublishedIcon, SmsFilledIcon, SMSIcon, StoppedIcon, TrendingUpIcon } from 'components/mui';
import { StatusMui } from 'components/status/status-mui.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import DatePicker from 'components/mui/calendar/date-picker.component';
import { withStyles } from '@mui/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { JourneyReleaseNotesModal } from './journey-release-notes-modal.component';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement, Colors } from 'chart.js';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import utils from '../../shared/utils';
import addSegmentImg from './assets/segment-add.png';
import removeSegmentImg from './assets/segment-remove.png';
import segmentImg from './assets/segment.png';
import timeImg from './assets/time.png';
import triggerRecurringImg from './assets/trigger-recurring.png';
import { allNodes } from './node/node-list';
import { styled } from '@mui/material/styles';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement, Colors);
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
const fontFamilyArr = ['Arial', 'Bitter', 'Cabin', 'Georgia', 'Helvetica', 'Lato', 'Merriweather', 'Nexa', 'Open Sans', 'Oswald', 'Popins', 'PT Sans', 'PT Serif', 'Roboto', 'Ubuntu', 'Verdana'];
const {
  EmojiBlot,
  ToolbarEmoji
} = quillEmoji;
Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-toolbar': ToolbarEmoji
}, true);
let fonts = Quill.import('attributors/style/font');
fonts.whitelist = fontFamilyArr;
Quill.register(fonts, true);
const fontSizeArr = ['10px', '11px', '12px', '14px', '16px', '18px', '24px', '30px', '36px', '48px', '60px', '72px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
const modules = {
  toolbar: [[{
    header: '1'
  }, {
    header: '2'
  }, {
    font: fontFamilyArr
  }], [{
    size: fontSizeArr
  }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{
    list: 'ordered'
  }, {
    list: 'bullet'
  }, {
    indent: '-1'
  }, {
    indent: '+1'
  }, 'link', 'image', 'emoji', 'clean']],
  'emoji-toolbar': true
};
const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'clean', 'emoji'];
const defaultNote = '<p><span style="font-family: Nexa; font-size: 16px;">A description of what this journey does</span></p>';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        // border: 'none',
      }
    }
  }
})(TextField);
const REVISION_MAX = 3;
const ASSET_MAX = 6;
function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{}}>
          <Typography component="div">{children}</Typography>
        </Box>}
    </div>;
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
export const CustomerJourneysDetailsNew = props => {
  const {
    hasPermission,
    checkCompany,
    user,
    flags,
    asCompany
  } = useContext(UserStateContext);
  const [isLoadingJourney, setIsLoadingJourney] = useState(true);
  const [journey, setJourney] = useState(null);
  const [revisionsExpanded, setRevisionsExpanded] = useState(false);
  const [segmentsExpanded, setSegmentsExpanded] = useState(false);
  const [templatesExpanded, setTemplatesExpanded] = useState(false);
  const [editTags, setEditTags] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [companyTagsMenuAnchorEl, setCompanyTagsMenuAnchorEl] = useState(null);
  const companyTagsOpen = Boolean(companyTagsMenuAnchorEl);
  const [newTagName, setNewTagName] = useState('');
  const [showJourneyReleaseNotesModal, setShowJourneyReleaseNotesModal] = useState(null);
  const [companyTags, setCompanyTags] = useState([]);
  const [KPIs, setKPIs] = useState([]);
  const [displayedGraph, setDisplayedGraph] = useState('graph1');
  const [editJourneyMetaDataModal, setEditJourneyMetaDataModal] = useState(false);
  const [graph1Data, setGraph1Data] = useState(null);
  const [graph2Data, setGraph2Data] = useState(null);
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [{
      data: []
    }]
  });
  const [originalPieData, setOriginalPieData] = useState(null);
  const [pieTableData, setPieTableData] = useState([]);
  const [selectTriggers, setSelectTriggers] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const [helpData, setHelpData] = React.useState(null);
  const [pieBreadcrumbs, setPieBreadcrumbs] = useState([]);
  const [journeyTags, setJourneyTags] = useState([]);
  const [journeyDescription, setJourneyDescription] = useState('');
  const [journeyName, setJourneyName] = useState('');
  const [fullscreenChart, setFullscreenChart] = useState(false);
  const [fullscreenChartToggle, setFullscreenChartToggle] = useState('week');
  const [originalReports, setOriginalReports] = useState(null);
  const [fullscreenPie, setFullscreenPie] = useState(false);
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL([]);
    if (!fils['date_after']) {
      fils['date_after'] = moment().subtract(180, 'days').startOf('day').toISOString();
    }
    return fils;
  });
  useEffect(() => {
    loadJourney();
  }, []);
  const formatNumber = num => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
  };
  const buildKPIs = journeyKPIs => {
    if (!journeyKPIs) {
      return;
    }
    // console.log(journeyKPIs, 'journeyKPIs');
    setKPIs([{
      id: 'sessionStarted',
      stat1: {
        title: 'Total Sessions',
        stat: formatNumber(journeyKPIs?.total_sessions)
      },
      stat2: {
        stat: formatNumber(journeyKPIs?.completed_sessions) + ' Completed Sessions'
      }
    }, {
      id: 'CompletedDays',
      stat1: {
        title: 'Journey Days Completed',
        stat: journeyKPIs?.completed_days
      },
      stat2: {
        stat: 'Days Running'
      }
    }, {
      id: 'successRate',
      stat1: {
        title: 'Success Rate',
        stat: Math.round(journeyKPIs?.success_rate_for_completed_days) + '%'
      },
      stat2: {
        stat: formatNumber(journeyKPIs?.success_sessions) + ' Successful'
      },
      stat3: {
        stat: formatNumber(journeyKPIs?.failure_sessions) + ' Failed'
      }
    }]);
  };
  const buildGraphs = (journeyReports, latest_completed_day) => {
    if (!journeyReports) {
      return;
    }
    // console.log(journeyReports, 'journeyReports');
    setGraph1Data({
      latest_completed_day: latest_completed_day,
      data: journeyReports?.map(report => {
        return {
          labelName: moment(report.session_start_date).format('MM/DD/YY'),
          success_sessions: report.success_sessions,
          failure_sessions: report.failure_sessions,
          ongoing_sessions: report.ongoing_sessions,
          not_applicable_sessions: report.not_applicable_sessions,
          stopped_sessions: report.stopped_sessions,
          paused_sessions: report.paused_sessions
        };
      })
    });
    setGraph2Data({
      latest_completed_day: latest_completed_day,
      data: journeyReports?.map(report => {
        return {
          labelName: moment(report.session_start_date).format('MM/DD/YY'),
          success_sessions: report.success_sessions / report.total_sessions,
          failure_sessions: report.failure_sessions / report.total_sessions,
          ongoing_sessions: report.ongoing_sessions / report.total_sessions,
          not_applicable_sessions: report.not_applicable_sessions / report.total_sessions,
          stopped_sessions: report.stopped_sessions / report.total_sessions,
          paused_sessions: report.paused_sessions / report.total_sessions
        };
      })
    });
  };
  const getPieColor = (key, level) => {
    if (level == 0) {
      if (key == 'Completed') {
        return '#0088fe';
      }
      if (key == 'Incomplete') {
        return '#fcba03';
      }
    }
    if (level == 1) {
      if (key == 'Complete Session') {
        return '#3EB87B';
      }
      if (key == 'Incomplete Session') {
        return '#E77A16';
      }
      if (key == 'Not Applicable Session') {
        return '#c2c0c0';
      }
    }
    if (level == 2) {
      if (key == 'Success') {
        return '#2ecc70';
      }
      if (key == 'Failure') {
        return '#e74d3c';
      }
      if (key == 'Ongoing') {
        return '#a8d0f5';
      }
      if (key == 'Not Applicable') {
        return '#c2c0c0';
      }
      if (key == 'Stopped') {
        return '#fad79e';
      }
      if (key == 'Paused') {
        return '#e8a6b3';
      }
    }
    if (level == 3) {
      //some random colors i picked, my soul for a designer
      const colors = ['#2ecc70', '#e74d3c', '#a8d0f5', '#c2c0c0', '#fad79e', '#e8a6b3', '#f1c40f', '#8e44ad', '#3498db', '#1abc9c', '#f39c12', '#d35400'];
      return colors[key % colors.length];
    }
    return '#3EB87B';
  };
  const addPieColors = reasons => {
    return {
      ...reasons,
      children: reasons?.children.map(r => {
        return {
          ...r,
          color: getPieColor(r.key, 0),
          children: r.children?.map(c => {
            return {
              ...c,
              color: getPieColor(c.key, 1),
              children: c.children?.map(c2 => {
                return {
                  ...c2,
                  color: getPieColor(c2.key, 2),
                  children: c2.children?.map((c3, i) => {
                    return {
                      ...c3,
                      color: getPieColor(i, 3)
                    };
                  })
                };
              })
            };
          })
        };
      })
    };
  };
  const buildReasons = reasons => {
    if (!reasons) {
      return;
    }
    if (reasons?.sum_sessions == 0) {
      setPieData({
        labels: [],
        datasets: [{
          data: []
        }]
      });
      setPieBreadcrumbs([]);
      setPieTableData([]);
      return;
    }

    //determine if we can zoom to level 3
    const level3Reasons = reasons?.children?.find(r => r.key == 'Completed')?.children.find(r => r.key == 'Complete Session')?.children.find(r => r.key == 'Success');
    if (level3Reasons?.sum_sessions > 0) {
      console.log('can zoom to level 3');
      setPieData({
        labels: level3Reasons?.children.map(d => d.key),
        datasets: [{
          data: level3Reasons?.children.map(d => d.sum_sessions),
          backgroundColor: level3Reasons?.children[0]?.color ? level3Reasons.children.map(d => d.color) : null,
          borderWidth: 0
        }]
      });
      setPieBreadcrumbs([{
        index: reasons.children.findIndex(r => r.key == 'Completed'),
        label: 'Completed',
        parent: reasons
      }, {
        index: reasons.children.find(r => r.key == 'Completed').children.findIndex(r => r.key == 'Complete Session'),
        label: 'Complete Session',
        parent: reasons.children.find(r => r.key == 'Completed')
      }, {
        index: reasons.children.find(r => r.key == 'Completed').children.find(r => r.key == 'Complete Session').children.findIndex(r => r.key == 'Success'),
        label: 'Success',
        parent: reasons.children.find(r => r.key == 'Completed').children.find(r => r.key == 'Complete Session')
      }]);
      setPieTableData(level3Reasons);
    } else {
      console.log('cant zoom to level 3, going to top level');
      setPieData({
        labels: reasons?.children?.map(d => d.key),
        datasets: [{
          data: reasons?.children?.map(d => d.sum_sessions),
          backgroundColor: reasons?.children?.length > 0 && reasons?.children[0]?.color ? reasons.children.map(d => d.color) : null,
          borderWidth: 0
        }]
      });
      setPieBreadcrumbs([]);
      setPieTableData(reasons);
    }
  };
  useEffect(() => {
    if (journey) {
      //refresh journey reports
      setIsLoadingJourney(true);
      const promises = [getJourneySummary(journey.id, filters)];
      Promise.all(promises).then(([reports]) => {
        //console.log(reports, 'reports');
        setOriginalReports(reports.data);
        buildKPIs(reports?.data?.kpi_results);
        buildGraphs(reports?.data?.grouped_summary_results, reports?.data?.kpi_results?.latest_completed_day);
        setOriginalPieData(reports?.data?.reasons_results ? addPieColors(reports.data.reasons_results) : null);
        buildReasons(addPieColors(reports.data.reasons_results));
        setIsLoadingJourney(false);
      });
    }
  }, [filters]);
  const loadJourney = () => {
    setIsLoadingJourney(true);
    const {
      id
    } = props.match.params;
    const promises = [getJourney(id), getJourneysTags(asCompany.id), getJourneySummary(id, filters)];
    Promise.all(promises).then(([journeyData, tagsData, reports]) => {
      if (journeyData.data) {
        checkCompany(journeyData.data.company).then(() => {
          // console.log(reports.data, 'reports');

          setFilters({
            ...filters,
            trigger: reports?.data?.trigger_metadata?.selected_trigger
          });
          const t = [];
          if (reports?.data?.trigger_metadata?.triggers) {
            Object.entries(reports.data.trigger_metadata.triggers).forEach(([key, value]) => {
              t.push({
                key: key,
                label: allNodes.find(n => n.subType == key)?.name?.split('Trigger')[0] || key,
                count: value
              });
            });
            setSelectTriggers(t);
          }
          const journ = journeyData.data;
          const allTemplates = [];
          [...journeyData.data.email_templates, ...journeyData.data.print_templates, ...journeyData.data.sms_templates].map(t => {
            if (allTemplates.find(at => at.id === t.id)) {
              allTemplates.find(at => at.id === t.id).count += 1;
            } else {
              allTemplates.push({
                ...t,
                count: 1
              });
            }
          });
          journ.all_templates = allTemplates;
          const allSegments = [];
          [...journeyData.data.segment_edits.filter(j => j.id !== null), ...journeyData.data.segment_triggers].map(t => {
            if (allSegments.find(at => at.id === t.id && at.node_name === t.node_name)) {
              allSegments.find(at => at.id === t.id).count += 1;
            } else {
              allSegments.push({
                ...t,
                count: 1
              });
            }
          });
          journ.all_segments = allSegments;
          setJourney(journ);
          //console.log(journ, 'journ');

          setCompanyTags(tagsData.data.results);
          setOriginalReports(reports.data);
          buildKPIs(reports?.data?.kpi_results);
          buildGraphs(reports?.data?.grouped_summary_results, reports?.data?.kpi_results?.latest_completed_day);
          setOriginalPieData(reports?.data?.reasons_results ? addPieColors(reports.data.reasons_results) : null);
          buildReasons(addPieColors(reports.data.reasons_results));
          setIsLoadingJourney(false);
        }).catch(e => {
          console.log(e, 'error');
          // User chose not to switch companies.
        });
      }
    }).catch(e => {
      setIsLoadingJourney(false);
      console.log(e, 'error');
    });
  };
  const goToJourney = (id, mode) => {
    if (hasPermission('journey.view_journey')) {
      history.push({
        pathname: `/customer-journeys/${mode}/${id}`
      });
    }
  };
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue == 0) {
      //nothing, already on this page
    }
    if (newValue == 1) {
      goToJourney(props.match.params.id, 'draft');
    }
    if (newValue == 2) {
      goToJourney(props.match.params.id, 'published');
    }
    if (newValue == 3) {
      history.push(`/customer-journeys/wizard-architect/${props?.match?.params?.id}`);
    }
  };
  useEffect(() => {
    if (showSaved) {
      const delayDebounceFn = setTimeout(() => {
        setIsSaving(true);
        patchJourney(props.match.params.id, journey).then(({
          data
        }) => {
          setIsSaving(false);
        });
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [journey]);
  const handleCompanyTagsClick = event => {
    setCompanyTagsMenuAnchorEl(companyTagsOpen ? null : event.currentTarget);
  };
  const handleCompanyTagsClose = () => {
    setCompanyTagsMenuAnchorEl(null);
  };
  const handleNewTagClick = () => {
    if (newTagName?.length > 0 && !isExistingTag()) {
      setJourneyTags([...journeyTags, newTagName]);
      setNewTagName('');
    }
  };
  const isExistingTag = () => {
    if (!journey.tags || journey.tags?.length == 0) {
      return false;
    }
    for (const tag of journey.tags) {
      if (tag.toLowerCase() == newTagName?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  const handleDelete = (tagToDelete, index) => () => {
    setJourneyTags(journeyTags.filter(tag => tag !== tagToDelete));
  };
  const handleDateChange = (after, before) => {
    const {
      date_after: _,
      date_before: __,
      ...newFilters
    } = filters;
    setFilters({
      ...newFilters,
      date_after: after,
      ...(!!before && {
        date_before: before
      })
    });
  };
  const getTemplateIcon = type => {
    if (type === 'email') {
      return <MailEnvelopeFilledIcon fill="#e1e1e1" />;
    }
    if (type === 'sms') {
      return <SmsFilledIcon fill="#e1e1e1" />;
    }
    if (type === 'print') {
      return <MailboxFilledIcon fill="#e1e1e1" />;
    }
  };
  const getTemplateTooltip = type => {
    if (type === 'email') {
      return 'Send Email';
    }
    if (type === 'sms') {
      return 'Send SMS/MMS';
    }
    if (type === 'print') {
      return 'Send Mail';
    }
  };
  const getSegmentIcon = type => {
    if (type === 'action_add_segment') {
      return addSegmentImg;
    }
    if (type == 'action_remove_segment') {
      return removeSegmentImg;
    }
    if (type == 'trigger_segment') {
      return segmentImg;
    }
    if (type == 'trigger_time') {
      return timeImg;
    }
    if (type == 'trigger_recurring') {
      return triggerRecurringImg;
    }
  };
  const getFormatedSegmentName = type => {
    if (type === 'action_add_segment') {
      return 'Add to Static Segment';
    }
    if (type == 'action_remove_segment') {
      return 'Remove from Static Segment';
    }
    if (type == 'trigger_segment') {
      return 'Static Segment Trigger';
    }
    if (type == 'trigger_time') {
      return 'Time Trigger';
    }
    if (type == 'trigger_recurring') {
      return 'Recurring Time Trigger';
    }
  };
  const getSegmentColor = type => {
    if (type == 'action_add_segment' || type == 'action_remove_segment') {
      return '#E77A16';
    }
    if (type == 'trigger_segment' || type == 'trigger_time' || type == 'trigger_recurring') {
      return '#3EB87B';
    }
  };
  const handleHelpClose = () => {
    setHelpAnchorEl(null);
    setHelpData(null);
  };
  const handleHelpPopupClick = (e, index) => {
    if (index == 'sessionStarted') {
      setHelpData({
        title: <Typography variant="tableHeader"> Sessions</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Total Sessions:
              </Box>
              TODO:some copy about total sessions
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Completed Sessions:
              </Box>
              Todo: some copy about completed sessions
            </Typography>
          </Stack>
      });
    }
    if (index == 'CompletedDays') {
      setHelpData({
        title: <Typography variant="tableHeader"> Benchmarks</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Days To Benchmark:
              </Box>
              TODO:some copy about days to benchmark
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Days Running:
              </Box>
              Todo: some copy about days running
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Days till complete:
              </Box>
              Todo: some copy about days till complete
            </Typography>
          </Stack>
      });
    }
    if (index == 'successRate') {
      setHelpData({
        title: <Typography variant="tableHeader"> Success Rates</Typography>,
        content: <Stack spacing={'20px'}>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Success Rate:
              </Box>
              TODO:some copy about success rate
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Successful Sessions:
              </Box>
              Todo: some copy about successful sessions
            </Typography>
            <Typography component="div">
              <Box fontWeight="700" display="inline" sx={{
              pr: '3px'
            }}>
                Failure Sessions:
              </Box>
              Todo: some copy about failure sessions
            </Typography>
          </Stack>
      });
    }
    setHelpAnchorEl(e.currentTarget);
  };
  const handlePieClick = index => {
    const newLevelData = pieTableData.children[index];
    if (!newLevelData?.children || newLevelData?.children.length == 0) {
      return;
    }
    setPieData({
      labels: newLevelData.children.map(d => d.key),
      datasets: [{
        data: newLevelData.children.map(d => d.sum_sessions),
        borderWidth: 0,
        backgroundColor: newLevelData.children[0]?.color ? newLevelData.children.map(d => d.color) : null
      }]
    });
    setPieTableData(newLevelData);
    setPieBreadcrumbs([...pieBreadcrumbs, {
      index,
      parent: pieTableData,
      label: newLevelData.key
    }]);
  };
  const handlePieBreadcrumbClick = index => {
    if (index == 3) {
      return;
    }
    let newPieData = [];
    let newPieBreadcrumbs = [];
    if (index == 0) {
      newPieData = originalPieData;
      newPieBreadcrumbs = [];
    } else if (index == 1) {
      newPieData = originalPieData.children[pieBreadcrumbs[0].index];
      newPieBreadcrumbs = pieBreadcrumbs.slice(0, 1);
    } else if (index == 2) {
      newPieData = originalPieData.children[pieBreadcrumbs[0].index].children[pieBreadcrumbs[1].index];
      newPieBreadcrumbs = pieBreadcrumbs.slice(0, 2);
    }

    //console.log(newPieData, 'newPieData');

    setPieData({
      labels: newPieData.children.map(d => d.key),
      datasets: [{
        data: newPieData.children.map(d => d.sum_sessions),
        borderWidth: 0,
        backgroundColor: newPieData.children[0]?.color ? newPieData.children.map(d => d.color) : null
      }]
    });
    setPieTableData(newPieData);
    setPieBreadcrumbs(newPieBreadcrumbs);
  };
  const handleFullscreenChartClose = () => {
    setFullscreenChart(false);
    setFullscreenChartToggle('week');
    buildGraphs(originalReports?.grouped_summary_results, originalReports?.kpi_results?.latest_completed_day);
  };
  const handleFullscreenPieClose = () => {
    setFullscreenPie(false);
  };
  const displayGraphs = size => {
    return <>
        {graph1Data && journey?.status != 'draft' && <Stack spacing="16px" sx={{
        width: size,
        pr: '8px'
      }}>
            <Box sx={{
          border: '1px solid #E2E2E2',
          borderRadius: '8px'
        }}>
              <Box sx={{
            p: 1
          }}>
                <Stack>
                  {size == '50%' && <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                      <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pl: 1
                }}>
                        <Typography sx={{
                    fontSize: 24,
                    fontWeight: 700
                  }}>Weekly Session Status</Typography>
                      </Box>
                      <Stack direction={'row'} spacing={1}>
                        <Box>
                          <MuiTooltip title="Toggle Stacked vs 100% Chart">
                            <IconButton onClick={e => {
                        if (displayedGraph == 'graph1') {
                          setDisplayedGraph('graph2');
                        } else {
                          setDisplayedGraph('graph1');
                        }
                      }}>
                              <InsertChartDataIcon />
                            </IconButton>
                          </MuiTooltip>
                        </Box>

                        {size == '50%' && <Box>
                            <MuiTooltip title="View graph expanded to full screen">
                              <IconButton onClick={e => {
                        setFullscreenChart(true);
                      }}>
                                <OpenFullIcon />
                              </IconButton>
                            </MuiTooltip>
                          </Box>}

                        {size == '100%' && <Box>
                            <MuiTooltip title={fullscreenChartToggle == 'week' ? 'View Day Chart' : 'View Week Chart'}>
                              <IconButton onClick={e => {
                        if (fullscreenChartToggle == 'week') {
                          buildGraphs(originalReports?.summary_results, originalReports?.kpi_results?.latest_completed_day);
                          setFullscreenChartToggle('day');
                        } else {
                          buildGraphs(originalReports?.grouped_summary_results, originalReports?.kpi_results?.latest_completed_day);
                          setFullscreenChartToggle('week');
                        }
                      }}>
                                {fullscreenChartToggle == 'week' ? <Calendar24HoursIcon /> : <Calendar7DaysIcon />}
                              </IconButton>
                            </MuiTooltip>
                          </Box>}
                      </Stack>
                    </Box>}
                  {displayedGraph == 'graph1' ? (/* !!!!!!!!!!!!!!!!!!!!!!!GRAPH 1!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
              <Box sx={{
                height: size == '50%' ? '55vh' : '80vh'
              }}>
                      <Bar plugins={[{
                  afterDraw: chart => {
                    if (graph1Data?.data?.length > 0) {
                      /*     let ctx = chart.ctx;
                                      let width = chart.width;
                                      let height = chart.height;
                                       ctx.beginPath();
                                      ctx.moveTo(width / 2, 20);
                                      ctx.strokeStyle = '#ff0000';
                                      ctx.lineWidth = 2;
                                      ctx.lineTo(width / 2, height - 27);
                                       ctx.stroke();
                                      ctx.closePath();
                                       ctx.textAlign = 'center';
                                      ctx.textBaseline = 'middle';
                                      ctx.font = '16px Nexa';
                                      ctx.fillText('Benchmark Date', width / 2, 10); */
                    } else {
                      let ctx = chart.ctx;
                      let width = chart.width;
                      let height = chart.height;

                      //chart.clear();
                      ctx.save();
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'middle';
                      ctx.font = '16px Nexa';
                      ctx.fillText('No Data', width / 2, (height - 20) / 2);
                      ctx.restore();
                    }
                  }
                }]} options={{
                  // indexAxis: 'y',
                  responsive: true,
                  maintainAspectRatio: false,
                  elements: {
                    bar: {
                      // borderSkipped: false, // Apply setting to all bar datasets
                    }
                  },
                  scales: {
                    x: {
                      stacked: true,
                      grid: {
                        display: false,
                        offset: false
                      },
                      ticks: {
                        stepSize: 1
                      },
                      title: {
                        display: true,
                        text: 'Session Start Date'
                      }
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Sessions'
                      },
                      stacked: true,
                      ticks: {
                        precision: 0,
                        beginAtZero: true,
                        color: '#1D252D',
                        font: {
                          family: 'Nexa'
                        }
                      },
                      grid: {
                        display: false
                      }
                    }
                  },
                  plugins: {
                    title: {
                      display: false
                    },
                    legend: {
                      //display: resizeBreakpoint > 200 ? true : false,
                      display: true,
                      events: [],
                      position: 'bottom',
                      font: {
                        family: 'Nexa'
                      }
                    },
                    tooltip: {
                      //intersect: false,
                      // events: [],
                      displayColors: false,
                      callbacks: {
                        label: function (context) {
                          return ['Session Start Date: ' + context.label, 'Sessions: ' + utils.commaize(context.parsed.y), 'Session Status: ' + context.dataset.label];
                        },
                        title: function (context) {
                          return '';
                        }
                      }
                    }
                  }
                }} data={{
                  labels: graph1Data?.data?.map(journey => journey.labelName),
                  datasets: [{
                    data: graph1Data?.data?.map(journey => {
                      return journey?.success_sessions ? journey.success_sessions : 0;
                    }),
                    label: 'Success',
                    backgroundColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#2ecc70' : '#9de3ba'),
                    borderColor: '#24a359',
                    /*   borderColor: graph1Data?.data?.map((journey) =>
                                      moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#24a359' : '#90d1ab',
                                    ), */
                    borderColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#2ecc70' : '#9de3ba'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    //barPercentage: 1,
                  }, {
                    data: graph1Data?.data?.map(journey => {
                      return journey?.failure_sessions ? journey.failure_sessions : 0;
                    }),
                    label: 'Failure',
                    //backgroundColor: '#e74d3c',
                    backgroundColor: graph1Data?.data?.map(journey => moment(graph1Data?.latest_completed_day) > moment(journey.labelName) ? '#e74d3c' : '#e69b93'),
                    /*  borderColor: graph1Data?.data?.map((journey) =>
                                      moment(graph1Data?.latest_completed_day) > moment(journey.labelName)
                                        ? '#bf4032'
                                        : '#bd4b3e',
                                    ), */
                    borderColor: graph1Data?.data?.map(journey => moment(graph1Data?.latest_completed_day) > moment(journey.labelName) ? '#e74d3c' : '#e69b93'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    //barPercentage: 1,
                  }, {
                    data: graph1Data?.data?.map(journey => {
                      return journey?.ongoing_sessions ? journey.ongoing_sessions : 0;
                    }),
                    label: 'Ongoing',
                    // backgroundColor: '#a8d0f5',
                    backgroundColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#a8d0f5' : '#d8e5f2'),
                    /*  borderColor: graph1Data?.data?.map((journey) =>
                                      moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#7894ad' : '#abb7c2',
                                    ), */
                    borderColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#a8d0f5' : '#d8e5f2'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    //barPercentage: 1,
                  }, {
                    data: graph1Data?.data?.map(journey => {
                      return journey?.not_applicable_sessions ? journey.not_applicable_sessions : 0;
                    }),
                    label: 'Not Applicable',
                    // backgroundColor: '#e0e0e0',
                    backgroundColor: graph1Data?.data?.map(journey => moment(graph1Data?.latest_completed_day) > moment(journey.labelName) ? '#c2c0c0' : '#e0e0e0'),
                    /*    borderColor: graph1Data?.data?.map((journey) =>
                                      moment(graph1Data?.latest_completed_day) > moment(journey.labelName)
                                        ? '#9c9a9a'
                                        : '#d9d7d7',
                                    ), */
                    borderColor: graph1Data?.data?.map(journey => moment(graph1Data?.latest_completed_day) > moment(journey.labelName) ? '#c2c0c0' : '#e0e0e0'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    // barPercentage: 1,
                  }, {
                    data: graph1Data?.data?.map(journey => {
                      return journey?.stopped_sessions ? journey.stopped_sessions : 0;
                    }),
                    label: 'Stopped',
                    // backgroundColor: '#fad79e',
                    backgroundColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#fad79e' : '#f7e5c6'),
                    /*  borderColor: graph1Data?.data?.map((journey) =>
                                      moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#d1b07b' : '#d1bb97',
                                    ), */
                    borderColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#fad79e' : '#f7e5c6'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    // barPercentage: 1,
                  }, {
                    data: graph1Data?.data?.map(journey => {
                      return journey?.paused_sessions ? journey.paused_sessions : 0;
                    }),
                    label: 'Stopped',
                    //backgroundColor: '#e8a6b3',
                    backgroundColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#e8a6b3' : '#edbbc5'),
                    /*  borderColor: graph1Data?.data?.map((journey) =>
                                      moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#b3818a' : '#ad959a',
                                    ), */
                    borderColor: graph1Data?.data?.map(journey => moment(graph1Data.latest_completed_day) > moment(journey.labelName) ? '#e8a6b3' : '#edbbc5'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    // barPercentage: 1,
                  }]
                }} />
                    </Box>) : (/* !!!!!!!!!!!!!!!!!!!!!!!!!!GRAPH2!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
              <Box sx={{
                height: size == '50%' ? '55vh' : '80vh'
              }}>
                      <Bar plugins={[{
                  afterDraw: chart => {
                    if (graph2Data?.data?.length > 0) {
                      /*     let ctx = chart.ctx;
                                      let width = chart.width;
                                      let height = chart.height;
                                       ctx.beginPath();
                                      ctx.moveTo(width / 2, 20);
                                      ctx.strokeStyle = '#ff0000';
                                      ctx.lineWidth = 2;
                                      ctx.lineTo(width / 2, height - 27);
                                       ctx.stroke();
                                      ctx.closePath();
                                       ctx.textAlign = 'center';
                                      ctx.textBaseline = 'middle';
                                      ctx.font = '16px Nexa';
                                      ctx.fillText('Benchmark Date', width / 2, 10); */
                    } else {
                      let ctx = chart.ctx;
                      let width = chart.width;
                      let height = chart.height;

                      //chart.clear();
                      ctx.save();
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'middle';
                      ctx.font = '16px Nexa';
                      ctx.fillText('No Data', width / 2, (height - 20) / 2);
                      ctx.restore();
                    }
                  }
                }]} options={{
                  // indexAxis: 'y',
                  responsive: true,
                  maintainAspectRatio: false,
                  elements: {
                    bar: {
                      // borderSkipped: false, // Apply setting to all bar datasets
                    }
                  },
                  scales: {
                    x: {
                      stacked: true,
                      grid: {
                        display: false,
                        offset: false
                      },
                      ticks: {
                        stepSize: 1
                      },
                      title: {
                        display: true,
                        text: 'Session Start Date'
                      }
                    },
                    y: {
                      min: 0,
                      max: 1,
                      title: {
                        display: true,
                        text: 'Sessions'
                      },
                      stacked: true,
                      ticks: {
                        precision: 2,
                        beginAtZero: true,
                        color: '#1D252D',
                        font: {
                          family: 'Nexa'
                        },
                        stepSize: 0.2,
                        callback: function (val, index) {
                          return val * 100 + '%';
                        }
                      },
                      grid: {
                        display: true
                        // tickLength: 0,
                      }
                    }
                  },
                  plugins: {
                    title: {
                      display: false
                    },
                    legend: {
                      //display: resizeBreakpoint > 200 ? true : false,
                      display: true,
                      events: [],
                      position: 'bottom',
                      font: {
                        family: 'Nexa'
                      }
                    },
                    tooltip: {
                      //intersect: false,
                      // events: [],
                      displayColors: false,
                      callbacks: {
                        label: function (context) {
                          return ['Session Start Date: ' + context.label, 'Sessions: ' + (context.parsed.y * 100).toFixed(2) + '%', 'Session Status: ' + context.dataset.label];
                        },
                        title: function (context) {
                          return '';
                        }
                      }
                    }
                  }
                }} data={{
                  labels: graph2Data?.data?.map(journey => journey.labelName),
                  datasets: [{
                    data: graph2Data?.data?.map(journey => {
                      return journey?.success_sessions ? journey.success_sessions : 0;
                    }),
                    label: 'Success',
                    backgroundColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#2ecc70' : '#9de3ba'),
                    borderColor: graph2Data.data.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#2ecc70' : '#9de3ba'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    // barPercentage: 1,
                  }, {
                    data: graph2Data?.data?.map(journey => {
                      return journey?.failure_sessions ? journey.failure_sessions : 0;
                    }),
                    label: 'Failure',
                    //backgroundColor: '#e74d3c',
                    backgroundColor: graph2Data?.data?.map(journey => moment(graph2Data?.latest_completed_day) > moment(journey.labelName) ? '#e74d3c' : '#e69b93'),
                    borderColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#e74d3c' : '#e69b93'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    // barPercentage: 1,
                  }, {
                    data: graph2Data?.data?.map(journey => {
                      return journey?.ongoing_sessions ? journey.ongoing_sessions : 0;
                    }),
                    label: 'Ongoing',
                    // backgroundColor: '#a8d0f5',
                    backgroundColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#a8d0f5' : '#d8e5f2'),
                    borderColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#a8d0f5' : '#d8e5f2'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    //barPercentage: 1,
                  }, {
                    data: graph2Data?.data?.map(journey => {
                      return journey?.not_applicable_sessions ? journey.not_applicable_sessions : 0;
                    }),
                    label: 'Not Applicable',
                    // backgroundColor: '#e0e0e0',
                    backgroundColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#c2c0c0' : '#e0e0e0'),
                    borderColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#c2c0c0' : '#e0e0e0'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    // barPercentage: 1,
                  }, {
                    data: graph2Data?.data?.map(journey => {
                      return journey?.stopped_sessions ? journey.stopped_sessions : 0;
                    }),
                    label: 'Stopped',
                    // backgroundColor: '#fad79e',
                    backgroundColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#fad79e' : '#f7e5c6'),
                    borderColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#fad79e' : '#f7e5c6'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    //barPercentage: 1,
                  }, {
                    data: graph2Data?.data?.map(journey => {
                      return journey?.paused_sessions ? journey.paused_sessions : 0;
                    }),
                    label: 'Stopped',
                    //backgroundColor: '#e8a6b3',
                    backgroundColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#e8a6b3' : '#edbbc5'),
                    borderColor: graph2Data?.data?.map(journey => moment(graph2Data.latest_completed_day) > moment(journey.labelName) ? '#e8a6b3' : '#edbbc5'),
                    borderSkipped: 'start',
                    categoryPercentage: 1,
                    borderWidth: 1
                    //barPercentage: 1,
                  }]
                }} />
                    </Box>)}
                </Stack>
              </Box>
            </Box>
          </Stack>}
      </>;
  };
  const displayPie = size => {
    return <>
        {journey?.status != 'draft' && originalPieData && <Stack spacing="16px" sx={{
        width: size == '50%' ? '50%' : '100%',
        pl: '8px'
      }}>
            <Box sx={{
          border: size == '50%' ? '1px solid #E2E2E2' : 'none',
          borderRadius: '8px',
          height: '100%'
        }}>
              <Stack spacing={size == '50%' ? '16px' : '48px'} sx={{
            p: size == '50%' ? 2 : 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%'
            }}>
                  <Stack spacing="16px" sx={{
                width: '100%'
              }}>
                    <Stack sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  width: '100%'
                }} direction="row" spacing={'10px'}>
                      <Stack spacing={1} sx={{
                    width: '100%'
                  }}>
                        {size == '50%' && <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                            <Box>
                              <Typography sx={{
                          fontSize: 24,
                          fontWeight: 700
                        }}>Session Status Distribution</Typography>
                            </Box>

                            <Box>
                              <MuiTooltip title="View Pie expanded to full screen">
                                <IconButton onClick={e => {
                            setFullscreenPie(true);
                          }}>
                                  <OpenFullIcon />
                                </IconButton>
                              </MuiTooltip>
                            </Box>
                          </Box>}

                        <Stack direction="row" spacing={1}>
                          <Stack direction="row" spacing={'10px'}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 0 < pieBreadcrumbs.length ? 'pointer' : 'default'
                        }} onClick={e => {
                          handlePieBreadcrumbClick(0);
                        }}>
                              <Typography sx={{
                            opacity: 0.5,
                            '&:hover': {
                              color: 0 < pieBreadcrumbs.length ? '#3898D9' : 'inherit'
                            }
                          }}>
                                All Days
                              </Typography>
                            </Box>
                            {pieBreadcrumbs.length > 0 && <Typography sx={{
                          opacity: 0.5
                        }}>&gt;</Typography>}
                          </Stack>

                          {pieBreadcrumbs.map((breadcrumb, index) => <Stack key={breadcrumb.id} direction="row" spacing={'10px'}>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: index < pieBreadcrumbs.length - 1 ? 'pointer' : 'default'
                        }} onClick={e => {
                          handlePieBreadcrumbClick(index + 1);
                        }}>
                                <Typography sx={{
                            opacity: 0.5,
                            '&:hover': {
                              color: index < pieBreadcrumbs.length - 1 ? '#3898D9' : 'inherit'
                            }
                          }}>
                                  {breadcrumb.label}
                                </Typography>
                              </Box>

                              {index < pieBreadcrumbs.length - 1 && <Typography sx={{
                          opacity: 0.5
                        }}>&gt;</Typography>}
                            </Stack>)}
                        </Stack>
                      </Stack>
                    </Stack>

                    <Box sx={{
                  cursor: pieBreadcrumbs.length < 3 ? 'pointer' : 'default'
                }}>
                      <Pie height={size == '50%' ? 170 : 450}
                  //width={100}
                  options={{
                    //rotation: 180,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      /*   colors: {
                                        enabled: true,
                                      }, */

                      title: {
                        display: false,
                        text: 'Sum of Sessions'
                      },
                      tooltip: {
                        //intersect: false,
                        // events: [],
                        displayColors: false
                        /*    callbacks: {
                                      label: function (context) {
                                        console.log(context, 'context');
                                        return context.dataset.label;
                                      },
                                      title: function (context) {
                                        return '';
                                      },
                                    }, */
                      },
                      legend: {
                        display: false,
                        position: 'bottom',
                        reverse: true,
                        labels: {
                          boxWidth: 10,
                          boxHeight: 10,
                          usePointStyle: true
                        }
                      }
                    },
                    onClick: function (e, item) {
                      handlePieClick(item[0]?.index);
                    }
                  }} data={pieData} {...props} />
                    </Box>
                  </Stack>
                </Box>
                <Stack spacing={1} sx={{
              width: '100%'
            }}>
                  <Box>
                    <Table>
                      <colgroup>
                        <col width="55%" />
                        <col width="25%" />
                        <col width="25%" />
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          <TableCell>{pieBreadcrumbs?.length == 0 ? 'Days' : 'Sessions'}</TableCell>
                          <TableCell>Count</TableCell>
                          <TableCell>Total Percentage</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pieTableData?.children?.map((row, index) => <TableRow hover={pieBreadcrumbs.length < 3} key={index} sx={{
                      cursor: pieBreadcrumbs.length < 3 ? 'pointer' : 'default'
                    }} onClick={() => handlePieClick(index)}>
                            <TableCell>
                              <Stack direction="row" spacing={1}>
                                <Box sx={{
                            borderRadius: '3px',
                            background: row?.color || '#0088fe',
                            height: '24px',
                            width: '12px'
                          }} />

                                <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                  <Typography> {row.key}</Typography>
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell>{utils.commaize(row.sum_sessions)}</TableCell>
                            <TableCell>{Number(row.percentage).toFixed(2)}%</TableCell>
                          </TableRow>)}

                        <TableRow>
                          <TableCell>
                            <Typography variant="tableHeader"> Grand Total:</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="tableHeader">{utils.commaize(pieTableData?.sum_sessions)} </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="tableHeader">100%</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>}
      </>;
  };
  return <>
      <Scoped css={styles}>
        <div className="wrapper">
          <>
            <PageHeaderMui type="Journey Details" name={journey?.name}
          // updateName={!isLoadingJourney ? updateName : null}
          // updateNamePermission={!isLoadingJourney && hasPermission('journey.change_journey')}
          icon={<Icon name="custom-account_tree" size={34} />} />
            <Box display="flex" justifyContent="space-between">
              <Stack sx={{
              px: '20px',
              pt: '20px'
            }} spacing="15px" direction={'row'}>
                <Box className="box-button" sx={{
                backgroundColor: currentTab == 0 ? '#3898D9' : '#F3F6FC',
                ':hover': {
                  backgroundColor: currentTab == 0 ? '#3898D9' : '#C2E7FF'
                }
              }} onClick={e => {
                handleTabsChange(e, 0);
              }}>
                  <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

                  <Typography sx={{
                  pl: '10px',
                  color: currentTab == 0 ? '#ffffff' : '#1D252D'
                }}>
                    Home
                  </Typography>
                </Box>
                <Box className="box-button" sx={{
                backgroundColor: currentTab == 1 ? '#3898D9' : '#F3F6FC',
                ':hover': {
                  backgroundColor: currentTab == 1 ? '#3898D9' : '#C2E7FF'
                }
              }} onClick={e => {
                handleTabsChange(e, 1);
              }}>
                  <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />
                  <Typography sx={{
                  pl: '10px',
                  color: currentTab == 1 ? '#ffffff' : '#1D252D'
                }}>
                    Draft View
                  </Typography>
                </Box>

                {journey?.status !== 'draft' && <Box className="box-button" sx={{
                backgroundColor: currentTab == 2 ? '#3898D9' : '#F3F6FC',
                ':hover': {
                  backgroundColor: currentTab == 2 ? '#3898D9' : '#C2E7FF'
                }
              }} onClick={e => {
                handleTabsChange(e, 2);
              }}>
                    <PublishedIcon fill={currentTab == 2 ? '#ffffff' : '#1D252D'} />

                    <Typography sx={{
                  pl: '10px',
                  color: currentTab == 2 ? '#ffffff' : '#1D252D'
                }}>
                      Published View
                    </Typography>
                  </Box>}
                {(user.is_superuser || flags?.journey_params) && <Box className="box-button" sx={{
                backgroundColor: currentTab == 3 ? '#3898D9' : '#F3F6FC',
                ':hover': {
                  backgroundColor: currentTab == 3 ? '#3898D9' : '#C2E7FF'
                }
              }} onClick={e => {
                handleTabsChange(e, 3);
              }}>
                    <IntegrationsIcon fill={currentTab == 3 ? '#ffffff' : '#1D252D'} />

                    <Typography sx={{
                  pl: '10px',
                  color: currentTab == 3 ? '#ffffff' : '#1D252D'
                }}>
                      Wizard
                    </Typography>
                  </Box>}
              </Stack>
              <Stack direction="row" spacing="40px" sx={{
              pr: '20px'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                pb: '15px'
              }}>
                  <Save saving={isSaving} />
                </Box>

                <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                pt: '12px'
              }}>
                  <StatusMui hideBackground status={journey?.status} className="d-inline ml-3" />
                </Box>
              </Stack>
            </Box>

            <div className="mui-wrapper">
              <Box sx={{
              m: '20px'
            }}>
                <Paper sx={{
                width: '100%',
                minHeight: '85vh',
                borderRadius: '14px'
              }}>
                  {isLoadingJourney ? <Box sx={{
                  width: '100%',
                  height: '100%'
                }}>
                      <Box sx={{
                    py: '240px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    justifyItems: 'center',
                    alignItems: 'center'
                  }}>
                        <CircularProgress />
                      </Box>
                    </Box> : <Box sx={{
                  height: '100%'
                }} display="flex" flexDirection="column" justifyContent="space-between">
                      <Stack spacing="16px" sx={{
                    pt: '24px',
                    pb: '30px',
                    px: '24px'
                  }}>
                        <Stack sx={{
                      cursor: 'pointer'
                    }} onClick={() => {
                      setJourneyTags(journey?.tags);
                      setJourneyDescription(journey?.description);
                      setEditJourneyMetaDataModal(true);
                      setJourneyName(journey?.name);
                    }}>
                          <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                            <Box>
                              <Typography sx={{
                            fontSize: '24px'
                          }}>{journey?.name} </Typography>
                            </Box>
                            <Stack sx={{
                          pr: '6px'
                        }} direction={'row'} spacing="4px">
                              <Stack direction="row" spacing={1} sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                {journey?.tags?.length > 0 ? <Box>
                                    {journey?.tags?.map((tag, index) => <Chip key={index} label={tag} sx={{
                                mr: '4px',
                                mb: '4px'
                              }} />)}
                                  </Box> : <Typography sx={{
                              opacity: 0.5
                            }}>(No tags)</Typography>}
                              </Stack>
                              <MuiTooltip title={'Edit'}>
                                <IconButton>
                                  <EditIcon />
                                </IconButton>
                              </MuiTooltip>
                            </Stack>
                          </Box>

                          <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                            {journey?.description ? <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <div dangerouslySetInnerHTML={{
                            __html: journey?.description
                          }} />
                              </Box> : <Typography sx={{
                          opacity: 0.5
                        }}>No description</Typography>}
                          </Box>
                        </Stack>
                        <Divider />

                        {journey?.status != 'draft' && KPIs?.length > 0 && <Stack spacing="16px">
                            <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                              <Box />
                              <Stack spacing="16px" direction="row">
                                {selectTriggers?.length > 1 && <FormControl sx={{
                            width: '250px'
                          }}>
                                    <InputLabel id="select-trigger-label">Trigger Select</InputLabel>
                                    <Select sx={{
                              color: 'white',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E3E5E7',
                                borderRadius: '8px'
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E3E5E7',
                                borderRadius: '8px'
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E3E5E7',
                                borderRadius: '8px'
                              }
                            }} id="select-trigger" value={filters.trigger} label="trigger select" onChange={e => {
                              setFilters({
                                ...filters,
                                trigger: e.target.value
                              });
                            }}>
                                      {selectTriggers?.map(trigger => <MenuItem key={trigger.key} value={trigger.key}>
                                          {trigger.label}
                                        </MenuItem>)}
                                    </Select>
                                  </FormControl>}

                                <DatePicker label="Date Range" background="#fff" filters={filters} after={filters.date_after} before={filters?.date_before} handleFilterChange={setFilters} handleDateChange={handleDateChange} bottom="10px" types={[{
                            key: '90days',
                            name: 'Last 90 Days',
                            icon: <Calendar30DaysIcon />
                          }, {
                            key: '180days',
                            name: 'Last 180 Days',
                            icon: <Calendar30DaysIcon />
                          }, {
                            key: '270days',
                            name: 'Last 270 Days',
                            icon: <Calendar30DaysIcon />
                          }, {
                            key: '365days',
                            name: 'Last year',
                            icon: <Calendar365DaysIcon />
                          }]} />
                              </Stack>
                            </Box>

                            <Stack spacing="32px" sx={{
                        width: '100%'
                      }}>
                              <Box sx={{
                          flexGrow: 1
                        }}>
                                <Grid container spacing={3}>
                                  {KPIs.map((kpi, index) => <Grid size="grow" item key={index}>
                                      <Box sx={{
                                border: '1px solid #E2E2E2',
                                borderRadius: '8px'
                              }}>
                                        <Stack spacing={1} sx={{
                                  pl: 2,
                                  pb: 2
                                }}>
                                          <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}>
                                            <Box sx={{
                                      pt: 1,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                              <Typography> {kpi?.stat1?.title}</Typography>
                                            </Box>
                                            <Box sx={{
                                      position: 'relative',
                                      bottom: '5px',
                                      left: '5px'
                                    }}>
                                              <IconButton onClick={e => handleHelpPopupClick(e, kpi.id)}>
                                                <HelpIcon fill="#3898D9" size="sm" />
                                              </IconButton>
                                            </Box>
                                          </Box>

                                          <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}>
                                            <Typography sx={{
                                      fontSize: '24px',
                                      fontWeight: 700
                                    }}> {kpi?.stat1?.stat} </Typography>
                                          </Box>
                                          <Box sx={{
                                    pt: '4px',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}>
                                            <Box>
                                              <Typography sx={{
                                        color: '#71717a'
                                      }}> {kpi?.stat2?.stat}</Typography>
                                            </Box>
                                            {kpi?.stat3?.stat && <Box>
                                                <Typography sx={{
                                        color: '#71717a'
                                      }}> {kpi?.stat3?.stat}</Typography>
                                              </Box>}
                                            {kpi?.stat3?.stat && <Box> </Box>}
                                          </Box>
                                        </Stack>
                                      </Box>
                                    </Grid>)}
                                </Grid>
                              </Box>
                            </Stack>
                          </Stack>}

                        <Box sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                          {displayGraphs('50%')}

                          {displayPie('50%')}
                        </Box>

                        {journey?.status != 'draft' && (originalPieData || KPIs?.length > 0 || graph1Data) && <Divider />}

                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Revision History </Typography>
                          </Box>
                          <Box>
                            <Table>
                              <colgroup>
                                <col width="15%" />
                                <col width="15%" />
                                <col width="20%" />
                                <col width="20%" />
                                <col width="20%" />
                                <col width="10%" />
                              </colgroup>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Revision</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Published</TableCell>
                                  <TableCell>Published By</TableCell>
                                  <TableCell>Stopped/Closed/Paused</TableCell>
                                  {flags?.journey_release_notes && <TableCell>Release Notes</TableCell>}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {journey?.revisions?.filter((r, i) => i < REVISION_MAX || revisionsExpanded).map((revision, index) => <TableRow key={revision.id}>
                                      <TableCell>Revision #{journey?.revisions?.length - index}</TableCell>
                                      <TableCell>
                                        <Box sx={{
                                  display: 'flex'
                                }}>
                                          <StatusMui disablePadding hideBackground status={revision.status} />
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        {!!revision.published_when ? <> {moment(revision.published_when).local().format('MMM D, YYYY h:mm A')}</> : <Typography> --</Typography>}
                                      </TableCell>

                                      <TableCell>
                                        {!!revision.published_by ? <>{revision.published_by}</> : <Typography> --</Typography>}
                                      </TableCell>

                                      <TableCell>
                                        {!!revision.deleted_when && <>Stopped: {moment(revision.deleted_when).local().format('MMM D, YYYY h:mm A')}</>}
                                        {!revision.deleted_when && !!revision.closed_when && <>Closed: {moment(revision.closed_when).local().format('MMM D, YYYY h:mm A')}</>}
                                        {!revision.deleted_when && !revision.closed_when && !!revision.paused_when && <>Paused: {moment(revision.paused_when).local().format('MMM D, YYYY h:mm A')}</>}
                                        {!revision.deleted_when && !revision.closed_when && !revision.paused_when && <Typography> --</Typography>}
                                      </TableCell>
                                      {flags?.journey_release_notes && <TableCell>
                                          {revision?.status != 'draft' && <MuiTooltip title="Show Release Notes">
                                              <IconButton onClick={() => {
                                    setShowJourneyReleaseNotesModal(revision);
                                  }}>
                                                <NewspaperIcon />
                                              </IconButton>
                                            </MuiTooltip>}
                                        </TableCell>}
                                    </TableRow>)}
                              </TableBody>
                            </Table>
                            {journey?.revisions?.length > REVISION_MAX && <Box sx={{
                          pt: 1
                        }}>
                                <Button sx={{
                            display: 'flex',
                            width: '125px',
                            borderRadius: '20px',
                            color: '#53A6D6'
                          }} variant="outlined" onClick={() => setRevisionsExpanded(!revisionsExpanded)}>
                                  <Typography sx={{
                              textTransform: 'none'
                            }}> Show {revisionsExpanded ? 'Less' : 'More'}</Typography>
                                </Button>
                              </Box>}
                          </Box>
                        </Stack>

                        <Stack spacing="16px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Segments </Typography>
                          </Box>

                          {!journey?.all_segments?.length ? <div className="mb-3">
                              <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 14,
                          color: '#1D252D80'
                        }}>
                                No Segments Triggered or edited
                              </Typography>
                            </div> : <Stack spacing={1}>
                              <Grid sx={{
                          px: '8px',
                          width: '100%'
                        }} container spacing={{
                          xs: 2,
                          sm: 2,
                          md: 3,
                          lg: 3
                        }} columns={{
                          xs: 4,
                          sm: 12,
                          md: 12,
                          lg: 12
                        }}>
                                {journey?.all_segments?.filter((r, i) => i < ASSET_MAX || segmentsExpanded).map((segment, index) => <Grid key={segment.id + index} xs="auto" sm="auto" md="auto" lg="auto">
                                      <Stack direction="row">
                                        <Box sx={{
                                backgroundColor: getSegmentColor(segment.node_name),
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #E2E2E2',
                                cursor: 'pointer',
                                borderRadius: '8px'
                              }} onClick={e => {
                                history.push(`/segments/${segment.id}/edit`);
                              }}>
                                          <Stack direction="row" spacing={1}>
                                            <Scoped css={css}>
                                              <MuiTooltip title={getFormatedSegmentName(segment.node_name)}>
                                                <div className="node-selection">
                                                  <div className="node-selection__img" style={{
                                          backgroundColor: getSegmentColor(segment.node_name)
                                        }}>
                                                    {<img src={getSegmentIcon(segment.node_name)} alt="" draggable={false} />}
                                                  </div>
                                                </div>
                                              </MuiTooltip>
                                            </Scoped>
                                            <MuiTooltip title={segment.name.length > 20 ? segment.name : ''}>
                                              <Box sx={{
                                      py: '12px',
                                      pr: 1,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                                <Typography sx={{
                                        color: '#FFFFFF',
                                        maxWidth: '150px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden !important',
                                        textOverflow: 'ellipsis'
                                      }}>
                                                  {segment.name}
                                                </Typography>
                                              </Box>
                                            </MuiTooltip>
                                          </Stack>
                                        </Box>
                                        <Box sx={{
                                py: 1,
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                                          <Typography>{segment.count > 1 ? `x${segment.count}` : ''}</Typography>
                                        </Box>
                                      </Stack>
                                    </Grid>)}
                              </Grid>

                              {journey?.all_segments?.length > ASSET_MAX && <Button variant="outlined" sx={{
                          display: 'flex',
                          width: '125px',
                          borderRadius: '20px',
                          color: '#53A6D6'
                        }} onClick={() => setSegmentsExpanded(!segmentsExpanded)}>
                                  <Typography sx={{
                            textTransform: 'none'
                          }}> Show {segmentsExpanded ? 'Less' : 'More'} </Typography>
                                </Button>}
                            </Stack>}
                        </Stack>

                        <Divider />

                        <Stack spacing="16px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Templates </Typography>
                          </Box>

                          {!journey?.all_templates?.length ? <div className="mb-3">
                              <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 14,
                          color: '#1D252D80'
                        }}> No Templates</Typography>
                            </div> : <Stack spacing={1}>
                              <Grid sx={{
                          px: '8px',
                          width: '100%'
                        }} container spacing={{
                          xs: 2,
                          sm: 2,
                          md: 3,
                          lg: 3
                        }} columns={{
                          xs: 4,
                          sm: 12,
                          md: 12,
                          lg: 12
                        }}>
                                {journey?.all_templates?.filter((r, i) => i < ASSET_MAX || templatesExpanded).map((template, index) => <Grid key={template.id + index} xs="auto" sm="auto" md="auto" lg="auto">
                                      <Stack direction="row">
                                        <Box sx={{
                                backgroundColor: '#E77A16',
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #E2E2E2',
                                cursor: 'pointer',
                                borderRadius: '8px'
                              }} onClick={e => {
                                if (template.type == 'email') {
                                  history.push(`/templates/edit/email/${template.id}`);
                                } else if (template.type == 'sms') {
                                  history.push(`/templates/edit/sms/${template.id}`);
                                } else if (template.type == 'print') {
                                  history.push(`/templates/edit/print/${template.id}`);
                                }
                              }}>
                                          <Stack direction="row">
                                            <Scoped css={css}>
                                              <MuiTooltip title={getTemplateTooltip(template.type)}>
                                                <div className="node-selection">
                                                  <div className="node-selection__img" style={{
                                          backgroundColor: '#E77A16'
                                        }}>
                                                    {getTemplateIcon(template.type)}
                                                  </div>
                                                </div>
                                              </MuiTooltip>
                                            </Scoped>
                                            <MuiTooltip title={template.name.length > 20 ? template.name : ''}>
                                              <Box sx={{
                                      py: '12px',
                                      pr: 1,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                                <Typography sx={{
                                        color: '#FFFFFF',
                                        maxWidth: '150px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden !important',
                                        textOverflow: 'ellipsis'
                                      }}>
                                                  {template.name}
                                                </Typography>
                                              </Box>
                                            </MuiTooltip>
                                          </Stack>
                                        </Box>
                                        <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                py: 1
                              }}>
                                          <Typography>{template.count > 1 ? `x${template.count}` : ''}</Typography>
                                        </Box>
                                      </Stack>
                                    </Grid>)}
                              </Grid>

                              {journey?.all_templates?.length > ASSET_MAX && <Button variant="outlined" sx={{
                          display: 'flex',
                          width: '125px',
                          borderRadius: '20px',
                          color: '#53A6D6'
                        }} onClick={() => setTemplatesExpanded(!templatesExpanded)}>
                                  <Typography sx={{
                            textTransform: 'none'
                          }}> Show {templatesExpanded ? 'Less' : 'More'} </Typography>
                                </Button>}
                            </Stack>}
                        </Stack>
                      </Stack>
                    </Box>}
                </Paper>
              </Box>
            </div>
          </>
        </div>
      </Scoped>

      {!!showJourneyReleaseNotesModal && <JourneyReleaseNotesModal isReadOnly={true} existingNote={showJourneyReleaseNotesModal} onClose={e => {
      setShowJourneyReleaseNotesModal(null);
    }} onSubmit={(note, revision) => {
      setShowJourneyReleaseNotesModal(null);
      patchRevision(revision.id, {
        release_notes: note
      }).then(() => {
        loadJourney();
      });
    }} />}

      <TagModal onClose={() => setEditTags(false)} onSuccess={journey => {
      setJourney(journey);
    }} objectTarget={editTags ? journey : null} getViewTags={getJourneysTags} patchView={patchJourney} viewName={'Journeys'} />

      <Popper anchorEl={companyTagsMenuAnchorEl} sx={{
      zIndex: 9200
    }} open={companyTagsOpen} onClose={handleCompanyTagsClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleCompanyTagsClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflowY: 'scroll',
          width: '360px',
          maxHeight: '40vh',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid sx={{
              p: 1
            }} container justifyContent="left">
                <Typography>Existing journey tags:</Typography>
              </Grid>

              <Box display="flex" flexDirection="column">
                {companyTags.map(opt => <Button variant="text" fullWidth key={opt} sx={{
                cursor: 'pointer',
                height: '32px',
                color: '#1D252D',
                textTransform: 'none',
                justifyContent: 'flex-start',
                px: 2
              }} onClick={e => {
                setNewTagName(opt.toUpperCase());
                handleCompanyTagsClose();
              }}>
                    <Typography sx={{
                  pl: 1
                }}> {opt}</Typography>
                  </Button>)}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxWidth: '1080px',
        width: '50%'
      }
    }} open={editJourneyMetaDataModal} onClose={(e, r) => {
      if (r != 'backdropClick') {
        setEditJourneyMetaDataModal(false);
      }
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Edit Journey
                  </Typography>
                </Box>
              </Stack>
              <IconButton onClick={() => {
              setEditJourneyMetaDataModal(false);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            <Stack spacing={'12px'}>
              <Box component="span">
                <CustomTextField sx={{
                width: '100%'
              }} label="Journey Name" value={journeyName || ''} onChange={e => {
                setJourneyName(e.target.value);
              }} />
              </Box>
            </Stack>

            <Stack spacing={'12px'}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography sx={{}}>Description</Typography>
              </Box>
              <Stack spacing={'24px'} direction={'row'}>
                <Box sx={{
                width: '100%'
              }}>
                  <Scoped css={css}>
                    <Box className="ed" sx={{
                    pb: 1
                  }}>
                      <ReactQuill className="ed" theme="snow" value={journeyDescription || ''} onChange={e => {
                      setJourneyDescription(e);
                    }} modules={modules} formats={formats} />
                    </Box>
                  </Scoped>
                </Box>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={'20px'}>
              <Stack display="flex" alignItems="center" direction="row" spacing="10px">
                {hasPermission('journey.change_journey') && <Box onClick={handleCompanyTagsClick}>
                    <CustomTextField label="Add tag" placeholder="Type tag name" sx={{
                  width: '360px',
                  height: '56px'
                }} onChange={e => setNewTagName(e.target.value.toUpperCase())} onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleNewTagClick();
                  }
                }} value={newTagName || ''} InputLabelProps={{
                  style: {
                    color: '#BEBEBE'
                  }
                }} InputProps={{
                  form: {
                    autocomplete: 'off'
                  },
                  startAdornment: <InputAdornment position="start">
                            <Icon fill="#1D252D" name="custom-new_label" />
                          </InputAdornment>
                }} />
                  </Box>}

                {hasPermission('journey.change_journey') && <MuiTooltip title={<div style={{
                whiteSpace: 'pre-line'
              }}>
                        {newTagName?.length == 0 ? 'Enter a tag name' : isExistingTag() ? 'Unable to add tag ' + newTagName + '\n It has already been added to ' + (journey.name || 'Untitled Journey') : 'Add ' + newTagName + ' to ' + (journey.name || 'Untitled Journey')}
                      </div>}>
                    <IconButton sx={{
                  width: '40px',
                  height: '40px',
                  textAlign: 'center',
                  padding: 0,
                  backgroundColor: '#fff',
                  ':hover': {
                    backgroundColor: '#DAEFFF'
                  }
                }} onClick={handleNewTagClick}>
                      <AddCircleFilledIcon fill={newTagName?.length > 0 && !isExistingTag() ? '#53A6D6' : '#53A6D64D'} />
                    </IconButton>
                  </MuiTooltip>}

                <Box display="flex" alignItems="center">
                  <Stack direction="row" spacing="10px" sx={{
                  flexWrap: 'wrap',
                  gap: 1
                }}>
                    {journeyTags?.length > 0 && journeyTags?.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<CloseOutlinedIcon sx={{
                    transform: 'scale(0.75)'
                  }} />} onDelete={hasPermission('journey.change_journey') && handleDelete(tag, index)} />)}
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setEditJourneyMetaDataModal(false);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}> Cancel </Typography>
              </Box>
            </Button>

            <Button onClick={() => {
            setJourney({
              ...journey,
              description: journeyDescription,
              tags: journeyTags,
              name: journeyName
            });
            setShowSaved(true);
            setEditJourneyMetaDataModal(false);
            setJourneyDescription('');
            setJourneyName('');
            setJourneyTags([]);
          }} variant="contained" sx={{
            mr: 1,
            borderRadius: '20px',
            textTransform: 'none',
            px: '20px'
          }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Popper anchorEl={helpAnchorEl} open={!!helpData} onClose={handleHelpClose} placement="right-start" sx={{
      zIndex: 2100
    }}>
        <ClickAwayListener onClickAway={handleHelpClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          width: '312px'
        }}>
            <Stack>
              <Box sx={{
              padding: '16px'
            }}>{helpData?.title} </Box>
              <Box sx={{
              px: '16px'
            }}> {helpData?.content}</Box>
              <Box sx={{
              py: '16px'
            }}>
                <Button sx={{
                display: 'flex',
                justifyItems: 'left',
                textTransform: 'none'
              }} onClick={handleHelpClose}>
                  {helpData?.buttonText || ' Got it'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxHeight: '90vh',
        minHeight: '90vh',
        minWidth: '90vw',
        maxWidth: '90vw'
      }
    }} open={fullscreenChart} onClose={(e, r) => {
      handleFullscreenChartClose();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 1
            }}>
                <Typography sx={{
                fontSize: 24,
                fontWeight: 700
              }}>Weekly Session Status</Typography>
              </Box>
              <Stack direction="row" spacing={3}>
                <Stack direction="row" spacing={1}>
                  <Box>
                    <MuiTooltip title="Toggle Stacked vs 100% Chart">
                      <IconButton onClick={e => {
                      if (displayedGraph == 'graph1') {
                        setDisplayedGraph('graph2');
                      } else {
                        setDisplayedGraph('graph1');
                      }
                    }}>
                        <InsertChartDataIcon />
                      </IconButton>
                    </MuiTooltip>
                  </Box>

                  <Box>
                    <MuiTooltip title={fullscreenChartToggle == 'week' ? 'View Day Chart' : 'View Week Chart'}>
                      <IconButton onClick={e => {
                      if (fullscreenChartToggle == 'week') {
                        buildGraphs(originalReports?.summary_results, originalReports?.kpi_results?.latest_completed_day);
                        setFullscreenChartToggle('day');
                      } else {
                        buildGraphs(originalReports?.grouped_summary_results, originalReports?.kpi_results?.latest_completed_day);
                        setFullscreenChartToggle('week');
                      }
                    }}>
                        {fullscreenChartToggle == 'week' ? <Calendar24HoursIcon /> : <Calendar7DaysIcon />}
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                </Stack>
                <Box>
                  <IconButton onClick={() => {
                  handleFullscreenChartClose();
                }}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          {displayGraphs('100%')}
        </DialogContent>
      </Dialog>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxHeight: '90vh',
        minHeight: '90vh',
        minWidth: '90vw',
        maxWidth: '90vw'
      }
    }} open={fullscreenPie} onClose={(e, r) => {
      handleFullscreenPieClose();
    }}>
        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack spacing={'4px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 1
            }}>
                <Typography sx={{
                fontSize: 24,
                fontWeight: 700
              }}>Session Status Distribution</Typography>
              </Box>

              <Box>
                <IconButton onClick={() => {
                handleFullscreenPieClose();
              }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            {displayPie('100%')}
          </Stack>
        </DialogContent>
      </Dialog>
    </>;
};
const css = {
  styles: `[kremling="i18"] body,body[kremling="i18"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i18"] .node-selection,[kremling="i18"].node-selection {
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: top;
  flex-basis: 33.333333%;
}

[kremling="i18"] .node-selection__img,[kremling="i18"].node-selection__img {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 32px;
}

[kremling="i18"] .ql-editor,[kremling="i18"].ql-editor {
  height: 250px;
  max-height: 250px;
  overflow: auto;
}

[kremling="i18"] .node-selection__img img,[kremling="i18"].node-selection__img img {
  width: 24px;
  height: 24px;
  display: inline-block;
  opacity: 0.7;
}

[kremling="i18"] .node-selection__name,[kremling="i18"].node-selection__name {
  color: #1D252D;
  font-size: 12px;
  font-family: Nexa;
}

[kremling="i18"] .cardrow .icons__remove,[kremling="i18"].cardrow .icons__remove {
  opacity: 0;
  margin-left: 0.4rem;
}
[kremling="i18"] .cardrow:hover .icons__remove,[kremling="i18"].cardrow:hover .icons__remove {
  opacity: 1;
}`,
  id: 'i18',
  namespace: 'kremling'
};