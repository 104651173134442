import React, { useContext, useState, useEffect } from 'react';
import { Loader } from '../../../components/loader/loader.component';
import { UserStateContext } from 'context/user-state-context';
import { createFacebookInstall, createFacebookReviewInstall, createInstall, getFacebookInstalls, getFacebookReviewAccountInstalls, getFacebookTokens, getInstalls, getIntegrations } from '../integrations.resource';
import { Icon, PageHeaderMui, Toggle } from 'components';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, InputAdornment, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { sortBy } from 'lodash';
import { CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, LandscapeDisabledIcon, ShieldPersonIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import { history } from '../../../root.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F3F3F4',
      borderRadius: `24px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
const FACEBOOKICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII=';
export const IntegrationsAddNew = () => {
  const {
    user,
    asCompany,
    hasPermission,
    enabledIntegrationChange,
    flags
  } = useContext(UserStateContext);
  const [installedIntegrations, setInstalledIntegrations] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredIntegrations, setFilteredIntegrations] = useState([]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setLoading(true);
    const promises = [getIntegrations({
      ...filters,
      limit: 1000
    }), getInstalls({
      ...filters,
      company: asCompany.id,
      limit: 1000
    }), getFacebookInstalls({
      company: asCompany.id
    }), getFacebookReviewAccountInstalls({
      company: asCompany.id
    })];
    Promise.all(promises).then(([integrations, installs, facebookInstalls, facebookReviewInstalls]) => {
      const publicIntegrations = integrations?.results.filter(i => i.status !== 'dev' && (hasPermission('integration.manage_private_integrations') || i.status !== 'private'));

      //add in facebook
      publicIntegrations.push({
        key: 'facebook',
        name: 'Facebook',
        icon: FACEBOOKICON
      });
      if (flags.facebook_reviews) {
        publicIntegrations.push({
          key: 'facebook_reviews',
          name: 'Facebook Reviews',
          icon: FACEBOOKICON
        });
      }
      publicIntegrations.map(i => {
        i.sortBy = i.name.toLowerCase();
      });
      const resultsWithFacebook = installs?.results.filter(i => i.integration_status !== 'dev' && (hasPermission('integration.manage_private_integrations') || i.integration_status !== 'private'));
      if (facebookInstalls?.results?.length > 0 && (search == null || search == '' || 'facebook'.includes(search?.toLowerCase())) && (filters.type == null || filters.type == '' || filters.type == 'all' || filters.type == 'product')) {
        resultsWithFacebook.push({
          id: 'facebook',
          integration: 'facebook',
          integration_name: 'Facebook',
          status: facebookInstalls?.results.some(i => i.status == 'configured') ? 'configured' : 'not_configured',
          integration_icon: FACEBOOKICON
        });
      }
      if (flags.facebook_reviews) {
        if (facebookReviewInstalls.results.length > 0) {
          resultsWithFacebook.push({
            id: 'facebook_reviews',
            integration: 'facebook_reviews',
            integration_name: 'Facebook Reviews',
            status: facebookInstalls?.results.some(i => i.status == 'configured') ? 'configured' : 'not_configured',
            integration_icon: FACEBOOKICON
          });
        }
      }
      resultsWithFacebook.map(result => {
        result.sortBy = result?.integration_name?.toLowerCase();
      });
      setInstalledIntegrations(sortBy(resultsWithFacebook, 'sortBy', 'desc'));
      setFilteredIntegrations(sortBy(publicIntegrations, 'sortBy', 'desc').filter(i => {
        return resultsWithFacebook.findIndex(ii => ii.integration === i.key) === -1;
      }));
      enabledIntegrationChange();
      setLoading(false);
    });
  }, [hasPermission, asCompany?.id, filters]);
  useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        search: search
      });
      setSelected([]);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const onSubmit = () => {
    const promises = [];
    selected.forEach(integration => {
      if (integration.key != 'facebook' && integration.key != 'facebook_reviews') {
        promises.push(createInstall({
          integration: integration.key,
          company: asCompany.id
        }));
      }
    });
    Promise.all(promises).then(() => {
      if (selected.find(i => i.key === 'facebook')) {
        createFacebookInstall().then(data => {
          history.push('/integrations', {
            newInstalls: selected
          });
        });
      } else if (selected.find(i => i.key === 'facebook_reviews')) {
        createFacebookReviewInstall({
          company: asCompany.id
        }).then(() => {
          history.push('/integrations', {
            newInstalls: selected
          });
        });
      } else {
        history.push('/integrations', {
          newInstalls: selected
        });
      }
    });
  };
  return <>
      <div className="wrapper" style={{
      height: !!loading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations and Apps" icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        px: '20px',
        pt: '20px',
        //makes the footer act a little weird
        pb: '20px'
      }}>
          <Paper sx={{
          borderRadius: '14px'
        }}>
            <Stack>
              <Stack>
                <Stack spacing="20px" sx={{
                backgroundColor: '#fff',
                width: '100%',
                position: 'sticky',
                top: 0,
                py: '10px',
                px: '20px',
                borderRadius: '14px'
              }}>
                  <Stack spacing={'10px'}>
                    <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                      <Typography sx={{
                      fontSize: '20px',
                      lineHeight: '26px'
                    }}>Install new </Typography>
                      <Box>
                        <IconButton onClick={e => {
                        history.push('/integrations');
                      }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>
                  <Box>
                    <CustomTextField variant="outlined" sx={{
                    width: '500px'
                  }} placeholder="Search" autoComplete="new-password" InputProps={{
                    startAdornment: <InputAdornment position="start">
                            <Icon name="custom-search" />
                          </InputAdornment>,
                    endAdornment: search != '' && <InputAdornment onClick={e => {
                      setSearch('');
                    }} position="start" sx={{
                      cursor: 'pointer'
                    }}>
                            <CloseIcon />
                          </InputAdornment>
                  }} value={search || ''} onChange={e => {
                    setSearch(e.target.value);
                  }} />
                  </Box>
                  <Stack direction="row">
                    <Box>
                      {selected.length > 0 ? <Stack sx={{
                      backgroundColor: '#C2E7FF',
                      borderRadius: '20px'
                    }} direction="row" spacing="4px">
                          <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pl: '16px',
                        py: '9px'
                      }}>
                            <Typography sx={{
                          color: '#3898D9',
                          fontSize: '14px',
                          fontWeight: '700',
                          lineHeight: '18px'
                        }}>
                              {selected.length} selected
                            </Typography>
                          </Box>
                          <IconButton onClick={e => {
                        setSelected([]);
                      }}>
                            <CloseIcon size="sm" fill="#3898D9" />
                          </IconButton>
                        </Stack> : <>
                          {!!filters?.type ? <Stack sx={{
                        backgroundColor: '#C2E7FF',
                        borderRadius: '20px'
                      }} direction="row" spacing="4px">
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pl: '16px',
                          py: '9px'
                        }}>
                                <Typography sx={{
                            color: '#3898D9',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            textTransform: 'capitalize'
                          }}>
                                  {filters?.type}
                                </Typography>
                              </Box>
                              <IconButton onClick={e => {
                          setFilters({
                            ...filters,
                            type: '',
                            integration__type: ''
                          });
                        }}>
                                <CloseIcon size="sm" fill="#3898D9" />
                              </IconButton>
                            </Stack> : <FormControl size="small" sx={{
                        width: '150px'
                      }}>
                              <InputLabel id="integration-type-label">Type</InputLabel>
                              <Select sx={{
                          borderRadius: '24px'
                        }} id="integration-type" labelId="integration-type-label" input={<OutlinedInput label="Type" />} label="type" value={filters?.type || ''} onChange={e => {
                          setFilters({
                            ...filters,
                            type: e.target.value,
                            integration__type: e.target.value
                          });
                        }}>
                                <MenuItem value={'data'}>Data</MenuItem>
                                <MenuItem value={'feature'}>Feature</MenuItem>
                                <MenuItem value={'product'}>Product</MenuItem>
                              </Select>
                            </FormControl>}
                        </>}
                    </Box>
                  </Stack>
                  <Divider />
                </Stack>
                <Stack spacing={'20px'}>
                  <Box sx={{
                  px: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '16.8px'
                  }}> Installed </Typography>
                  </Box>
                  {loading ? <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                            <Box sx={{
                      p: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      backgroundColor: '#fff'
                    }}>
                              <Stack direction="row" spacing={'16px'}>
                                <Box>
                                  <DefaultIntegrationIcon />
                                </Box>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Skeleton variant="rounded" width={210} height={20} />
                                </Box>
                              </Stack>
                            </Box>
                          </Grid>)}
                    </Grid> : <Grid sx={{
                  maxWidth: '85vw',
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {installedIntegrations.length > 0 ? <>
                          {installedIntegrations.map(integration => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={integration?.id}>
                              <Box key={integration?.id} sx={{
                        p: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid #E8EAED',
                        borderRadius: '8px',
                        width: '315px',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F3F3F4',
                          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                        }
                      }} onClick={e => {
                        if (integration?.integration === 'facebook') {
                          history.push(`/integrations/manage/facebook`);
                        } else if (hasPermission('integration.view_credential')) {
                          history.push(`/integrations/manage/${integration?.id}`);
                        } else {
                          snackbarService.popup({
                            type: 'error',
                            message: 'You do not have permission to view this integration'
                          });
                        }
                      }}>
                                <Stack direction="row" spacing={'16px'}>
                                  <Box>
                                    {integration?.integration_icon ? <img src={integration?.integration_icon} height="40" width="40" alt={integration?.integration_name} /> : <DefaultIntegrationIcon />}
                                  </Box>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Typography> {integration?.integration_name}</Typography>
                                  </Box>
                                </Stack>
                                <Stack direction="row" spacing={'16px'}>
                                  {integration?.status && <>
                                      {integration?.status === 'configured' && <Tooltip title="You have configured this integration">
                                          <Box sx={{
                                p: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#C5EAD7'
                              }}>
                                            <CheckCircleIcon size="sm" fill="#3EB87B" />
                                          </Box>
                                        </Tooltip>}
                                      {integration?.status === 'not_configured' && <Tooltip title="You have not yet configured this integration. To use it, please click on it and fill out the required information">
                                          <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#F3F3F4'
                              }}>
                                            <LandscapeDisabledIcon fill="#616161" />
                                          </Box>
                                        </Tooltip>}
                                      {integration?.status === 'auto' && <Tooltip title="This integration was automatically configured. It may be ready for use or may
                                    require additional configuration elsewhere.">
                                          <Box sx={{
                                p: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#C5EAD7'
                              }}>
                                            <CheckCircleIcon size="sm" fill="#3EB87B" />
                                          </Box>
                                        </Tooltip>}
                                    </>}
                                  {user?.is_superuser && integration?.integration_status == 'private' && <Tooltip title="This integration is only shown for super users only">
                                      <Box sx={{
                              p: '7px',
                              borderRadius: '8px',
                              backgroundColor: '#C2E7FF'
                            }}>
                                        <ShieldPersonIcon size="sm" fill="#3898D9" />
                                      </Box>
                                    </Tooltip>}
                                </Stack>
                              </Box>
                            </Grid>)}
                        </> : <>
                          {installedIntegrations?.length > 0 ? <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pl: '24px'
                    }}>
                              <Typography>No search results. Try adjusting your search or filters.</Typography>
                            </Box> : <Box sx={{
                      px: '10px',
                      width: '100%'
                    }}>
                              <Box sx={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '14px',
                        width: '100%'
                      }}>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: '32px',
                          justifyContent: 'center'
                        }}>
                                  <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '300px',
                            lineHeight: '17px'
                          }}>
                                    You have not installed any integrations yet
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>}
                        </>}
                    </Grid>}
                  <Box sx={{
                  px: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '16.8px'
                  }}> Available </Typography>
                  </Box>
                  {loading ? <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                            <Box sx={{
                      p: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      backgroundColor: '#fff'
                    }}>
                              <Stack direction="row" spacing={'16px'}>
                                <Box>
                                  <DefaultIntegrationIcon />
                                </Box>
                                <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Skeleton variant="rounded" width={210} height={20} />
                                </Box>
                              </Stack>
                            </Box>
                          </Grid>)}
                    </Grid> : <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {filteredIntegrations.length > 0 ? <>
                          {filteredIntegrations.map((integration, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index + integration?.key}>
                              <Box onClick={e => {
                        if (hasPermission('integration.add_credential')) {
                          if (selected.find(i => i.key === integration.key)) {
                            setSelected(selected.filter(i => i.key !== integration.key));
                          } else {
                            setSelected([...selected, integration]);
                          }
                        } else {
                          snackbarService.popup({
                            type: 'error',
                            message: 'You do not have permission to add integrations'
                          });
                        }
                      }} sx={{
                        p: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: selected.find(i => i.key === integration.key) ? '2px solid #3898D9' : '1px solid #E8EAED',
                        borderRadius: '8px',
                        width: '315px',
                        cursor: 'pointer',
                        backgroundColor: selected.find(i => i.key === integration.key) ? '#F3F3F4' : '#fff',
                        '&:hover': {
                          backgroundColor: '#F3F3F4',
                          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                        }
                      }}>
                                <Stack direction="row" spacing={'16px'}>
                                  <Box>
                                    {integration?.icon ? <img src={integration?.icon} height="40" width="40" alt={integration?.name} /> : <DefaultIntegrationIcon />}
                                  </Box>
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Typography> {integration.name}</Typography>
                                  </Box>
                                </Stack>

                                {user?.is_superuser && integration?.status == 'private' && <Tooltip title="This integration is only shown for super users only">
                                    <Box sx={{
                            p: '7px',
                            borderRadius: '8px',
                            backgroundColor: '#C2E7FF'
                          }}>
                                      <ShieldPersonIcon size="sm" fill="#3898D9" />
                                    </Box>
                                  </Tooltip>}
                              </Box>
                            </Grid>)}
                        </> : <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: '24px'
                  }}>
                          <Typography> No results</Typography>
                        </Box>}
                    </Grid>}
                </Stack>
              </Stack>
              <Stack spacing="24px" sx={{
              backgroundColor: '#fff',
              width: '100%',
              position: 'sticky',
              bottom: 0,
              pt: 1,
              px: 2,
              pb: 2,
              borderRadius: '14px'
            }}>
                <Divider />
                <Box sx={{
                display: 'flex',
                justifyContent: 'right'
              }}>
                  <Stack spacing="16px" direction={'row'}>
                    <Button sx={{
                    backgroundColor: '#F3F3F4',
                    '&:hover': {
                      backgroundColor: '#F3F3F4'
                    }
                  }} onClick={e => {
                    history.push('/integrations');
                  }} variant="contained">
                      <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                        <Typography sx={{
                        color: '#3898D9'
                      }}>Cancel </Typography>
                      </Box>
                    </Button>
                    <Button onClick={e => {
                    onSubmit();
                  }} disabled={selected?.length == 0} variant="contained" color="primary" sx={{
                    backgroundColor: '#3898D9',
                    '&:hover': {
                      backgroundColor: '#3898D9'
                    }
                  }}>
                      <Box sx={{
                      py: '5px',
                      px: '12px'
                    }}>
                        <Typography sx={{
                        color: '#fff'
                      }}> Add selected</Typography>
                      </Box>
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </div>
    </>;
};